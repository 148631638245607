import styled from '@emotion/styled';
import { Row } from 'antd';

import Card from 'components/Card/Card';
import FormLabel from 'components/FormLabel/FormLabel';

export const SetupContainerCard = styled(Card)`
  margin-bottom: '16px';
`;

export const StyledTitle = styled(FormLabel)`
  margin-bottom: '12px';
  font-size: '16px';
`;

export const RoomArrangementContainer = styled(Row)`
  width: '100%';
`;
