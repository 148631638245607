import api from './apiHelper';
import { useCustomQuery, useCustomPaginatedQuery } from 'hooks/reactQuery';
import { queryCache } from 'react-query';

/* ---------------------------------- GET ---------------------------------------- */
export const useGetPaginatedPromotions = query => {
  const getPromotions = async (key, query) => {
    return api.get(`promotion/paginated`, { params: query });
  };

  return useCustomPaginatedQuery('promotions', getPromotions, query);
};

export const useGetPromotionById = promotionId => {
  const getPromotionDetailsById = (key, promotionId) => {
    return api.get(`promotion/${promotionId}`);
  };

  return useCustomQuery('getPromotionDetailsById', [promotionId], getPromotionDetailsById, { enabled: !!promotionId });
};

/* ------------------------------- POST  -------------------------------------- */
export const postCreatePromotion = payload => {
  return api.post('promotion/', payload);
};

export const verifyPromotionCode = promotionCode => {
  return api.post('promotion/verify', { promotionCode });
};

/* ---------------------------------- PATCH  ---------------------------------------- */
export const patchUpdatePromotion = (promotionId, payload) => {
  return api.patch(`promotion/${promotionId}`, payload);
};

export const patchUpdatePromotionStatus = (promotionId, status) => {
  return api.patch(`promotion/${promotionId}/status`, { status });
};

/* ---------------------------------- DELETE ----------------------------------- */

export const deletePromotion = promotionId => {
  return api.delete(`promotion/${promotionId}`);
};

/* ---------------------------------- REFETCH FUNCTION ----------------------------------- */
export const refetchPromotionDetails = promotionId => queryCache.invalidateQueries(['getPromotionDetailsById', promotionId]);
