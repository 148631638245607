import api from './apiHelper';
import { queryCache } from 'react-query';
import { useCustomQuery } from 'hooks/reactQuery';

const getRoomTypePath = (propertyId, params) => `property/${propertyId}/roomtype${params ? `/${params}` : ``}`;

/* ------------------------------- POST CREATE -------------------------------------- */

export const postCreateRoomType = (listingId, payload) => {
  const path = getRoomTypePath(listingId);
  return api.post(path, payload).then(createdRoomType => {
    refetchRoomTypes();
    return createdRoomType;
  });
};

/* ---------------------------------- GET ---------------------------------------- */

export const useGetRoomTypes = listingId => {
  const getRoomTypes = (key, listingId) => {
    const path = getRoomTypePath(listingId);
    return api.get(path);
  };
  return useCustomQuery('getRoomTypes', [listingId], getRoomTypes, { enabled: !!listingId });
};

export const useGetRoomTypeById = (propertyId, roomTypeId) => {
  const getRoomTypeById = (key, propertyId, roomTypeId) => {
    const path = getRoomTypePath(propertyId, roomTypeId);
    return api.get(path);
  };
  return useCustomQuery('useGetRoomTypeById', [propertyId, roomTypeId], getRoomTypeById, { enabled: !!roomTypeId });
};

export const useGetRoomTypesWithAvailableInventory = (propertyId, { startDate, endDate, bookingCodeToExclude }, recalculatePrice) => {
  const getRoomTypes = () => {
    const path = getRoomTypePath(propertyId, `inventory`);
    return api.get(path, { params: { startDate, endDate, bookingCodeToExclude } });
  };
  return useCustomQuery('getRoomTypesWithInventory', [propertyId, startDate, endDate, bookingCodeToExclude], getRoomTypes, {
    shouldDefaultEmptyObject: false,
    enabled: !!propertyId && !!startDate && !!endDate && !!recalculatePrice
  });
};

/* ---------------------------------- PATCH UPDATE ----------------------------------- */

export const patchUpdateRoomType = (propertyId, roomTypeId, payload) => {
  const path = getRoomTypePath(propertyId, roomTypeId);
  return api.patch(path, payload).then(updatedRoomType => {
    refetchRoomTypes();
    refetchRoomTypeDetails(updatedRoomType.property, updatedRoomType._id);
    return updatedRoomType;
  });
};

/* ---------------------------------- DELETE UPDATE ----------------------------------- */

export const deleteRoomType = (propertyId, roomTypeId) => {
  const path = getRoomTypePath(propertyId, roomTypeId);
  return api.delete(path).then(deletedRoomType => {
    refetchRoomTypes();
    return deletedRoomType;
  });
};

/* ---------------------------------- REFETCH FUNCTION ----------------------------------- */
const refetchRoomTypes = () => queryCache.invalidateQueries(['getRoomTypes']);
const refetchRoomTypeDetails = (propertyId, roomTypeId) => queryCache.invalidateQueries(['useGetRoomTypeById', propertyId, roomTypeId]);
