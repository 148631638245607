import { Col, Row, Table } from 'antd';
import Card from 'components/Card/Card';
import SectionContainer from 'components/SectionContainer/SectionContainer';
import Title from 'components/Title/Title';
import React, { useEffect, useRef, useState } from 'react';
import { Map, Marker, ZoomControl } from 'pigeon-maps';
import { useGetPaginatedQRCheckinLocations } from 'apis/qrCodeCheckin';
import { Link } from 'react-router-dom';
import { constructColumn, constructColumnFilterSearch, handleOnAggregationTableChange } from 'utils/table/table';
import { getMemberDetailsRoute } from 'utils/routes';

const constructColumns = () => [
  {
    ...constructColumn('Service Name', 'serviceName', { hasSorter: true })
    // ...constructColumnFilterSearch('hubId', 'Search Service')
  },
  {
    ...constructColumn('Nomad', 'memberName', { hasSorter: true }),
    ...constructColumnFilterSearch('memberName', 'Search Nomad'),
    render: (text, record) => <Link to={getMemberDetailsRoute(record.memberId).path}>{text}</Link>
  },
  {
    ...constructColumn('Checked-in Date', 'createdAt', { isDate: true, hasSorter: true })
  }
];

const QRCodeCheckin = () => {
  const [query, setQuery] = useState({});
  const { isLoading: qrcheckinsLoading, paginatedData: qrCheckins, total } = useGetPaginatedQRCheckinLocations(query);
  const checkinDatas = useRef([]);
  useEffect(() => {
    if (qrCheckins) {
      checkinDatas.current = qrCheckins.map(val => {
        return {
          _id: val._id,
          hubId: val.hubId,
          serviceID: val.serviceId,
          serviceName: val.serviceName,
          lat: val.lat,
          long: val.long,
          memberName: `${val.memberDetails.name} (${val.memberDetails.email})`,
          memberId: val.memberId,
          createdAt: val.createdAt
        };
      });
    }
  }, [qrCheckins]);

  return (
    <Card>
      <SectionContainer>
        <Row justify="space-between" align="middle">
          <Col>
            <Title>Nomad QR Code Check-in</Title>
          </Col>
        </Row>
      </SectionContainer>

      <Map height={300} defaultCenter={[3.5770102512892032, 102.01592436539765]} defaultZoom={6}>
        <ZoomControl />
        {checkinDatas.current && checkinDatas.current.map(checkin => <Marker key={checkin._id} width={50} anchor={[checkin.lat, checkin.long]} />)}
      </Map>

      <Table
        loading={qrcheckinsLoading}
        rowKey={record => record._id}
        dataSource={checkinDatas.current}
        columns={constructColumns()}
        scroll={{ x: 1000 }}
        pagination={{ total }}
        onChange={(pagination, filters, sorter) => handleOnAggregationTableChange({ pagination, filters, sorter }, setQuery)}
      />
    </Card>
  );
};

export default QRCodeCheckin;
