import { useCustomPaginatedQuery } from 'hooks/reactQuery';
import api from './apiHelper';

const getQRCheckinsPath = params => `qr-checkin${!!params ? `/${params}` : ''}`;

export const useGetPaginatedQRCheckinLocations = (query, onError) => {
  const getQRCheckins = async (key, query) => {
    const path = getQRCheckinsPath('paginated');
    return api.get(path, { params: query });
  };

  return useCustomPaginatedQuery('checkIns', getQRCheckins, query, { onError });
};
