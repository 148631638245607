import React from 'react';
import PropTypes from 'prop-types';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

import { CountContainer, StyledButton, DisplayText } from './Counter.styles';

const generateLabel = (count, emptyLabel) => {
  let label = count;
  if (emptyLabel) {
    if (count === 0) {
      label = emptyLabel;
    }
  }
  return label;
};

const Counter = ({ count, onDecrement, onIncrement, emptyLabel, disable }) => {
  const label = generateLabel(count, emptyLabel);
  return (
    <CountContainer>
      <StyledButton ghost type="primary" icon={<MinusOutlined />} size="large" onClick={onDecrement} disabled={disable} />
      <DisplayText>{label}</DisplayText>
      <StyledButton ghost type="primary" icon={<PlusOutlined />} size="large" onClick={onIncrement} disabled={disable} />
    </CountContainer>
  );
};

Counter.propTypes = {
  count: PropTypes.number.isRequired,
  onDecrement: PropTypes.func.isRequired,
  onIncrement: PropTypes.func.isRequired,
  emptyLabel: PropTypes.string
};

Counter.defaultProps = {
  count: 0,
  onDecrement: () => {},
  onIncrement: () => {},
  emptyLabel: ''
};

export default Counter;
