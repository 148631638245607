import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row, Table } from 'antd';
import { Link, useHistory } from 'react-router-dom';

import { useGetPaginatedHosts } from 'apis/host';

import Card from 'components/Card/Card';
import SectionContainer from 'components/SectionContainer/SectionContainer';
import Title from 'components/Title/Title';

import { getHostDetailRoute, getNewHostRoute } from 'utils/routes';
import { constructColumn, constructColumnFilterSearch, handleOnAggregationTableChange } from 'utils/table/table';

const constructColumns = () => [
  {
    ...constructColumn('Host name', 'name', { hasSorter: true }),
    ...constructColumnFilterSearch('name', 'Search Host name'),
    render: (text, record) => {
      return <Link to={getHostDetailRoute(record._id).path}>{text}</Link>;
    }
  },
  {
    ...constructColumn('Email Address', 'email'),
    ...constructColumnFilterSearch('email', 'Search Email Address')
  },
  {
    ...constructColumn('Contact Number', 'contactNo')
  }
];

const Hosts = () => {
  const [query, setQuery] = useState({});
  const history = useHistory();

  const { isLoading: isHostsLoading, paginatedData: hosts, total } = useGetPaginatedHosts(query);

  const isLoading = isHostsLoading;

  return (
    <Card>
      <SectionContainer>
        <Row justify="space-between" align="middle">
          <Col>
            <Title>Hosts</Title>
          </Col>
          <Col>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => history.push(getNewHostRoute().path)}>
              Add new host
            </Button>
          </Col>
        </Row>
      </SectionContainer>
      <Table
        loading={isLoading}
        rowKey={record => record._id}
        dataSource={hosts}
        columns={constructColumns()}
        scroll={{ x: 1000 }}
        pagination={{ total }}
        onChange={(pagination, filters, sorter) => handleOnAggregationTableChange({ pagination, filters, sorter }, setQuery)}
      />
    </Card>
  );
};

export default Hosts;
