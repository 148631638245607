import styled from '@emotion/styled';

import { Col } from 'antd';

export const StyledCol = styled(Col)`
  margin-bottom: 38px;
  float: left;
`;

export const StyledTitle = styled.div`
  word-wrap: break-word !important;
  color: ${props => props.theme.color.headingWithBackground};
`;
