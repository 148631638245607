import React from 'react';
import { Button, Row, Col, Radio } from 'antd';
import { FileOutlined, FilterOutlined, SearchOutlined } from '@ant-design/icons';

import { checkIsMoment, formatToDateString } from 'utils/date';
import { constructDisplayPrice } from 'utils/general';

import {
  StyledFilterDropdown,
  StyledFilterDropdownInput,
  StyledFilterDropdownButtonSearch,
  StyledFilterRadioButton,
  StyledFilterRadioContainer
} from './table.styles';

const { Group: RadioGroup } = Radio;

export const constructColumn = (title, dataIndex, { width, hasSorter, isDate, isDocument, isImage, isRM } = {}) => ({
  title,
  dataIndex,
  key: dataIndex,
  width,
  ...(hasSorter && constructColumnSorter(dataIndex)),
  render: data => {
    if (!!data) {
      let text = <span>{data}</span>;

      if (checkIsMoment(data) || isDate) {
        text = <span>{formatToDateString(data)}</span>;
      } else if (isDocument) {
        text = (
          <a href={data.url} target="_blank" rel="noopener noreferrer">
            <FileOutlined />
          </a>
        );
      } else if (isRM) {
        text = <span>{constructDisplayPrice(data)}</span>;
      } else if (isImage) {
        text = <img src={data} height="100" alt="partner logo" />;
      }

      return text;
    } else {
      return <span>-</span>;
    }
  }
});

export const constructColumnFilterSearch = (dataIndex, placeholder) => ({
  ...constructFilter(dataIndex, 'search', { dataIndex, placeholder }),
  onFilterDropdownVisibleChange: visible => visible && setTimeout(() => document.getElementById(dataIndex).focus())
});

export const constructColumnFilterRadio = (dataIndex, filterSelections) => constructFilter(dataIndex, 'radio', { filterSelections });

export const getCurrentIndexForPaginatedList = (index = 0, currentPage = 1, pageSize = 10) => {
  return (currentPage - 1) * pageSize + index;
};

export const handleOnAggregationTableChange = async ({ pagination, filters, sorter }, setNewAggregationQuery) => {
  const filter = Object.entries(filters).reduce((filterQuery, [key, values]) => ({ ...filterQuery, ...(!!values && { [key]: values[0] }) }), {});
  const { current, pageSize } = pagination;
  const { columnKey, order } = sorter;
  const query = {
    ...(Object.keys(filter).length > 0 && { filter }),
    ...(sorter && columnKey && { sorter: { [columnKey]: order === 'descend' ? -1 : 1 } }),
    ...(pagination && current && { limit: pageSize, currentPage: current })
  };
  setNewAggregationQuery(query);
};

/*---------------------------------------Local Functions-------------------------------------*/
const constructColumnSorter = dataIndex => ({
  sorter: (nextRecord, currentRecord) => {
    const nextValue = String(nextRecord[dataIndex]).toUpperCase();
    const currentValue = String(currentRecord[dataIndex]).toUpperCase();

    return nextValue.localeCompare(currentValue);
  }
});

const constructFilter = (dataIndex, type, filterComponentProps) => {
  const FilterComponent = type === 'radio' ? FilterRadio : FilterSearch;
  const FilterIconComponent = type === 'radio' ? FilterOutlined : SearchOutlined;

  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <FilterComponent
        setSelectedKeys={setSelectedKeys}
        selectedKeys={selectedKeys}
        confirm={confirm}
        clearFilters={clearFilters}
        {...filterComponentProps}
      />
    ),
    filterIcon: filtered => <FilterIconComponent style={{ color: filtered ? '#098ca3' : '#AAA' }} />,
    onFilter: (value, record) => String(record[dataIndex]).toLowerCase().includes(value.toLowerCase())
  };
};

const FilterSearch = ({ dataIndex, placeholder, setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
  <StyledFilterDropdown>
    <StyledFilterDropdownInput
      id={dataIndex}
      value={selectedKeys[0]}
      placeholder={placeholder}
      onChange={value => setSelectedKeys(value ? [value] : [])}
      onPressEnter={confirm}
    />
    <StyledFilterDropdownButtonSearch type="primary" onClick={confirm}>
      Search
    </StyledFilterDropdownButtonSearch>
    <Button onClick={clearFilters}>Reset</Button>
  </StyledFilterDropdown>
);

const FilterRadio = ({ filterSelections, setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
  <StyledFilterRadioContainer>
    <RadioGroup onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])} value={selectedKeys[0]}>
      {filterSelections.map(selection => (
        <Row key={selection.label}>
          <StyledFilterRadioButton value={selection.value}>{selection.label}</StyledFilterRadioButton>
        </Row>
      ))}
    </RadioGroup>
    <Row style={{ margin: 8 }} gutter={8} type="flex" justify="start">
      <Col>
        <Button type="primary" onClick={confirm}>
          Search
        </Button>
      </Col>
      <Col>
        <Button onClick={clearFilters}>Reset</Button>
      </Col>
    </Row>
  </StyledFilterRadioContainer>
);
