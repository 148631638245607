import React, { useState } from 'react';
import { useFetchConstant } from 'hooks/constants';
import { constructColumn, constructColumnFilterRadio, constructColumnFilterSearch, handleOnAggregationTableChange } from 'utils/table/table';
import { useGetPaginatedPartners } from 'apis/partner';
import { Link, useHistory } from 'react-router-dom';
import { getLabelOfConstant } from 'utils/general';
import { getNewPartnerRoute, getPartnerDetailsRoute } from 'utils/routes';

const { PlusOutlined } = require('@ant-design/icons');
const { Row, Col, Button, Table } = require('antd');
const { default: Card } = require('components/Card/Card');
const { default: SectionContainer } = require('components/SectionContainer/SectionContainer');
const { default: Title } = require('components/Title/Title');

const constructColumns = partnerStatuses => [
  {
    ...constructColumn('Name', 'name'),
    ...constructColumnFilterSearch('name', 'Search Partner'),
    render: (text, record) => {
      return <Link to={getPartnerDetailsRoute(record._id).path}>{text}</Link>;
    }
  },
  {
    ...constructColumn('Logo', 'logoUrl', { isImage: true })
  },
  {
    ...constructColumn('Website URL', 'websiteUrl'),
    ...constructColumnFilterSearch('websiteUrl', 'Search Web URL'),
    render: (text, record) => {
      return (
        <a href={text} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      );
    }
  },
  {
    ...constructColumn('Status', 'status'),
    ...constructColumnFilterRadio('status', partnerStatuses),
    render: status => getLabelOfConstant(status, partnerStatuses) || '-'
  }
];

const Partners = () => {
  const history = useHistory();
  const [query, setQuery] = useState({});
  const { isLoading: partnerDataLoading, paginatedData: partners, total } = useGetPaginatedPartners(query);
  const { isLoading: statusLoading, selection: partnerStatuses } = useFetchConstant('partnerStatuses');

  const isLoading = partnerDataLoading || statusLoading;

  return (
    <Card>
      <SectionContainer>
        <Row justify="space-between" align="middle">
          <Col>
            <Title>Partners</Title>
          </Col>
          <Col>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => history.push(getNewPartnerRoute().path)}>
              Add new partner
            </Button>
          </Col>
        </Row>
      </SectionContainer>
      <Table
        loading={isLoading}
        rowKey={record => record._id}
        dataSource={partners}
        columns={constructColumns(partnerStatuses)}
        scroll={{ x: 1000 }}
        pagination={{ total }}
        onChange={(pagination, filters, sorter) => handleOnAggregationTableChange({ pagination, filters, sorter }, setQuery)}
      />
    </Card>
  );
};

export default Partners;
