import { Card } from 'antd';
import styled from '@emotion/styled';

export const StyledCard = styled(Card)`
  background-color: ${props => props.theme.color.white} !important;
  width: 100%;
`;

export const StyledButton = styled.button`
  cursor: pointer;
  border: 0;
  background: transparent;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  transition: color 0.3s;
  color: rgba(0, 0, 0, 0.45);
  outline: 0;
  padding: 0;
`;

export const CloseButtonContainer = styled.div`
  display: block;
  font-style: normal;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  width: 56px;
  height: 56px;
  line-height: 56px;
  font-size: 16px;
`;
