import React from 'react';
import { Row, Col } from 'antd';

import Card from 'components/Card/Card';
import FormInput from 'components/FormInput/FormInput';
import FormRadioButton from 'components/FormRadioButton/FormRadioButton';
import FormSelection from 'components/FormSelection/FormSelection';

import {
  FORM_KEY_HOST,
  FORM_KEY_NAME,
  FORM_KEY_PROPERTY_TYPES,
  FORM_KEY_PROPERTY_CLASS_TYPE,
  FORM_KEY_IS_ALLOW_INSTANT_BOOKING,
  FORM_KEY_IS_HOLISTAY_PROMO
} from '../../utils/formKey';
import { constructGeneralToggleSelection } from 'utils/constants';

const BasicDetailsCard = ({ isEditMode, canEdit, hostSelection, propertyTypes, classTypesSelection }) => {
  return (
    <Col>
      <Card title="Basic Information">
        <Row gutter={16}>
          <Col span={24} md={12}>
            <FormSelection
              disabled={isEditMode}
              name={FORM_KEY_HOST}
              label="Select your host"
              requiredErrorMessage="Please select this property's host"
              placeholder="Choose this property's host"
              selections={hostSelection}
            />
          </Col>
          <Col span={24} md={12}>
            <FormInput
              name={FORM_KEY_NAME}
              label="Property Name"
              placeholder="Type in your property name"
              requiredErrorMessage="Property name is required"
              extraProps={{ size: 'large' }}
            />
          </Col>
          <Col span={24} md={12}>
            <FormSelection
              disabled={!canEdit}
              name={FORM_KEY_PROPERTY_TYPES}
              label="Property Type"
              requiredErrorMessage="Please select your property type"
              placeholder="Choose your property type"
              selections={propertyTypes}
            />
          </Col>
          <Col span={24} md={12}>
            <FormSelection
              name={FORM_KEY_PROPERTY_CLASS_TYPE}
              label="Property Class"
              requiredErrorMessage="Please select your property class"
              placeholder="Choose your property class"
              selections={classTypesSelection}
            />
          </Col>
          <Col span={24} md={12}>
            <FormRadioButton
              name={FORM_KEY_IS_ALLOW_INSTANT_BOOKING}
              label="Allow bookings to be confirmed instantly?"
              selections={constructGeneralToggleSelection()}
              buttonStyle="solid"
              defaultValue={false}
            />
          </Col>
          <Col span={24} md={12}>
            <FormRadioButton
              name={FORM_KEY_IS_HOLISTAY_PROMO}
              label="Enroll in HoliStay Promotion Campaign?"
              selections={constructGeneralToggleSelection()}
              buttonStyle="solid"
              defaultValue={false}
            />
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

export default BasicDetailsCard;
