import { useCustomPaginatedQuery, useCustomQuery } from 'hooks/reactQuery';
import { queryCache } from 'react-query';
import api from './apiHelper';

const getPartnerPath = params => `partner${params ? `/${params}` : ``}`;
const partnersQueryKey = 'partners';

export const postCreatePartner = payload => {
  const path = getPartnerPath();
  return api.post(path, payload).then(newPartner => {
    return newPartner;
  });
};

export const useGetPaginatedPartners = (query, onError) => {
  const getPartners = async (key, query) => {
    const path = getPartnerPath('paginated');

    return api.get(path, {
      params: query
    });
  };

  return useCustomPaginatedQuery(partnersQueryKey, getPartners, query, { onError });
};

export const useGetAllPartners = onError => {
  const getPartners = () => {
    const path = getPartnerPath();

    return api.get(path);
  };

  return useCustomQuery(partnersQueryKey, [], getPartners, { onError });
};

export const useGetPartnerDetailsById = (partnerId, onError) => {
  const getPartnerDetailById = (key, partnerId) => {
    const path = getPartnerPath(`${partnerId}`);

    return api.get(path);
  };

  return useCustomQuery(partnersQueryKey, [partnerId], getPartnerDetailById, { onError, enabled: !!partnerId });
};

export const patchUpdatePartnerStatus = (partnerId, status) => {
  const path = getPartnerPath(`${partnerId}/status`);
  return api.patch(path, { status }).then(res => {
    refetchPartnerDetails(partnerId);
    return res;
  });
};

export const patchUpdatePartner = (partnerId, payload) => {
  const path = getPartnerPath(partnerId);
  return api.patch(path, payload).then(res => {
    refetchPartnerDetails(partnerId);
    return res;
  });
};

export const deletePartner = partnerId => {
  const path = getPartnerPath(partnerId);
  return api.delete(path);
};

const refetchPartnerDetails = partnerId => queryCache.invalidateQueries([partnersQueryKey, partnerId]);
