import React, { useEffect } from 'react';
import { Skeleton } from 'antd';
import { useHistory } from 'react-router-dom';

import { withAppContext } from 'contexts/AppContext/AppContext';
import { getOverviewRoute, getLoginRoute } from 'utils/routes';

const overviewRoute = getOverviewRoute();
const loginRoute = getLoginRoute();

const App = ({ isLoggedIn, isUserLoading }) => {
  const history = useHistory();

  useEffect(() => {
    if (!isUserLoading) {
      if (isLoggedIn) {
        history.push(overviewRoute.path);
      } else {
        history.push(loginRoute.path);
      }
    }
  }, [isLoggedIn, isUserLoading, history]);

  return <Skeleton loading={true} />;
};

export default withAppContext(App);
