import React from 'react';
import PropTypes from 'prop-types';

import FormItem from 'components/FormItem/FormItem';

import { StyledFormInputNumber } from './FormInputNumber.styles';

const FormInputNumber = ({
  className,
  name,
  label,
  placeholder,

  extraRules,

  minValue,
  maxValue,
  formatter,
  parser,
  precision,
  disabled,

  requiredErrorMessage,

  onChange,
  ...props
}) => {
  const rules = [
    {
      pattern: precision ? /^\d+\.?\d*$/ : /^[0-9]*$/,
      message: precision ? `Only allow numeric input with ${precision} decimal points` : `Only allow numeric input`
    }
  ];

  return (
    <>
      <FormItem name={name} label={label} requiredErrorMessage={requiredErrorMessage} extraRules={[...rules, ...extraRules]}>
        <StyledFormInputNumber
          className={className}
          placeholder={placeholder}
          min={minValue}
          max={maxValue}
          formatter={formatter}
          parser={parser}
          precision={precision}
          disabled={disabled}
          onChange={onChange}
          {...props}
        />
      </FormItem>
    </>
  );
};

FormInputNumber.propTypes = {
  className: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  placeholder: PropTypes.string,
  extraRules: PropTypes.array,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  formatter: PropTypes.func,
  parser: PropTypes.func,
  precision: PropTypes.number,
  disabled: PropTypes.bool,
  requiredErrorMessage: PropTypes.string,
  onChange: PropTypes.func
};

FormInputNumber.defaultProps = {
  className: '',
  placeholder: '',
  extraRules: [],
  minValue: 1,
  formatter: undefined,
  parser: undefined,
  precision: 0,
  disabled: false,
  requiredErrorMessage: '',
  onChange: undefined
};

export default FormInputNumber;
