import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row, Form, Button, Skeleton, Tooltip, Modal, message, Alert } from 'antd';
import { CheckOutlined, ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';

import FormInput from 'components/FormInput/FormInput';
import FormPasswordInput from 'components/FormPasswordInput/FormPasswordInput';
import FormSelection from 'components/FormSelection/FormSelection';
import FormGroupSelectionInput from 'components/FormGroupSelectionInput/FormGroupSelectionInput';
import SectionCard from 'components/SectionCard/SectionCard';
import Title from 'components/Title/Title';
import ClickableTextButton from 'components/ClickableTextButton/ClickableTextButton';

import { withAppContext } from 'contexts/AppContext/AppContext';

import { useGetUserById, postCreateUser, patchUpdateUser, deleteUser } from 'apis/user';
import { useFetchConstant } from 'hooks/constants';
import { getUserDetailsRoute, getUsersRoute } from 'utils/routes';

import { SectionContainer, PasswordContainer, PasswordTitle, PasswordDesc, PasswordRequirement, UsernameInfoIcon } from './UserDetails.styles';

const { confirm, error } = Modal;

const User = ({ user: loggedInUser }) => {
  const history = useHistory();
  const { id: userId } = useParams();
  const { isLoading: isHostLoading, data: user } = useGetUserById(userId);
  const { isLoading: isCountryCodesLoading, selection: countryCodes } = useFetchConstant('countries', {
    labelKey: 'phoneCode',
    valueKey: 'phoneCode'
  });
  const { isLoading: isRolesLoading, selection: roles } = useFetchConstant('roles');
  const isSelf = useMemo(() => userId === loggedInUser._id, [userId, loggedInUser]);
  const isEditMode = useMemo(() => !!userId, [userId]);
  const isLoading = useMemo(() => isRolesLoading || isHostLoading || isCountryCodesLoading, [isRolesLoading, isHostLoading, isCountryCodesLoading]);
  const passwordTitle = useMemo(() => (isEditMode ? 'Reset Password' : 'Password'), [isEditMode]);

  const handleOnSave = values => {
    const payload = {
      ...values
    };
    if (isEditMode) {
      confirm({
        title: 'Are you sure you want to overwrite existing data?',
        content: 'You will not be able to undo this action, but you may update it again.',
        icon: <ExclamationCircleOutlined />,
        onOk() {
          patchUpdateUser(userId, payload)
            .then(() => {
              message.success('Update success!');
            })
            .catch(ex => {
              error({
                title: ex.message
              });
            });
        },
        onCancel() {}
      });
    } else {
      postCreateUser(payload)
        .then(newUser => {
          Modal.success({
            title: 'User has been created successfully!',
            okText: 'View created user',
            onOk() {
              history.push(getUserDetailsRoute(newUser._id).path);
            }
          });
        })
        .catch(ex => {
          error({
            title: ex.message
          });
        });
    }
  };

  const handleOnSaveFailed = ({ errorFields }) => {
    errorFields.forEach(field => message.error(field.errors[0]));
  };

  const handleOnDeleteClick = () => {
    confirm({
      title: 'Are you sure you want to delete this user?',
      content: 'You will not be able to undo this action.',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        deleteUser(userId)
          .then(() => {
            message.success('Delete success!');
            history.push(getUsersRoute().path);
          })
          .catch(ex => {
            error({
              title: ex.message
            });
          });
      },
      onCancel() {}
    });
  };

  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Form initialValues={user} scrollToFirstError={true} style={{ width: '100%' }} onFinish={handleOnSave} onFinishFailed={handleOnSaveFailed}>
          <ClickableTextButton text="Back to Users" url={getUsersRoute().path} />
          <Title marginBottom="16px">{isEditMode ? user.name : 'New User'}</Title>
          {isSelf && (
            <SectionContainer>
              <Alert message={<b>Note</b>} description="To update your profile, please go to your profile page." type="warning" showIcon />
            </SectionContainer>
          )}
          <SectionContainer>
            <SectionCard title="Basic Information">
              <Row gutter={16}>
                <Col span={12}>
                  <FormInput label="Name" name="name" requiredErrorMessage="Please enter user's name." disabled={isSelf} />
                </Col>
                <Col span={12}>
                  <FormInput
                    label="Email Address"
                    name="email"
                    type="email"
                    placeholder="example@mail.com"
                    requiredErrorMessage="Please enter email address."
                    disabled={isSelf}
                  />
                </Col>
                <Col span={12}>
                  <FormGroupSelectionInput
                    label="Contact Number"
                    inputName={['contact', 'contactNumber']}
                    inputType="number"
                    selectionName={['contact', 'countryCode']}
                    selections={countryCodes}
                    requiredErrorMessage="Please enter contact number"
                    disabled={isSelf}
                  />
                </Col>
              </Row>
            </SectionCard>
          </SectionContainer>
          <SectionContainer>
            <SectionCard title="Account Details">
              <Row gutter={16}>
                <Col span={12}>
                  <FormInput
                    label={
                      <Tooltip
                        title={'Use alphanumeric and special characters period (.), underscore (_) and hyphen (-) for a length of 6 to 20 characters'}
                        placement="right"
                      >
                        Username <UsernameInfoIcon />
                      </Tooltip>
                    }
                    name="username"
                    requiredErrorMessage="Please enter username."
                    disabled={isSelf || isEditMode}
                    type="username"
                  />
                  <FormSelection
                    label="Role"
                    name="role"
                    placeholder="Select role type"
                    requiredErrorMessage="Please select a role."
                    selections={roles}
                    disabled={isSelf}
                  />
                </Col>
                <Col span={12}>
                  <PasswordContainer>
                    <PasswordTitle>{passwordTitle}</PasswordTitle>
                    <PasswordDesc>This password will be used for logging in. Users can self-reset their password in user profile page.</PasswordDesc>
                    <PasswordRequirement>Use alphanumeric and at least 1 uppercase letter for a length of 12 - 20 characters</PasswordRequirement>
                    <FormPasswordInput
                      name="password"
                      placeholder="P@ssW0rd"
                      requiredErrorMessage={!isEditMode && "Please enter user's password."}
                      checkPattern={true}
                      disabled={isSelf}
                    />
                  </PasswordContainer>
                </Col>
              </Row>
            </SectionCard>
          </SectionContainer>
          {!isSelf && (
            <Row gutter={8} style={{ marginBottom: '32px' }}>
              <Col>
                <Button htmlType="submit" type="primary" icon={<CheckOutlined />}>
                  {isEditMode ? 'Save' : 'Create'}
                </Button>
              </Col>
              {isEditMode && (
                <Col>
                  <Button type="ghost" danger icon={<DeleteOutlined />} onClick={handleOnDeleteClick}>
                    Delete
                  </Button>
                </Col>
              )}
            </Row>
          )}
        </Form>
      )}
    </>
  );
};

export default withAppContext(User);
