import { Button, Radio } from 'antd';
import styled from '@emotion/styled';

import Input from 'components/Input/Input';

export const StyledFilterDropdown = styled.div`
  padding: 8px;
  border-radius: 6px;
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
`;

export const StyledFilterDropdownInput = styled(Input)`
  width: 150px;
  margin-right: 8px;
`;

export const StyledFilterDropdownButtonSearch = styled(Button)`
  margin-right: 4px;
`;

export const StyledFilterRadioContainer = styled.div`
  padding: 8px;
  overflow-y: scroll;
`;

export const StyledFilterRadioButton = styled(Radio)`
  display: block;
  height: 30px;
  line-height: 30px;
`;
