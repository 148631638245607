import { EMAIL_ADDRESS_REGEX } from 'utils/constants';

/* ------------------------------------------------General function------------------------------------------------- */
const constructDisplayNumber = (number, decimal = 2) => {
  const numberWithDecimal = number.toFixed(decimal);
  const numberWithDecimalAndComma = numberWithDecimal.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return numberWithDecimalAndComma;
};

export const getSingularOrPluralLabel = (amount, singularLabel, { pluralLabel, labelOnly } = {}) => {
  const returnLabel = amount === 1 ? singularLabel : pluralLabel || `${singularLabel}s`;
  const returnAmount = labelOnly ? '' : amount;

  return `${returnAmount} ${returnLabel}`.trim();
};

export const getLabelOfConstant = (selectedConstant, constants, constantField = 'value') => {
  const foundConstant = constants.find(constant => constant[constantField] === selectedConstant);
  return foundConstant && foundConstant.label;
};

export const checkIsObjectEmpty = object => {
  return !object || Object.keys(object).length === 0;
};

export const checkHasValue = value => {
  return value !== undefined && value !== null;
};

export const checkIsEmail = value => EMAIL_ADDRESS_REGEX.test(value);

export const guard = (callback, fallbackValue) => {
  try {
    const value = callback();
    if (value === undefined || value === null) {
      return fallbackValue;
    }

    return value;
  } catch {
    return fallbackValue;
  }
};

export const generateCode = (prefix = '', postfix = '') => {
  return prefix + (Math.random().toString(36) + Date.now().toString(36)).substr(2, 9).toUpperCase() + postfix;
};

/* ------------------------------------------------Specific function------------------------------------------------- */
// Rate
export const constructDisplayPrice = (feeAmount, hasPrefix = true) => {
  const displayFee = String(constructDisplayNumber(feeAmount));
  const displayFeeWithPrefix = hasPrefix ? `RM ${displayFee}` : displayFee;

  return displayFeeWithPrefix;
};

export const constructDisplayPercentage = (percentage, isString) => {
  const numberPercentage = percentage * 100;
  const displayPercentage = `${numberPercentage}%`;

  return isString ? displayPercentage : numberPercentage;
};

export const constructPercentageFromDisplay = displayPercentage => {
  const percentage = displayPercentage / 100;

  return percentage;
};

// Listing
export const convertAmenitiesSelectionToObj = (listOfAmenities, amenitiesSelection) => {
  return guard(() =>
    Object.keys(amenitiesSelection).reduce((objectAmenitiesSelection, currentKey) => {
      objectAmenitiesSelection[currentKey] = listOfAmenities.filter(
        payloadValue => !!amenitiesSelection[currentKey].data.find(amentity => amentity.key === payloadValue)
      );
      return objectAmenitiesSelection;
    }, {})
  );
};
