import moment from 'moment';

import { DATE_FORMAT, FORMAL_DATE_FORMAT, DATE_DATE_TIME_FORMAT, MONTH_FORMAT, TIME_FORMAT } from 'utils/constants';

/* ----------------------------------------base moment related functions----------------------------------------------- */
export const getTodayMoment = () => moment().startOf('day');
export const getCurrentMoment = () => moment();
export const getCurrentMonth = () => moment().startOf('month');
export const getCurrentMonthEnd = () => moment().endOf('month');

export const addMoment = (date, amount, dateUnit, shouldReturnString) => {
  const dateMoment = moment(date);
  const updatedDateMoment = dateMoment.add(amount, dateUnit.toLowerCase());

  return shouldReturnString ? formatToDateString(updatedDateMoment) : updatedDateMoment;
};

export const checkIsMoment = data => {
  return moment.isMoment(data);
};

export const formatToDateString = date => {
  return !!date ? moment(date).format(DATE_FORMAT) : date;
};

export const formatToFormalDateString = date => {
  return !!date ? moment(date).format(FORMAL_DATE_FORMAT) : date;
};

export const formatToMonthString = date => {
  return !!date ? moment(date).format(MONTH_FORMAT) : date;
};

export const formatToDateTimeString = date => {
  return !!date ? moment(date).format(DATE_DATE_TIME_FORMAT) : date;
};

export const formatToMomentObject = date => {
  return !!date ? moment(date) : date;
};

export const formatToTimeMoment = date => {
  return !!date ? moment(date, TIME_FORMAT) : date;
};

export const formatToTimeString = timeMoment => {
  return !!timeMoment ? moment(timeMoment).format(TIME_FORMAT) : timeMoment;
};

/* ----------------------------------------return boolean----------------------------------------------- */
export const checkIsDate = (type, date1, date2, canOverlap) => {
  const date1Moment = moment(date1);
  const date2Moment = moment(date2);
  const typeInLowerCase = type.toLowerCase();

  const checkIsDateSame = () => date1Moment.isSame(date2Moment);
  const checkIsDateBefore = () => date1Moment.isBefore(date2Moment);
  const checkIsDateAfter = () => date1Moment.isAfter(date2Moment);

  switch (typeInLowerCase) {
    case 'same':
      return checkIsDateSame();

    case 'before':
      return checkIsDateBefore() || (canOverlap && checkIsDateSame());

    case 'after':
      return checkIsDateAfter() || (canOverlap && checkIsDateSame());

    default:
      return false;
  }
};

export const checkIsDateInBetween = (currentDate, startDate, endDate, overlapStart, overlapEnd) => {
  return checkIsDate('after', currentDate, startDate, overlapStart) && checkIsDate('before', currentDate, endDate, overlapEnd);
};
