import React from 'react';
import { Form, Input, Select } from 'antd';
import PropTypes from 'prop-types';

import { FormLabel, StyledSelect, StyledInput } from './FormGroupSelectionInput.styles';

const FormItem = Form.Item;
const InputGroup = Input.Group;

const { Option } = Select;

const FormGroupSelectionInput = ({
  disabled,
  extraProps,
  inputDefaultValue,
  inputPlaceholder,
  inputName,
  inputType,
  label,
  name,
  onFocus,
  selectionName,
  selections,
  selectionPlaceholder,
  selectionDefaultValue,
  requiredErrorMessage
}) => {
  const generatePropsForRules = () => {
    const rules = [];
    if (requiredErrorMessage && typeof requiredErrorMessage === 'string') {
      rules.push({
        required: true,
        message: requiredErrorMessage
      });
    }
    return rules;
  };

  return (
    <>
      {label && <FormLabel>{label}</FormLabel>}
      <FormItem>
        <InputGroup compact>
          <FormItem noStyle name={selectionName} rules={generatePropsForRules()} onFocus={() => onFocus(name)}>
            <StyledSelect placeholder={selectionPlaceholder} defaultValue={selectionDefaultValue} disabled={disabled}>
              {selections.map(selection => (
                <Option key={selection.value} value={selection.value}>
                  {selection.label || selection.value}
                </Option>
              ))}
            </StyledSelect>
          </FormItem>
          <FormItem noStyle name={inputName} rules={generatePropsForRules()} onFocus={() => onFocus(name)}>
            <StyledInput placeholder={inputPlaceholder} defaultValue={inputDefaultValue} type={inputType} {...extraProps} disabled={disabled} />
          </FormItem>
        </InputGroup>
      </FormItem>
    </>
  );
};

export default FormGroupSelectionInput;

FormGroupSelectionInput.propTypes = {
  disabled: PropTypes.bool,
  extraProps: PropTypes.object,
  onFocus: PropTypes.func,
  requiredErrorMessage: PropTypes.string,
  selections: PropTypes.array
};

FormGroupSelectionInput.defaultProps = {
  disabled: false,
  extraProps: {},
  onFocus: () => {},
  selections: []
};
