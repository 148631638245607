import styled from '@emotion/styled';
import { Button, Menu } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import BackgroundImg from './images/background.jpg';

export const StyledFoldIcon = styled(MenuFoldOutlined)`
  font-size: 20px;
  line-height: 64px;
  cursor: pointer;
  color: ${props => props.theme.color.secondary};
`;

export const StyledUnfoldIcon = styled(MenuUnfoldOutlined)`
  font-size: 20px;
  line-height: 64px;
  cursor: pointer;
  color: ${props => props.theme.color.secondary};
`;

export const StyledHeaderContainer = styled.div`
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  background-image: url(${BackgroundImg});
  background-size: cover;
  background-position-y: center;
`;

export const AvatarButton = styled(Button)`
  background-color: ${props => props.theme.color.primary};
  border-radius: 50%;
  border: none;
  color: ${props => props.theme.color.headingWithBackground};
  font-size: 16px;
  font-weight: ${props => props.theme.fontWeight.bold};
  padding: 1px 7px 2px;
  height: 48px;
  width: 48px;
  cursor: pointer;
`;

export const MenuItem = styled(Menu.Item)`
  padding: ${props => props.theme.spacing.regular};
`;
