import { useCustomPaginatedQuery, useCustomQuery } from 'hooks/reactQuery';
import { queryCache } from 'react-query';
import api from './apiHelper';

const getVoucherPath = params => `voucher${params ? `/${params}` : ``}`;
const vouchersQueryKey = 'vouchers';

export const useGetPaginatedVouchers = (query, onError) => {
  const getVouchers = async (key, query) => {
    const path = getVoucherPath('paginated');

    return api.get(path, {
      params: query
    });
  };

  return useCustomPaginatedQuery(vouchersQueryKey, getVouchers, query, { onError });
};

export const postCreateVoucher = payload => {
  const path = getVoucherPath();
  return api.post(path, payload).then(newVoucher => {
    return newVoucher;
  });
};

export const patchUpdateVoucher = (voucherId, payload) => {
  const path = getVoucherPath(voucherId);
  return api.patch(path, payload).then(res => {
    refetchVoucherDetails(voucherId);
    return res;
  });
};

export const useGetVoucherDetailsById = (voucherId, onError) => {
  const getVoucherDetailById = (key, voucherId) => {
    const path = getVoucherPath(`${voucherId}`);

    return api.get(path);
  };

  return useCustomQuery(vouchersQueryKey, [voucherId], getVoucherDetailById, { onError, enabled: !!voucherId });
};

export const patchUpdateVoucherStatus = (voucherId, status) => {
  const path = getVoucherPath(`${voucherId}/status`);
  return api.patch(path, { status }).then(res => {
    refetchVoucherDetails(voucherId);
    return res;
  });
};

export const deleteVoucher = voucherId => {
  const path = getVoucherPath(voucherId);
  return api.delete(path);
};

const refetchVoucherDetails = voucherId => queryCache.invalidateQueries([vouchersQueryKey, voucherId]);
