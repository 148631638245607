import api from './apiHelper';
import { queryCache } from 'react-query';
import { useCustomQuery, useCustomPaginatedQuery } from 'hooks/reactQuery';

const getPropertyPath = params => `property${params ? `/${params}` : ``}`;

/* ------------------------------- POST CREATE -------------------------------------- */

export const postCreateProperty = payload => {
  const path = getPropertyPath();

  return api.post(path, payload).then(createdProperty => {
    refetchProperties();
    return createdProperty;
  });
};

/* ---------------------------------- GET ---------------------------------------- */

export const useGetPaginatedProperties = (query, onError) => {
  const getProperties = async (key, query) => {
    const path = getPropertyPath('paginated');
    return api.get(path, {
      params: query
    });
  };

  return useCustomPaginatedQuery('properties', getProperties, query, {
    onError
  });
};

export const useGetProperties = () => {
  const getProperties = () => {
    const path = getPropertyPath();
    return api.get(path);
  };
  return useCustomQuery('getProperties', [], getProperties, { shouldDefaultEmptyObject: false });
};

export const useGetPropertyDetailsById = (listingId, onError) => {
  const getPropertyDetailsById = (key, listingId) => {
    const path = getPropertyPath(`details/${listingId}`);

    return api.get(path);
  };

  return useCustomQuery('property', [listingId], getPropertyDetailsById, { onError, enabled: !!listingId });
};

export const useGetPropertyQRIsEnabled = (listingId, onError) => {
  const getQrEnabledStatus = (key, listingId) => {
    const path = getPropertyPath(`qr-enabled-status/${listingId}`);
    return api.get(path);
  };

  return useCustomQuery('propertyQR', [listingId], getQrEnabledStatus, { onError, enabled: !!listingId });
};

/* ---------------------------------- PATCH UPDATE ----------------------------------- */

export const patchUpdateProperty = (propertyId, payload) => {
  const path = getPropertyPath(propertyId);
  return api.patch(path, payload).then(updatedProperty => {
    refetchProperties();
    refetchPropertyDetails(updatedProperty._id);
    return updatedProperty;
  });
};

export const patchUpdatePropertyStatus = (propertyId, status) => {
  const path = getPropertyPath(`${propertyId}/status/${status}`);
  return api.patch(path).then(updatedProperty => {
    refetchProperties();
    refetchPropertyDetails(updatedProperty._id);
    return updatedProperty;
  });
};

export const patchQREnabledStatus = (propertyId, isEnabled) => {
  const path = getPropertyPath(`${propertyId}/qr-enable/${isEnabled}`);
  return api.patch(path).then(updatedQREnableStatus => {
    return updatedQREnableStatus;
  });
};

/* ---------------------------------- DELETE UPDATE ----------------------------------- */

export const deleteProperty = propertyId => {
  const path = getPropertyPath(propertyId);
  return api.delete(path).then(deletedProperty => {
    refetchProperties();
    return deletedProperty;
  });
};

/* ---------------------------------- REFETCH FUNCTION ----------------------------------- */
const refetchProperties = () => queryCache.invalidateQueries(['properties']);
const refetchPropertyDetails = propertyId => queryCache.invalidateQueries(['property', propertyId]);
