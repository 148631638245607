import React from 'react';
import { Text } from './Title.styles';

const Title = ({ children, type = 'default', marginBottom = '0px' }) => {
  return (
    <Text type={type} marginBottom={marginBottom}>
      {children}
    </Text>
  );
};

export default Title;
