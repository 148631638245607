import { message, Row, Tabs } from 'antd';
import { patchUpdatePartnerStatus, useGetPartnerDetailsById } from 'apis/partner';
import ActivationButton from 'components/ActivationButton/ActivationButton';
import Card from 'components/Card/Card';
import ClickableTextButton from 'components/ClickableTextButton/ClickableTextButton';
import Title from 'components/Title/Title';
import { useFetchConstant } from 'hooks/constants';
import React from 'react';
import { useParams } from 'react-router-dom';
import { guard } from 'utils/general';
import { getPartnersRoute } from 'utils/routes';
import PartnerForm from './PartnerForm/PartnerForm';

const TabPane = Tabs.TabPane;

const PartnerDetails = () => {
  const { partnerId } = useParams();
  const isEditMode = !!partnerId;

  const { data: partnerDetails, isLoading: isLoadingPartnerDetails } = useGetPartnerDetailsById(partnerId);
  const { isLoading: statusLoading, data: partnerStatuses } = useFetchConstant('partnerStatuses');
  const isLoading = isLoadingPartnerDetails || statusLoading;

  const status = guard(() => partnerDetails.status, undefined);
  const isActivated = !statusLoading && status === partnerStatuses.ACTIVE.code;

  const handleOnActivate = () =>
    patchUpdatePartnerStatus(partnerId, partnerStatuses.ACTIVE.code).then(() => {
      message.success(`Partner has been successfully activated.`);
    });

  const handleOnDeactivate = () =>
    patchUpdatePartnerStatus(partnerId, partnerStatuses.INACTIVE.code).then(() => {
      message.success(`Partner has been successfully deactivated.`);
    });

  return (
    !isLoading && (
      <div style={{ width: '100%' }}>
        <ClickableTextButton text="Back to Partners" url={getPartnersRoute().path} />
        <Row type="flex" align="middle" style={{ marginBottom: '16px' }}>
          <Title>{isEditMode ? partnerDetails && partnerDetails.name : 'New Partner'}</Title>
        </Row>
        <Card>
          <Tabs
            defaultActiveKey="1"
            tabBarExtraContent={
              <ActivationButton
                id={partnerId}
                status={status}
                isActivated={isActivated}
                label="partner"
                onActivate={handleOnActivate}
                onDeactivate={handleOnDeactivate}
              />
            }
          >
            <TabPane tab="Partner" key="1">
              <PartnerForm isEditMode={isEditMode} partnerDetails={partnerDetails} />
            </TabPane>
          </Tabs>
        </Card>
      </div>
    )
  );
};

export default PartnerDetails;
