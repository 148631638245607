import styled from '@emotion/styled';
import { Row } from 'antd';

export const NavButton = styled(Row)`
  color: ${props => props.theme.color.primary};
  cursor: pointer;
  height: 32px;
  margin-bottom: ${props => props.theme.spacing.regular};
`;

export const IconContainer = styled.span`
  display: block;
  margin-right: ${props => props.theme.spacing.xs};
`;

export const Text = styled.span`
  &:hover {
    border-bottom: 1px solid ${props => props.theme.color.primary};
  }
`;
