import React, { useEffect, useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Alert, Col, Row, Form, List, Modal, message } from 'antd';
import PropTypes from 'prop-types';

import { patchUpdateBookingFinancialInfo, refetchBooking } from 'apis/booking';
import { constructDisplayPrice } from 'utils/general';

import FormInput from 'components/FormInput/FormInput';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import Title from 'components/Title/Title';

const { useForm } = Form;
const { confirm, error } = Modal;

const FinancialInfoModal = ({ bookingCode, financialDetails, bookingId, visible, onSave, onCancel }) => {
  const [totalNettAmtByRooms, setTotalNettAmtByRooms] = useState({});
  const [form] = useForm();
  useEffect(() => {
    const totalNettAmtByRooms = {};
    financialDetails &&
      financialDetails.rooms.forEach(room => {
        totalNettAmtByRooms[`${room._id}`] = room.priceDetails.hostNettAmountPerRoom * room.roomCount;
      });
    setTotalNettAmtByRooms(totalNettAmtByRooms);
  }, [financialDetails]);

  const constructUpdatePayloadForRooms = values => {
    const formValues = { ...values };
    delete formValues.remarks;
    return Object.keys(formValues).map(key => ({ refId: key, hostNettAmountPerRoom: formValues[key] }));
  };

  const handleOnSaveButtonClick = async e => {
    e.preventDefault();
    try {
      const values = await form.validateFields();
      confirm({
        title: 'Are you sure you want to top up this package?',
        content: 'You will not be able to undo this action.',
        icon: <ExclamationCircleOutlined />,
        onOk() {
          const payload = {
            rooms: constructUpdatePayloadForRooms(values),
            remarks: values.remarks
          };
          patchUpdateBookingFinancialInfo(bookingId, payload)
            .then(updatedBooking => {
              message.success('Update success!');
              refetchBooking(bookingId);
              onSave();
            })
            .catch(ex => {
              error({
                title: 'Financial info could not be updated.',
                content: ex.message
              });
            });
        },
        okText: 'Yes, I want to update these info.',
        onCancel() {}
      });
    } catch (error) {
      error.errorFields.forEach(field => message.error(field.errors[0]));
    }
  };

  const formatInitialValue = data => {
    const formInitialValue = { ...data };
    data.rooms.forEach(room => (formInitialValue[`${room._id}`] = room.priceDetails.hostNettAmountPerRoom));
    return formInitialValue;
  };

  const handleNettAmountChanges = (_id, roomCount) => newNettAmt => {
    setTotalNettAmtByRooms({
      ...totalNettAmtByRooms,
      [_id]: newNettAmt * roomCount
    });
  };

  return (
    <Modal
      title={`Financial Info for Booking ${bookingCode}`}
      visible={visible}
      onOk={handleOnSaveButtonClick}
      onCancel={onCancel}
      okText="Save these information"
    >
      <Form form={form} initialValues={formatInitialValue(financialDetails)}>
        <Alert
          message="All amount must be the nett amount that host will receive. Remember to deduct any fees charged to the host."
          type="warning"
          showIcon
        />
        <List
          dataSource={financialDetails.rooms}
          renderItem={item => (
            <List.Item key={item._id}>
              <List.Item.Meta
                title={
                  <Title type="s">
                    {item.roomCount} x {item.name}
                  </Title>
                }
                description={
                  <Row>
                    <Col span={24}>
                      <p>Total: {constructDisplayPrice(Object.keys(totalNettAmtByRooms).length > 0 ? totalNettAmtByRooms[item._id] : 0)}</p>
                    </Col>
                    <Col span={24}>
                      <FormInputNumber
                        label="Nett Amount for Host Per Room"
                        name={`${item._id}`}
                        placeholder="300"
                        minValue={0}
                        precision={2}
                        requiredErrorMessage="Please enter nett amount for host per room."
                        onChange={handleNettAmountChanges(item._id, item.roomCount)}
                      />
                    </Col>
                  </Row>
                }
              />
            </List.Item>
          )}
        />
        <Row>
          <FormInput name="remarks" label="Internal Notes" type="textArea" placeholder="(Optional)" />
        </Row>
      </Form>
    </Modal>
  );
};

FinancialInfoModal.propTypes = {
  bookingCode: PropTypes.string,
  bookingId: PropTypes.string.isRequired,
  financialDetails: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

FinancialInfoModal.defaultProps = {
  bookingCode: 'this package',
  financialDetails: {},
  visible: false,
  onSave: () => {},
  onCancel: () => {}
};

export default FinancialInfoModal;
