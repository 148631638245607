import React, { useEffect, useRef } from 'react';
import { message, Row, Tabs } from 'antd';
import { useParams } from 'react-router-dom';
import { useFetchConstant } from 'hooks/constants';
import ClickableTextButton from 'components/ClickableTextButton/ClickableTextButton';
import { getVouchersRoute } from 'utils/routes';
import Title from 'components/Title/Title';
import Card from 'components/Card/Card';
import VoucherDetailsCard from './component/VoucherDetailsCard';
import { useGetAllPartners } from 'apis/partner';
import { patchUpdateVoucherStatus, useGetVoucherDetailsById } from 'apis/voucher';
import { guard } from 'utils/general';
import ActivationButton from 'components/ActivationButton/ActivationButton';

const TabPane = Tabs.TabPane;

const VoucherDetails = () => {
  const { voucherId } = useParams();
  const isEditMode = !!voucherId;

  const { data: voucherDetails, isLoading: isLoadingVoucherDetails } = useGetVoucherDetailsById(voucherId);
  const { isLoading: statusLoading, data: voucherStatuses } = useFetchConstant('voucherStatuses');

  // partner datas for partner selection at voucher form
  const { data: partners, isLoading: isLoadingPartners } = useGetAllPartners();
  const partnerDatas = useRef();
  useEffect(() => {
    if (partners.length > 0) {
      partnerDatas.current = partners.map(val => {
        return {
          label: val.name,
          value: val._id,
          disabled: false
        };
      });
    }
  }, [partners]);

  const isLoading = isLoadingVoucherDetails || statusLoading || isLoadingPartners;

  const status = guard(() => voucherDetails.status, undefined);
  const isActivated = !statusLoading && status === voucherStatuses.ACTIVE.code;

  const handleOnActivate = () =>
    patchUpdateVoucherStatus(voucherId, voucherStatuses.ACTIVE.code).then(() => {
      message.success(`Voucher has been successfully activated.`);
    });

  const handleOnDeactivate = () =>
    patchUpdateVoucherStatus(voucherId, voucherStatuses.INACTIVE.code).then(() => {
      message.success(`Voucher has been successfully deactivated.`);
    });

  return (
    !isLoading && (
      <div style={{ width: '100%' }}>
        <ClickableTextButton text="Back to Vouchers" url={getVouchersRoute().path} />
        <Row type="flex" align="middle" style={{ marginBottom: '16px' }}>
          <Title>{isEditMode ? voucherDetails && voucherDetails.name : 'New Voucher'}</Title>
        </Row>
        <Card>
          <Tabs
            defaultActiveKey="1"
            tabBarExtraContent={
              <ActivationButton
                id={voucherId}
                status={status}
                isActivated={isActivated}
                label="voucher"
                onActivate={handleOnActivate}
                onDeactivate={handleOnDeactivate}
              />
            }
          >
            <TabPane tab="Voucher" key="1">
              <VoucherDetailsCard isEditMode={isEditMode} voucherDetails={voucherDetails} partners={partnerDatas.current} />
            </TabPane>
          </Tabs>
        </Card>
      </div>
    )
  );
};

export default VoucherDetails;
