import styled from '@emotion/styled';
import { Row } from 'antd';

export const SectionContainer = styled.div`
  margin-bottom: ${props => props.theme.spacing.md};
`;

export const PasswordContainer = styled.div`
  background-color: ${props => props.theme.color.secondary};
  border: none;
  box-shadow: 0 2px 2px ${props => props.theme.color.primary}33;
  padding: ${props => props.theme.spacing.regular} !important;
  padding-bottom: ${props => props.theme.spacing.xxs} !important;
  ${props => props.isSticky && 'position: fixed'};
  width: auto;
`;

export const PasswordTitle = styled(Row)`
  color: ${props => props.theme.color.primary};
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: 16px;
`;

export const PasswordDesc = styled(Row)`
  color: ${props => props.theme.color.primary};
  font-size: 14px;
  line-height: normal;
  padding-top: ${props => props.theme.spacing.xs} !important;
`;

export const PasswordRequirement = styled(Row)`
  color: ${props => props.theme.color.primary};
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: 14px;
  line-height: normal;
  padding-top: ${props => props.theme.spacing.xs} !important;
  padding-bottom: ${props => props.theme.spacing.xs} !important;
`;
