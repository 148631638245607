import styled from '@emotion/styled';
import { Layout } from 'antd';

const { Sider } = Layout;

export const RootLayout = styled(Layout)`
  min-width: 768px;
`;

export const ContentLayout = styled(Layout)`
  background-color: ${props => props.theme.color.white};
  height: 100vh;
  overflow-y: scroll;
`;

export const StyledBody = styled.div`
  background-color: ${props => props.theme.color.white};
  min-height: 92vh;
  overflow-x: scroll;
`;

export const ContentContainer = styled.div`
  background-color: ${props => props.theme.color.white};
  display: flex;
  justify-content: center;
  overflow: auto;
  padding: ${props => props.theme.spacing.md};
`;

export const StyledSider = styled(Sider)`
  background-color: ${props => props.theme.color.white};
  height: 100vh;
`;

export const LogoContainer = styled.div`
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: center;
  padding: ${props => props.theme.spacing.xs};
`;

export const Image = styled.img`
  width: ${props => (props.isCollapsed ? '100%' : 'auto')};
  height: ${props => (props.isCollapsed ? 'auto' : '100%')};
  object-fit: scale-down;
`;
