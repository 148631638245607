import styled from '@emotion/styled';
import { Button, Row, Tag } from 'antd';

export const StatusUpdateButton = styled(Button)`
  color: ${props => props.theme.color.headingWithBackground};
  background: ${props => (props.actionType === 'accept' ? props.theme.color.green : props.theme.color.red)};
  border: none;
`;

export const Divider = styled.hr`
  background-color: ${props => props.theme.color.primary};
  height: ${props => props.height || '1px'};
  border: none;
`;

export const ExtraInfoText = styled.span`
  font-style: italic;
  text-decoration: ${props => props.strikethrough && 'line-through'};
  font-weight: ${props => props.theme.fontWeight.light};
  font-size: ${props => (props.fontSize || 14) + 'px'};
`;

export const InfoText = styled.div`
  color: ${props => props.fontColor || props.theme.color.headingColor};
  font-size: ${props => (props.fontSize || 14) + 'px'};
  font-weight: ${props => props.theme.fontWeight[props.fontWeight || 'regular']};
  word-break: break-all;
`;

export const StatusTag = styled(Tag)`
  font-size: 12px;
  line-height: 28px;
  font-weight: ${props => props.theme.fontWeight.bolder};
  margin-left: ${props => props.theme.spacing.xs};
`;

export const StyledContainer = styled.div`
  background-color: ${props => props.theme.color.white};
  width: 100%;
  margin-bottom: 20px;
`;

export const TitleContainer = styled(Row)`
  margin-bottom: ${props => props.theme.spacing.regular};
`;

export const RoomsContainer = styled(Row)`
  margin-bottom: ${props => props.theme.spacing.regular};
  padding-bottom: ${props => props.theme.spacing.regular};
  border-bottom: 1px ${props => props.theme.color.borderColor} solid;
`;

export const AddRoomButton = styled(Button)`
  border-radius: 4px;
  border: 1px solid ${props => props.theme.color.primary};
  color: ${props => props.theme.color.primary};
  height: 100%;
  width: 100%;
`;
