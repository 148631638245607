import React from 'react';
import { Modal } from 'antd';

import { Button } from './ActivationButton.styles';

const { confirm } = Modal;

const ActivationButton = ({ id, label, isActivated, onActivate, onDeactivate }) => {
  const handleOnActivateButtonClick = () => {
    confirm({
      title: `Are you sure to activate this ${label}?`,
      okText: `Yes`,
      onOk() {
        onActivate();
      }
    });
  };

  const handleOnDeactivateButtonClick = () => {
    confirm({
      title: `Are you sure to deactivate this ${label}?`,
      okText: `Yes`,
      width: 430,
      onOk() {
        onDeactivate();
      }
    });
  };

  if (id) {
    return isActivated ? (
      <Button type="primary" actionType="deactivate" onClick={handleOnDeactivateButtonClick}>
        Deactivate {label}
      </Button>
    ) : (
      <Button type="primary" actionType="activate" onClick={handleOnActivateButtonClick} style={{ marginRight: 5 }}>
        Activate {label}
      </Button>
    );
  }
  return null;
};

export default ActivationButton;
