import { Col, Row } from 'antd';
import FormInput from 'components/FormInput/FormInput';
import FormSinglePhoto from 'components/FormSinglePhoto/FormSinglePhoto';
import SectionCard from 'components/SectionCard/SectionCard';
import SectionContainer from 'components/SectionContainer/SectionContainer';
import React from 'react';
import { FOLDER_PARTNERS } from 'utils/constants';

const PartnerDetailsCard = ({ isEditMode, form }) => {
  return (
    <SectionContainer>
      <SectionCard title="Basic Information">
        <Row gutter={16}>
          <Col span={24} md={12}>
            <FormInput
              // disabled={isEditMode}
              label="Name"
              name="name"
              requiredErrorMessage="Type in partner's name."
            />
          </Col>
          <Col span={24} md={12}>
            <FormInput
              // disabled={isEditMode}
              label="Website URL"
              name="websiteUrl"
              requiredErrorMessage="Type in partner's website URL."
            />
          </Col>
          <Col span={24} md={12}>
            <FormSinglePhoto
              label="Upload Partner Logo"
              name="logoUrl"
              form={form}
              // disabled={isEditMode}
              folderName={FOLDER_PARTNERS}
            />
          </Col>
        </Row>
      </SectionCard>
    </SectionContainer>
  );
};

export default PartnerDetailsCard;
