import React from 'react';
import { Row, Col } from 'antd';

import Card from 'components/Card/Card';
import FormInput from 'components/FormInput/FormInput';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import Title from 'components/Title/Title';

import FormRowCounter from '../RowFormCounter/RowFormCounter';

const FIELD_NAME_NAME = 'name';
const FIELD_NAME_ROOM_SIZE = 'roomSizeInSqFt';
const FIELD_NAME_WEEKDAY_PRICE = 'weekdayPrice';
const FIELD_NAME_WEEKEND_PRICE = 'weekendPrice';
const FIELD_NAME_INVENTORY = 'inventory';
const FIELD_NAME_CAPACITY_ADULT = 'adultCapacity';
const FIELD_NAME_CAPACITY_CHILDREN = 'childrenCapacity';

const BasicDetailsCard = ({ canEdit, form, isEditMode }) => {
  return (
    <Col>
      <Card title="Basic Information">
        <Row gutter={16}>
          <Col span={24} md={12}>
            <FormInput
              name={FIELD_NAME_NAME}
              label="Name"
              placeholder="Type in your room type name"
              requiredErrorMessage="Name is required"
              extraProps={{ size: 'large' }}
            />
          </Col>
          <Col span={24} md={12}>
            <FormInputNumber
              disabled={!canEdit}
              name={FIELD_NAME_ROOM_SIZE}
              label="Room Size (sqft)"
              placeholder="Size of your room type"
              minValue={1}
              maxValue={9999}
              requiredErrorMessage="Please provide size of your room type in sqft"
              size="large"
            />
          </Col>
          <Col span={24} md={12}>
            <FormInputNumber
              name={FIELD_NAME_WEEKDAY_PRICE}
              label="Weekday Pricing"
              placeholder="Price to charge on weekday"
              minValue={1}
              maxValue={9999}
              requiredErrorMessage="Please set a price for weekday"
              size="large"
            />
          </Col>
          <Col span={24} md={12}>
            <FormInputNumber
              name={FIELD_NAME_WEEKEND_PRICE}
              label="Weekend Pricing"
              placeholder="Price to charge on weekend"
              minValue={1}
              maxValue={9999}
              requiredErrorMessage="Please set a price for weekday"
              size="large"
            />
          </Col>
        </Row>
        {/* <p style={{ marginBottom: '12px', fontSize: '16px' }}> Inventory </p> */}
        <Title type="s" marginBottom="16px">
          Inventory
        </Title>
        <FormRowCounter form={form} name={FIELD_NAME_INVENTORY} label="Inventory" disable={isEditMode} minValue={1} />
        {/* <p style={{ marginBottom: '12px', fontSize: '16px' }}> Max Occupancy </p> */}
        <Title type="s" marginBottom="16px">
          Max Occupancy
        </Title>
        <FormRowCounter form={form} name={FIELD_NAME_CAPACITY_ADULT} label="Adult" disable={!canEdit} minValue={1} />
        <FormRowCounter form={form} name={FIELD_NAME_CAPACITY_CHILDREN} label="Children" disable={!canEdit} />
      </Card>
    </Col>
  );
};

export default BasicDetailsCard;
