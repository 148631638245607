import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row, Form, Button, Skeleton, Modal, message } from 'antd';
import { CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import FormPasswordInput from 'components/FormPasswordInput/FormPasswordInput';
import Title from 'components/Title/Title';

import { withAppContext } from 'contexts/AppContext/AppContext';

import { useGetSelf, patchUpdateSelf } from 'apis/user';
import { PasswordContainer, PasswordTitle, PasswordDesc, PasswordRequirement } from './ResetPassword.styles';
import { buildBaseUri } from 'utils/routes';

const { confirm, error } = Modal;

const ResetPassword = ({ user: loggedInUser, onLogout }) => {
  const history = useHistory();
  const { isLoading: isUserLoading, data: user } = useGetSelf();
  const isLoading = useMemo(() => isUserLoading, [isUserLoading]);

  const handleOnSave = values => {
    const payload = {
      ...values
    };
    confirm({
      title: 'Are you sure you want to reset your password?',
      content: 'You will be logged out and please use your new password for the next login.',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        patchUpdateSelf(payload)
          .then(() => {
            message.success('Reset success!');
            history.push(buildBaseUri());
            onLogout();
          })
          .catch(ex => {
            error({
              title: ex.message
            });
          });
      },
      onCancel() {}
    });
  };

  const handleOnSaveFailed = ({ errorFields }) => {
    errorFields.forEach(field => message.error(field.errors[0]));
  };

  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Form initialValues={user} scrollToFirstError={true} style={{ width: '100%' }} onFinish={handleOnSave} onFinishFailed={handleOnSaveFailed}>
          <Title marginBottom="16px">Reset Password</Title>
          <PasswordContainer>
            <PasswordTitle>Your New Password</PasswordTitle>
            <PasswordDesc>This password will be used for logging in. Users can self-reset their password in user profile page.</PasswordDesc>
            <PasswordRequirement>Use alphanumeric and at least 1 uppercase letter for a length of 12 - 20 characters</PasswordRequirement>
            <FormPasswordInput name="password" placeholder="P@ssW0rd" requiredErrorMessage="Please enter user's password." checkPattern={true} />
          </PasswordContainer>
          <Row gutter={8} style={{ margin: '32px 0' }}>
            <Col>
              <Button htmlType="submit" type="primary" icon={<CheckOutlined />}>
                Reset my password
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default withAppContext(ResetPassword);
