import api from './apiHelper';
import { queryCache } from 'react-query';
import { useCustomQuery, useCustomPaginatedQuery } from 'hooks/reactQuery';
import env from '../config/env';

const getHostPath = params => `host${!!params ? `/${params}` : ''}`;

/* ---------------------------------- GET ---------------------------------------- */

export const useGetPaginatedHosts = (query, onError) => {
  const getPaginatedHosts = async (key, query) => {
    const path = getHostPath('paginated');
    return api.get(path, { params: query });
  };

  return useCustomPaginatedQuery('hosts', getPaginatedHosts, query, {
    onError
  });
};

export const useGetHostById = (hostId, onError) => {
  const getHostById = (key, hostId) => {
    const path = getHostPath(hostId);
    return api.get(path);
  };
  return useCustomQuery('getHostById', [hostId], getHostById, {
    onError,
    enabled: !!hostId
  });
};

export const useGetStaySuitesHosts = function (isGetHasBookingOwnedHost) {
  const HOSTPLATFORM_API_PREFIX = env.HOSTPLATFORM_API_PREFIX;
  const getHasBookingOwnedHosts = function (key, isGetHasBookingOwnedHost) {
    const path = getHostPath(HOSTPLATFORM_API_PREFIX);
    return api.get(path, { params: { isGetHasBookingOwnedHost } });
  };
  return useCustomQuery('getHasBookingOwnedHosts', [isGetHasBookingOwnedHost], getHasBookingOwnedHosts);
};

/* ------------------------------- POST CREATE -------------------------------------- */

export const postCreateHost = payload => {
  const path = getHostPath();
  return api.post(path, payload).then(newHost => {
    refetchHosts();
    return newHost;
  });
};

/* ---------------------------------- PATCH UPDATE ----------------------------------- */

export const patchUpdateHost = (hostId, payload) => {
  const path = getHostPath(hostId);
  return api.patch(path, payload).then(updatedHost => {
    refetchHosts();
    refetchHostDetails(hostId);
    return updatedHost;
  });
};

/* ---------------------------------- DELETE ----------------------------------- */

export const deleteHost = hostId => {
  const path = getHostPath(hostId);
  return api.delete(path).then(deletedHost => {
    refetchHosts();
    return deletedHost;
  });
};

/* ---------------------------------- REFETCH FUNCTION ---------------------------------------- */

const refetchPaginatedHosts = () => queryCache.invalidateQueries(['hosts']);
const refetchStaySuitesHosts = () => queryCache.invalidateQueries(['getStaySuitesOwnedHosts']);
const refetchHosts = () => {
  refetchPaginatedHosts();
  refetchStaySuitesHosts();
};
const refetchHostDetails = hostId => queryCache.invalidateQueries(['getHostById', hostId]);
