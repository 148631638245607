import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Row } from 'antd';

import FormSelection from 'components/FormSelection/FormSelection';
import FormInput from 'components/FormInput/FormInput';

import { Divider } from './RoomModal.styles';

const ModalFooter = ({ isAddRoom, onClose }) => {
  return (
    <Row justify="end">
      <Button onClick={onClose} style={{ marginRight: 5 }}>
        Back
      </Button>
      <Button type="primary" htmlType="submit">
        {isAddRoom ? 'Add Room' : 'Edit Room'}
      </Button>
    </Row>
  );
};

const constructRoomTypeSelections = (roomTypes = [], room, selectedRooms) => {
  let roomTypeSelections = [];
  if (Array.isArray(roomTypes)) {
    if (room._id) {
      roomTypeSelections = [roomTypes.find(roomType => room._id === roomType._id)];
    } else {
      roomTypeSelections = roomTypes.filter(roomType => !selectedRooms.includes(roomType._id) && roomType.availableInventory);
    }
  }

  return roomTypeSelections.map(roomType => ({ label: roomType.name, value: roomType._id }));
};
const constructAvailableInventorySelections = availableInventory =>
  new Array(availableInventory).fill(undefined).map((inv, idx) => ({ value: idx + 1, label: idx + 1 }));

const RoomModal = ({ data, onRoomUpdated, onClose }) => {
  const { room = {}, selectedRooms, roomTypes } = data;

  const [selectedRoom, setSelectedRoom] = useState({});
  const [inventorySelection, setInventorySelection] = useState([]);

  const isEditMode = !!room._id;
  const roomTypeSelections = isEditMode ? [] : constructRoomTypeSelections(roomTypes, room, selectedRooms);

  const handleOnSubmitForm = values => {
    const updatedRoomValues = {
      ...(Object.keys(selectedRoom).length
        ? {
            name: selectedRoom.name,
            priceDetails: { amount: selectedRoom.totalPrice },
            nightsCost: selectedRoom.nightsCost * values.roomCount
          }
        : room),
      ...values
    };
    onRoomUpdated(updatedRoomValues);
  };

  const handleOnRoomSelectionChange = useCallback(
    roomId => {
      if (Array.isArray(roomTypes)) {
        const selectedRoom = roomTypes && roomTypes.find(roomType => roomType._id === roomId);

        let totalAvailableInventory = selectedRoom.availableInventory;

        const inventorySelection = constructAvailableInventorySelections(totalAvailableInventory);
        setSelectedRoom(selectedRoom);
        setInventorySelection(inventorySelection);
      }
    },
    [roomTypes]
  );

  useEffect(() => {
    if (room && room._id) {
      handleOnRoomSelectionChange(room._id);
    }
  }, [room, handleOnRoomSelectionChange]);

  return (
    <Modal destroyOnClose visible={true} title="Edit Room" footer={null} onCancel={onClose}>
      <Form onFinish={handleOnSubmitForm} initialValues={room}>
        {isEditMode ? (
          <>
            <FormInput label="Room Type" name="name" disabled />
            <FormInput label="Number of rooms" name="roomCount" disabled />
          </>
        ) : (
          <>
            <FormSelection
              label="Room Type"
              name="_id"
              selections={roomTypeSelections}
              onChange={handleOnRoomSelectionChange}
              disabled={isEditMode}
              requiredErrorMessage="Please select a room to book."
              placeholder="Select a Room Type"
            />
            <FormSelection
              name="roomCount"
              label="Number of rooms"
              selections={inventorySelection}
              requiredErrorMessage="Please select the number of rooms to book."
              placeholder="Select number of rooms"
              disabled={isEditMode || !selectedRoom._id}
            />
          </>
        )}
        <FormInput name="remarks" label="Special Request" type="textArea" placeholder="(Optional)" />
        <Divider />
        <ModalFooter onClose={onClose} isAddRoom={!room._id} />
      </Form>
    </Modal>
  );
};

export default RoomModal;

RoomModal.propTypes = {
  data: PropTypes.object,
  onRoomUpdated: PropTypes.func,
  onClose: PropTypes.func
};

RoomModal.defaultProps = {
  data: { room: {} },
  onRoomUpdated: () => {},
  onClose: () => {}
};
