import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import FormItem from 'components/FormItem/FormItem';
import UploadPhoto from 'components/UploadPhoto/UploadPhoto';
// import Axios from 'axios';

const Photo = ({ value: hostLogo, form, name, disabled, folderName }) => {
  const handleOnUploadFinish = imageUrl => {
    const uploadedPhoto = {
      imageUrl
    };

    form.setFieldsValue({
      [name]: [uploadedPhoto]
    });
  };

  const handleOnImageDelete = index => e => {
    form.setFieldsValue({
      [name]: []
    });
  };

  return (
    <Row gutter={[24]}>
      <Col span={24}>
        <UploadPhoto
          photos={hostLogo}
          onUploadFinish={handleOnUploadFinish}
          onImageDelete={handleOnImageDelete}
          hideMeta={true}
          disabled={disabled}
          folderName={folderName}
        />
      </Col>
    </Row>
  );
};

const FormSinglePhoto = ({ name, label, acceptTypes, defaultValue, onChange, isDisabled, form, disabled, requiredErrorMessage, ...props }) => {
  return (
    <FormItem name={name} label={label} defaultValue={defaultValue} requiredErrorMessage={requiredErrorMessage}>
      <Photo name={name} form={form} disabled={disabled} folderName={props.folderName} />
    </FormItem>
  );
};

FormSinglePhoto.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  label: PropTypes.string,
  acceptTypes: PropTypes.array,
  defaultValue: PropTypes.object,
  onChange: PropTypes.func
};

export default FormSinglePhoto;
