import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { message } from 'antd';
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming';
import { useHistory } from 'react-router-dom';

import { updateApiHeaders } from 'apis/apiHelper';
import { postLogin, postVerify } from 'apis/auth';
import { useFetchConstant } from 'hooks/constants';
import { getUser, getAuthToken, getIsUserLoggedIn, setUserAuthObj, deleteUserAuthObj } from 'utils/auth'; // Do not remove these, will be useful in the next implementation
import { getLoginRoute } from 'utils/routes';

const AppContext = React.createContext();

const useTheme = () => {
  const originalTheme = useMemo(
    () => ({
      color: {
        primary: '#098ca3',
        secondary: 'white',
        lightSecondary: '#FFFCE6',
        green: '#52C41A',
        red: '#F5222D',
        white: '#FFFFFF',
        borderColor: '#E9E9E9',
        headingColor: '#000',
        headingWithBackground: 'white'
      },
      fontWeight: {
        light: 200,
        regular: 400,
        bold: 600,
        bolder: 700
      },
      spacing: {
        xxs: '4px',
        xs: '8px',
        regular: '16px',
        md: '32px',
        lg: '40px',
        xl: '48px',
        xxl: '60px',
        xxxl: '104px'
      }
    }),
    []
  );
  const [theme] = useState(originalTheme);

  return theme;
};

export const AppContextProvider = ({ children }) => {
  const history = useHistory();
  const theme = useTheme();

  const [user, setUser] = useState({});
  const [token, setToken] = useState('');
  const [isLoadingUser, setIsLoadingUser] = useState(true);

  const { selection: roles, isLoading: isRolesLoading } = useFetchConstant('roles', { extraCustomKey: 'appContext' });

  const mountUserData = useCallback(async () => {
    if (getIsUserLoggedIn()) {
      try {
        await postVerify();
      } catch (ex) {
        onLogout();
        message.error('Your session has expired. Please log in again.');
        history.push(getLoginRoute().path);
        return;
      }

      const storedToken = getAuthToken();
      const storedUser = getUser();
      setUser(storedUser);
      setToken(storedToken);
    }
    setIsLoadingUser(false);
  }, [history]);

  useEffect(() => {
    mountUserData();
  }, [mountUserData]);

  const isUserLoading = isLoadingUser || isRolesLoading;

  const isLoggedIn = !!user && !!user.username;

  const checkCanUserAccess = roleToAccess => {
    if (!roleToAccess || user.role === roleToAccess) {
      return true;
    } else {
      const foundUserRole = roles.find(role => {
        return role.code === user.role;
      });
      return !!foundUserRole && foundUserRole.access.includes(roleToAccess);
    }
  };

  const onLogin = (username, password) => {
    return postLogin(username, password)
      .then(userData => {
        setUserAuthObj(userData);
        setUser(userData.user);
        setToken(userData.token);
        updateApiHeaders();
        return userData;
      })
      .finally(() => setIsLoadingUser(false));
  };

  const onLogout = () => {
    deleteUserAuthObj();
    setUser({});
    setToken('');
    updateApiHeaders();
  };

  return (
    <AppContext.Provider value={{ user, token, isLoggedIn, isUserLoading, checkCanUserAccess, onLogin, onLogout }}>
      <EmotionThemeProvider theme={theme}>{children}</EmotionThemeProvider>
    </AppContext.Provider>
  );
};

export const AppContextConsumer = AppContext.Consumer;

export const withAppContext = Component => {
  const AppContextComponent = props => <AppContextConsumer>{appContextProps => <Component {...appContextProps} {...props} />}</AppContextConsumer>;
  return AppContextComponent;
};
