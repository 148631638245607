import React, { useCallback, useMemo, useState, useEffect } from 'react';
import moment from 'moment';
import { useParams, useHistory } from 'react-router-dom';
import { Row, Col, Skeleton, Tabs, Tooltip, Form, Button, Modal, message } from 'antd';
import { CheckOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';

import ClickableTextButton from 'components/ClickableTextButton/ClickableTextButton';
import FormDatePicker from 'components/FormDatePicker/FormDatePicker';
import FormInput from 'components/FormInput/FormInput';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import FormSelection from 'components/FormSelection/FormSelection';
import FormGroupSelectionInput from 'components/FormGroupSelectionInput/FormGroupSelectionInput';
import SectionCard from 'components/SectionCard/SectionCard';
import Title from 'components/Title/Title';

import { useGetBookingById, useGetBookingSurcharge, patchUpdateBookingStatus, patchUpdateBooking, postCreateBooking } from 'apis/booking';
import { useGetMembersForSuggestions } from 'apis/member';
import { useGetPackageOrders } from 'apis/packageOrder';
import { useGetProperties } from 'apis/property';
import { useGetRoomTypesWithAvailableInventory } from 'apis/roomType';

import { useFetchConstant } from 'hooks/constants';

import { getLabelOfConstant, constructDisplayPrice, checkHasValue, guard } from 'utils/general';
import { getBookingsRoute, getBookingDetailRoute } from 'utils/routes';
import { DATE_FORMAT } from 'utils/constants';

import RoomModal from './components/RoomModal/RoomModal';
import FinancialInfoModal from './components/FinancialInfoModal/FinancialInfoModal';

import {
  Divider,
  ExtraInfoText,
  InfoText,
  StatusTag,
  StatusUpdateButton,
  TitleContainer,
  RoomsContainer,
  AddRoomButton
} from './BookingDetails.styles';

const { TabPane } = Tabs;
const { useForm } = Form;
const { confirm, error } = Modal;

const checkIsBookingPending = (bookingStatusesConst, currentBookingStatus) => {
  return [bookingStatusesConst.PENDING.code, bookingStatusesConst.STRIPE_UNPAID.code].includes(currentBookingStatus);
};

const ContentDisplay = ({ label, details, isBold }) => {
  return (
    <Row style={{ marginBottom: 20 }} gutter={8}>
      <Col span={10} style={{ alignItems: 'center' }}>
        <InfoText>{label}</InfoText>
      </Col>
      <Col span={14} style={{ alignItems: 'center' }}>
        <InfoText fontWeight={isBold && 'bold'} fontSize={isBold && 16}>
          {details}
        </InfoText>
      </Col>
    </Row>
  );
};

const PriceDisplay = ({ label, price, remarks, remarksLabel, totalRoomRate, isBold }) => {
  return (
    <Row style={{ marginBottom: 10 }} gutter={8}>
      <Col span={15} style={{ alignItems: 'center' }}>
        <InfoText>{label}</InfoText>
      </Col>
      <Col span={9} style={{ alignItems: 'center' }}>
        <Row>
          <InfoText fontWeight={isBold && 'bold'} fontSize={isBold && 16}>
            {price ? constructDisplayPrice(price) : constructDisplayPrice(0)}
          </InfoText>
          &nbsp;
          {!!totalRoomRate && (
            <ExtraInfoText strikethrough fontSize={12}>
              ({constructDisplayPrice(totalRoomRate)})
            </ExtraInfoText>
          )}
        </Row>
      </Col>
      <Col span={24}>{remarks && <ExtraInfoText>{`${remarksLabel}: ${remarks}`}</ExtraInfoText>}</Col>
    </Row>
  );
};

const BookingStatusTag = ({ status, bookingStatuses, bookingStatusesConst, isExpired }) => {
  const getTagColor = () => {
    switch (status) {
      case bookingStatusesConst.CONFIRM.code:
        return 'success';
      case bookingStatusesConst.PENDING.code:
      case bookingStatusesConst.STRIPE_UNPAID.code:
        return 'processing';
      case bookingStatusesConst.CANCEL.code:
        return 'error';
      default:
        return 'default';
    }
  };
  return isExpired && checkIsBookingPending(bookingStatusesConst, status) ? (
    <StatusTag color="default">Expired</StatusTag>
  ) : (
    <StatusTag color={getTagColor()}>{getLabelOfConstant(status, bookingStatuses)}</StatusTag>
  );
};

const UpdateBookingStatusButtons = ({
  bookingId,
  bookingStatus,
  bookingStatusesConst,
  refetchBooking,
  setFieldsValue,
  isPastBooking,
  onAcceptFinish
}) => {
  const isPending = checkIsBookingPending(bookingStatusesConst, bookingStatus);
  const isCancelled = bookingStatus === bookingStatusesConst.CANCEL.code;

  const handleOnAcceptBookingButtonClick = () => {
    confirm({
      title: 'Are you sure to accept this booking?',
      okText: 'Yes, I want to accept this booking.',
      onOk() {
        patchUpdateBookingStatus(bookingId, 'accepted').then(() => {
          refetchBooking(bookingId);
          setFieldsValue({ status: bookingStatus });
          onAcceptFinish();
          message.success('The booking has been successfully accepted.');
        });
      }
    });
  };

  const handleOnRejectBookingButtonClick = () => {
    confirm({
      title: 'Are you sure to reject this booking?',
      okText: 'Yes, I want to reject this booking.',
      onOk() {
        patchUpdateBookingStatus(bookingId, 'rejected').then(() => {
          refetchBooking(bookingId);
          setFieldsValue({ status: bookingStatus });
          message.success('The booking has been successfully rejected.');
        });
      }
    });
  };

  return bookingId ? (
    <Row>
      <StatusUpdateButton
        type="primary"
        actionType="accept"
        onClick={handleOnAcceptBookingButtonClick}
        style={{ marginRight: 5 }}
        disabled={!isPending || isPastBooking}
      >
        Accept Booking
      </StatusUpdateButton>
      <StatusUpdateButton type="primary" actionType="reject" onClick={handleOnRejectBookingButtonClick} disabled={isCancelled || isPastBooking}>
        Reject Booking
      </StatusUpdateButton>
    </Row>
  ) : null;
};

const calculateBookedNights = (startDate, endDate) => {
  return moment(endDate).diff(moment(startDate), 'days');
};

const calculateTotalPrice = (rooms = []) => {
  return rooms.reduce((totalPayment, room) => totalPayment + room.priceDetails.amount * room.roomCount, 0);
};

const calculateTotalDiscount = (rooms = []) => {
  return rooms.reduce((totalPayment, room) => totalPayment + guard(() => room.priceDetails.discountAmount) * room.roomCount, 0);
};

const calculateTotalPriceBeforeDiscount = (rooms = []) => {
  return rooms.reduce((total, room) => total + (room.priceDetails.amount + guard(() => room.priceDetails.discountAmount, 0)) * room.roomCount, 0);
};

const formatFormInitialValues = (defaultValues = {}) => {
  const hasPromotionCode = guard(() => defaultValues.paymentDetails.promotionCode);

  return {
    ...defaultValues,
    startDate: defaultValues.startDate ? moment(defaultValues.startDate, DATE_FORMAT) : moment(),
    endDate: defaultValues.endDate ? moment(defaultValues.endDate, DATE_FORMAT) : moment().add(1, 'day'),
    guestDetails: defaultValues.guestDetails || {
      numberOfPax: 1
    },
    rooms: defaultValues.rooms || [],
    ...(hasPromotionCode && {
      promotionCode: defaultValues.paymentDetails.promotionCode
    })
  };
};

const getTotalRoomNightsCostAndRoomNightsAvailable = (packageCode, packageOrders, rooms, nightsRedeemed) => {
  const selectedPackage = packageOrders.find(packageOrder => packageOrder.packageCode === packageCode);
  if (selectedPackage) {
    const nightsAvailable = selectedPackage.nightsLeft + nightsRedeemed;
    const totalNightsCost = rooms.reduce((totalNightsCost, room) => totalNightsCost + room.nightsCost, 0);
    return { nightsCost: totalNightsCost, nightsAvailable };
  }
};

const checkAndFormatSavePayload = (payload, rooms, selectedProperty, roomTypes, packageOrders, booking) => {
  if (!rooms || !rooms.length) {
    message.error('Please add at least (1) room to your booking.');
  } else {
    if (payload.packageCode) {
      // nightsRedeemed will only be more than 0 if this booking is in Edit Mode
      const nightsRedeemed = guard(() => !!booking._id && booking.paymentDetails.nightRedeemed, 0);
      const { nightsCost, nightsAvailable } = getTotalRoomNightsCostAndRoomNightsAvailable(payload.packageCode, packageOrders, rooms, nightsRedeemed);
      if (nightsCost > nightsAvailable) {
        return error({
          title: 'Not enough room nights to redeem this booking. Please choose another package code with sufficient room nights.',
          content: (
            <>
              <Row>Room Nights required: {nightsCost}</Row>
              <Row>Room Nights available: {nightsAvailable}</Row>
            </>
          )
        });
      }
    }
    const startDate = payload.startDate.format(DATE_FORMAT);
    const endDate = payload.endDate.format(DATE_FORMAT);
    const bookedNights = calculateBookedNights(startDate, endDate);
    return {
      startDate,
      endDate,
      status: payload.status,
      guestDetails: payload.guestDetails,
      rooms: formatRooms(rooms, roomTypes, bookedNights),
      propertyId: selectedProperty,
      packageCode: payload.packageCode,
      memberId: payload.memberId
    };
  }
};

const formatRooms = (rooms = [], roomTypes = [], bookedNights) => {
  return rooms.map(room => {
    const matchingRoomType = roomTypes.find(roomType => roomType._id === room._id);
    const nightsCost = matchingRoomType && matchingRoomType.nightsCost * room.roomCount * bookedNights;
    return {
      ...room,
      nightsCost
    };
  });
};

// Uses string instead of DOM for filter purpose
const getPropertyStatTag = ({ isPremium, isHoliStayPromo } = {}) => {
  const tags = [];
  isPremium && tags.push('Premium');
  isHoliStayPromo && tags.push('HoliStay');

  return tags.length > 0 ? ` [${tags.join(', ')}]` : '';
};

const useFetchConstants = () => {
  const { selection: bookingStatuses, data: bookingStatusesConst, isLoading: isBookingStatusesLoading } = useFetchConstant('bookingStatuses');
  const { selection: paymentTypes, isLoading: isPackageTypesLoading } = useFetchConstant('paymentTypes');
  const { selection: paymentGateways, isLoading: isPaymentGatewaysLoading } = useFetchConstant('paymentGateways');
  const { selection: identificationTypes, data: identificationTypesConst, isLoading: isIndetificationTypesLoading } = useFetchConstant(
    'identificafionTypes'
  );
  const { selection: countries, data: countriesConst, isLoading: isCountriesLoading } = useFetchConstant('countries', {
    query: { isFetchingAllCountries: true },
    valueKey: 'iso2',
    labelKey: 'name'
  });
  const { selection: countryCodes, data: countryCodesConst, isLoading: isCountryCodesLoading } = useFetchConstant('countries', {
    query: { isFetchingAllCountries: true },
    valueKey: 'phoneCode',
    labelKey: 'phoneCode'
  });

  const isLoading =
    isBookingStatusesLoading ||
    isPaymentGatewaysLoading ||
    isPackageTypesLoading ||
    isIndetificationTypesLoading ||
    isCountriesLoading ||
    isCountryCodesLoading;
  const selections = {
    bookingStatuses,
    paymentTypes,
    paymentGateways,
    identificationTypes,
    countries,
    countryCodes
  };
  const datas = {
    bookingStatusesConst,
    identificationTypesConst,
    countriesConst,
    countryCodesConst
  };

  return { isLoading, selections, datas };
};

const useCalculateSurcharge = (form, rooms = []) => {
  const startDate = moment.isMoment(form.getFieldValue('startDate')) && form.getFieldValue('startDate').format(DATE_FORMAT);
  const endDate = moment.isMoment(form.getFieldValue('endDate')) && form.getFieldValue('endDate').format(DATE_FORMAT);
  const packageCode = form.getFieldValue('packageCode');
  const noOfRooms = rooms.reduce((noOfRooms, room) => noOfRooms + room.roomCount, 0);

  return useGetBookingSurcharge({ startDate, endDate, noOfRooms, packageCode }, !!startDate && !!endDate && !!noOfRooms && !!packageCode);
};

const BookingDetails = () => {
  const { id: bookingId } = useParams();
  const history = useHistory();
  const [form] = useForm();

  const [selectedIdentificationType, setSelectedIdentificationType] = useState('');
  const [editingRoomIdx, setEditingRoomIdx] = useState(0);
  const [selectedProperty, setSelectedProperty] = useState('');
  const [roomModalVisible, setRoomModalVisible] = useState(false);
  const [isUpdateFinInfoButtonClicked, setIsUpdateFinInfoButtonClicked] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [roomTypeQuery, setRoomTypeQuery] = useState({});
  const [memberKeyword, setMemberKeyword] = useState();
  const [selectedMember, setSelectedMember] = useState();
  const [recalculatePrice, setRecalculatePrice] = useState(!bookingId);
  const [isHoliStayBooking, setIsHoliStayBooking] = useState(false);
  const [hasPackageCode, setHasPackageCode] = useState(false);
  const [hasPromotionCode, setHasPromotionCode] = useState(false);

  const [isPostProcessingRooms, setIsPostProcessingRooms] = useState(false); // a quick hacky fix to ensure rooms is processed before render

  const { isLoading: isBookingLoading, data: booking, refetch: refetchBooking } = useGetBookingById(bookingId);
  const { isLoading: isPropertiesLoading, data: properties } = useGetProperties();
  const { isLoading: isRoomTypesLoading, data: roomTypes } = useGetRoomTypesWithAvailableInventory(selectedProperty, roomTypeQuery, recalculatePrice);
  const { isLoading: isMembersLoading, data: members } = useGetMembersForSuggestions(memberKeyword);
  const { isLoading: isPackageOrdersLoading, data: packageOrders } = useGetPackageOrders({ memberId: selectedMember }, bookingId);
  const { data: surcharge } = useCalculateSurcharge(form, rooms);

  const {
    isLoading: isConstantsLoading,
    selections: { bookingStatuses, paymentTypes, paymentGateways, identificationTypes, countries, countryCodes },
    datas: { bookingStatusesConst, identificationTypesConst, countriesConst, countryCodesConst }
  } = useFetchConstants();

  const isLoading = isBookingLoading || isPropertiesLoading || isConstantsLoading || isPostProcessingRooms;
  const isEditMode = !!bookingId;
  const isBookingCancelled = !isLoading && booking.status === bookingStatusesConst.CANCEL.code;
  const isPastBooking = !isLoading && moment(booking.startDate).isBefore(moment(), 'day');

  const bookedNights = calculateBookedNights(form.getFieldValue('startDate'), form.getFieldValue('endDate'));
  const propertiesSelection = useMemo(
    () =>
      isPropertiesLoading
        ? []
        : properties.map(property => ({
            value: property._id,
            label: `${property.name}${getPropertyStatTag(property)}`
          })),
    [isPropertiesLoading, properties]
  );
  const membersSelection = useMemo(
    () =>
      isMembersLoading
        ? []
        : members.map(member => ({
            value: member._id,
            label: `${member.name} (${member.email}${member.contact ? ` | ${member.contact.countryCode}${member.contact.contactNumber}` : ''})`
          })),
    [isMembersLoading, members]
  );
  const packageCodesSelection = useMemo(
    () =>
      isPackageOrdersLoading
        ? []
        : packageOrders
            .sort((pkgA, pkgB) => pkgB.nightsLeft - pkgA.nightsLeft)
            .map(packageOrder => ({
              value: packageOrder.packageCode,
              label: `${packageOrder.packageCode} - ${packageOrder.packageName} (Room Nights left: ${packageOrder.nightsLeft})`,
              disabled: !packageOrder.nightsLeft
            })),
    [isPackageOrdersLoading, packageOrders]
  );
  const paymentGatewayLabel = guard(() => getLabelOfConstant(booking.paymentDetails.paymentGateway, paymentGateways));

  const updateRoomTypeQuery = useCallback(
    query => {
      if (query) {
        const newQuery = { ...roomTypeQuery, ...query };
        setRoomTypeQuery(newQuery);
      } else {
        const defaultValue = formatFormInitialValues(booking);
        setRoomTypeQuery({
          startDate: defaultValue.startDate.format(DATE_FORMAT),
          endDate: defaultValue.endDate.format(DATE_FORMAT),
          bookingCodeToExclude: defaultValue.code
        });
        form.setFieldsValue({
          startDate: defaultValue.startDate,
          endDate: defaultValue.endDate
        });
      }
    },
    [booking, roomTypeQuery, setRoomTypeQuery, form]
  );

  useEffect(() => {
    if (booking && booking.rooms) {
      setRooms(booking.rooms);
    }
    if (booking && booking.guestDetails && booking.guestDetails.identification) {
      setSelectedIdentificationType(booking.guestDetails.identification.type);
    }
    if (booking && booking.property) {
      setSelectedProperty(booking.property._id);
    }
    if (booking && booking.memberId) {
      setSelectedMember(booking.memberId);
    }
    if (booking && booking.packageCode) {
      setHasPackageCode(!!booking.packageCode);
    }
    if (booking && booking.paymentDetails && booking.paymentDetails.promotionCode) {
      setHasPromotionCode(!!booking.paymentDetails.promotionCode);
    }
    if (booking && booking.isHoliStayPromo) {
      setIsHoliStayBooking(booking.isHoliStayPromo);
    }

    const defaultValue = formatFormInitialValues(booking);
    setRoomTypeQuery({
      startDate: defaultValue.startDate.format(DATE_FORMAT),
      endDate: defaultValue.endDate.format(DATE_FORMAT),
      bookingCodeToExclude: defaultValue.code
    });
  }, [booking]);

  useEffect(() => {
    if (!isEditMode) {
      if (identificationTypesConst) {
        form.setFieldsValue({
          guestDetails: {
            identification: { type: identificationTypesConst.NRIC.code }
          }
        });
        setSelectedIdentificationType(identificationTypesConst.NRIC.code);
      }
      if (countriesConst) {
        form.setFieldsValue({
          guestDetails: {
            identification: { nationality: countriesConst.MALAYSIA.iso2 }
          }
        });
      }
      if (countryCodesConst) {
        form.setFieldsValue({
          guestDetails: {
            contact: { countryCode: countryCodesConst.MALAYSIA.phoneCode }
          }
        });
      }
      if (bookingStatusesConst) {
        form.setFieldsValue({ status: bookingStatusesConst.CONFIRM.code });
      }
    }
  }, [identificationTypesConst, countriesConst, countryCodesConst, bookingStatusesConst, form, isEditMode]);

  useEffect(() => {
    if (isEditMode && guard(() => rooms.length > 0, false) && guard(() => roomTypes.length > 0, false) && recalculatePrice) {
      setIsPostProcessingRooms(true);

      for (let i = 0; i < rooms.length; i++) {
        const room = rooms[i];
        const matchingRoomType = roomTypes.find(roomType => roomType._id === room._id);
        if (!matchingRoomType || matchingRoomType.availableInventory < room.roomCount) {
          const { startDate, endDate } = formatFormInitialValues(booking);

          const initialStartDate = startDate.format(DATE_FORMAT);
          const initialEndDate = endDate.format(DATE_FORMAT);

          const newStartDate = form.getFieldValue('startDate').format(DATE_FORMAT);
          const newEndDate = form.getFieldValue('endDate').format(DATE_FORMAT);

          error({
            title: `The booked rooms is not available on date ${newStartDate} to ${newEndDate}! `,
            content: `Date changes will be reverted back to (${initialStartDate} to ${initialEndDate}).`,
            onOk() {
              updateRoomTypeQuery();
            }
          });
        }

        if (matchingRoomType) {
          const newRoomValues = {
            ...room,
            name: matchingRoomType.name,
            priceDetails: { amount: matchingRoomType.totalPrice },
            nightsCost: matchingRoomType.nightsCost * room.roomCount * bookedNights
          };
          rooms[i] = { ...rooms[i], ...newRoomValues };
        }
      }
      setIsPostProcessingRooms(false);
    }
  }, [booking, bookedNights, rooms, roomTypes, isEditMode, form, roomTypeQuery, recalculatePrice, updateRoomTypeQuery]);

  const getIDLabel = useCallback(() => {
    const selectedIdType = identificationTypes.find(type => type.value === selectedIdentificationType);
    const selectedIdTypeLabel = (selectedIdType && selectedIdType.label) || identificationTypesConst.NRIC.label;

    return selectedIdTypeLabel + ' Number';
  }, [selectedIdentificationType, identificationTypes, identificationTypesConst]);

  const getPropertyName = useCallback(
    propertyId => {
      const foundProperty = properties.find(property => property._id === propertyId);
      if (foundProperty) {
        return foundProperty.name;
      }
      return '-';
    },
    [properties]
  );

  const getTotalOfFinancialAmount = rooms => {
    return rooms.reduce(
      (totalAmt, currentRecord) =>
        totalAmt +
        (currentRecord.priceDetails ? currentRecord.roomCount * currentRecord.priceDetails.hostNettAmountPerRoom : 0) +
        (currentRecord.priceDetails ? currentRecord.roomCount * currentRecord.priceDetails.platformFeePerRoom : 0),
      0
    );
  };

  const formatDataForModal = () => {
    return {
      room: rooms[editingRoomIdx],
      roomTypes,
      selectedRooms: rooms.map(room => room._id)
    };
  };

  const clearRooms = (fieldKey, value) => {
    confirm({
      title: 'Are you sure you want to make this change?',
      content: 'The list of rooms will be cleared off as not all rooms are available to your current new changes.',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        setRooms([]);
        setEditingRoomIdx(undefined);
        if (fieldKey === 'propertyId' && value) {
          setSelectedProperty(value);
        }
      },
      onCancel() {
        if (fieldKey === 'propertyId') {
          form.setFieldsValue({ property: { _id: selectedProperty } });
        } else if (fieldKey === 'startDate' || fieldKey === 'endDate') {
          form.setFieldsValue({
            startDate: moment(booking.startDate),
            endDate: moment(booking.endDate)
          });
          updateRoomTypeQuery();
        }
      }
    });
  };

  const handleOnStartDateChange = (momentStartDate, stringStartDate) => {
    const newRoomTypeQuery = { startDate: stringStartDate };
    setRecalculatePrice(true);
    if (!isEditMode && rooms.length) {
      clearRooms('startDate');
    }
    if (form.getFieldValue('endDate').isSameOrBefore(momentStartDate)) {
      const endDate = moment(momentStartDate).add(1, 'day');
      form.setFieldsValue({ endDate });
      newRoomTypeQuery.endDate = endDate.format(DATE_FORMAT);
    }
    updateRoomTypeQuery(newRoomTypeQuery);
  };

  const handleOnEndDateChange = (momentEndDate, stringEndDate) => {
    const newRoomTypeQuery = { endDate: stringEndDate };
    setRecalculatePrice(true);
    if (!isEditMode && rooms.length) {
      clearRooms('endDate');
    }
    if (form.getFieldValue('startDate').isSameOrAfter(momentEndDate)) {
      const startDate = moment(momentEndDate).subtract(1, 'day');
      form.setFieldsValue({ startDate });
      newRoomTypeQuery.startDate = startDate.format(DATE_FORMAT);
    }
    updateRoomTypeQuery(newRoomTypeQuery);
  };

  const handleOnPropertyChange = propertyId => {
    if (!isEditMode && rooms.length) {
      clearRooms('propertyId', propertyId);
    } else {
      setSelectedProperty(propertyId);
    }
  };

  const handleOnAddRoomButtonClick = () => {
    setEditingRoomIdx(undefined);
    setRoomModalVisible(true);
  };

  const handleOnEditButtonClick = roomIdx => () => {
    setEditingRoomIdx(roomIdx);
    setRoomModalVisible(true);
  };

  const handleOnDeleteButtonClick = roomId => () => {
    confirm({
      title: 'Are you sure you want to delete this room from booking?',
      content: 'You will not be able to undo this action.',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        const updatedRooms = rooms.filter(room => room._id !== roomId);
        setRooms(updatedRooms);
      },
      onCancel() {}
    });
  };

  const handleOnMemberChange = memberId => {
    const selectedMember = members.find(member => member._id === memberId);
    if (selectedMember) {
      form.setFieldsValue({
        guestDetails: selectedMember,
        packageCode: undefined
      });
    }
    setSelectedMember(memberId);
    setHasPackageCode(false);
  };

  const handleOnRoomUpdated = value => {
    if (checkHasValue(editingRoomIdx)) {
      rooms[editingRoomIdx] = { ...rooms[editingRoomIdx], ...value };
    } else {
      rooms.push({ ...value });
    }
    setRoomModalVisible(false);
  };

  const handleOnModalClose = () => setRoomModalVisible(false);

  const handleOnFinishFailed = () => {
    message.error('Please check for missing required field to fill in.');
  };

  const handleOnFinish = values => {
    const payload = checkAndFormatSavePayload(values, rooms, selectedProperty, roomTypes, packageOrders, booking);
    if (payload && payload.startDate) {
      try {
        if (isEditMode) {
          confirm({
            title: 'Are you sure you want to overwrite existing data?',
            content: 'You will not be able to undo this action, but you may update it again.',
            icon: <ExclamationCircleOutlined />,
            onOk() {
              patchUpdateBooking(bookingId, payload)
                .then(updatedBooking => {
                  message.success('Update success!');
                  refetchBooking(updatedBooking._id);
                })
                .catch(ex => {
                  error({
                    title: 'The booking could not be updated.',
                    content: ex.message
                  });
                });
            },
            onCancel() {}
          });
        } else {
          postCreateBooking(payload)
            .then(newBooking => {
              Modal.success({
                title: 'Booking has been created successfully!',
                okText: 'View created booking',
                onOk() {
                  history.push(getBookingDetailRoute(newBooking._id).path);
                }
              });
            })
            .catch(ex => {
              error({
                title: 'The booking could not be created.',
                content: ex.message
              });
            });
        }
      } catch (error) {
        error.errorFields.forEach(field => message.error(field.errors[0]));
      }
    }
  };

  return (
    <div>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          {roomModalVisible && <RoomModal data={formatDataForModal()} onClose={handleOnModalClose} onRoomUpdated={handleOnRoomUpdated} />}
          <ClickableTextButton text="Back to Bookings" url={getBookingsRoute().path} />
          <TitleContainer type="flex" align="middle">
            <Title>Booking {booking.code}</Title>
            {isEditMode && (
              <BookingStatusTag
                status={booking.status}
                bookingStatuses={bookingStatuses}
                bookingStatusesConst={bookingStatusesConst}
                isExpired={isPastBooking}
              />
            )}
          </TitleContainer>
          <Row gutter={[24, 16]}>
            <Col span={24} lg={16}>
              <Form form={form} initialValues={formatFormInitialValues(booking)} onFinish={handleOnFinish} onFinishFailed={handleOnFinishFailed}>
                <Tabs
                  tabBarExtraContent={
                    <UpdateBookingStatusButtons
                      bookingId={bookingId}
                      bookingStatus={booking.status}
                      bookingStatusesConst={bookingStatusesConst}
                      refetchBooking={refetchBooking}
                      setFieldsValue={form.setFieldsValue}
                      isPastBooking={isPastBooking}
                      onAcceptFinish={() => setIsUpdateFinInfoButtonClicked(true)}
                    />
                  }
                >
                  <TabPane tab="Booking Details" key="booking">
                    <Row gutter={[24, 16]}>
                      <Col span={24} md={12}>
                        <FormDatePicker
                          label="Check-in"
                          name="startDate"
                          isReservation
                          extraProps={{
                            onChange: handleOnStartDateChange,
                            allowClear: false
                          }}
                          disabled={isPastBooking || isBookingCancelled}
                        />
                      </Col>
                      <Col span={24} md={12}>
                        <FormDatePicker
                          label="Check-out"
                          name="endDate"
                          isReservation
                          extraProps={{
                            onChange: handleOnEndDateChange,
                            allowClear: false
                          }}
                          disabled={isPastBooking || isBookingCancelled}
                        />
                      </Col>
                      <Col span={24}>
                        <FormSelection label="Booking Status" name="status" selections={bookingStatuses} disabled={isEditMode} />
                      </Col>
                    </Row>
                    <Row>
                      <SectionCard title="Guest Details">
                        <Row gutter={[24, 16]}>
                          <Col span={24}>
                            <FormSelection
                              label="Membership ID"
                              name="memberId"
                              selections={membersSelection}
                              onSearchByApi={setMemberKeyword}
                              isSearching={isMembersLoading}
                              onChange={handleOnMemberChange}
                              disabled={isEditMode}
                            />
                          </Col>
                          <Col span={24}>
                            <FormInput
                              label="Name"
                              name={['guestDetails', 'name']}
                              requiredErrorMessage="Please enter guest's first name."
                              disabled={isPastBooking || isBookingCancelled}
                            />
                          </Col>

                          <Col span={24} md={12}>
                            <FormInput
                              label="Email Address"
                              name={['guestDetails', 'email']}
                              requiredErrorMessage="Please enter guest's email address."
                              placeholder="example@mail.com"
                              type="email"
                              disabled={isPastBooking || isBookingCancelled}
                            />
                          </Col>
                          <Col span={24} md={12}>
                            <FormGroupSelectionInput
                              label="Contact Number"
                              inputName={['guestDetails', 'contact', 'contactNumber']}
                              selectionName={['guestDetails', 'contact', 'countryCode']}
                              inputType="number"
                              selections={countryCodes}
                              requiredErrorMessage="Please enter guest's contact number"
                              inputPlaceholder="12-3456789"
                              disabled={isPastBooking || isBookingCancelled}
                            />
                          </Col>
                          <Col span={24} md={12}>
                            <FormInputNumber
                              label="Total Number Of Guests"
                              name={['guestDetails', 'numberOfPax']}
                              requiredErrorMessage="Please enter total number of guests."
                              disabled={isPastBooking || isBookingCancelled}
                            />
                          </Col>
                          <Col span={24} md={12}>
                            <FormSelection
                              label="Nationality"
                              name={['guestDetails', 'identification', 'nationality']}
                              placeholder="Select Nationality"
                              requiredErrorMessage="Please select guest's nationality."
                              selections={countries}
                              disabled={isPastBooking || isBookingCancelled}
                            />
                          </Col>

                          <Col span={24} md={12}>
                            <FormSelection
                              label="ID Type"
                              name={['guestDetails', 'identification', 'type']}
                              placeholder="Select ID Type"
                              requiredErrorMessage="Please enter ID type."
                              selections={identificationTypes}
                              onChange={setSelectedIdentificationType}
                              disabled={isPastBooking || isBookingCancelled}
                            />
                          </Col>
                          <Col span={24} md={12}>
                            <FormInput
                              label={getIDLabel()}
                              name={['guestDetails', 'identification', 'id']}
                              requiredErrorMessage="Please enter guest's ID number."
                              disabled={isPastBooking || isBookingCancelled}
                              type={selectedIdentificationType === identificationTypesConst.NRIC.code ? 'nric' : 'default'}
                            />
                          </Col>
                          <Col span={24}>
                            {isEditMode ? (
                              <FormInput label="PackageCode" name="packageCode" disabled />
                            ) : (
                              <FormSelection
                                label="Package Code"
                                name="packageCode"
                                selections={packageCodesSelection}
                                disabled={!selectedMember || isEditMode}
                                onChange={value => {
                                  if (!!value) {
                                    const selectedPackageOrder = packageOrders.find(pkg => pkg.packageCode === value);
                                    setIsHoliStayBooking(selectedPackageOrder.isHoliStayPromo);
                                  } else {
                                    setIsHoliStayBooking(false);
                                  }
                                  setHasPackageCode(!!value);
                                }}
                              />
                            )}
                          </Col>
                        </Row>
                      </SectionCard>
                    </Row>
                  </TabPane>
                  <TabPane tab="Property & Rooms" key="property">
                    <Row>
                      <Col span={24}>
                        {isEditMode ? (
                          <FormInput label="Property" name={['property', 'name']} disabled />
                        ) : (
                          <FormSelection
                            label="Property"
                            name={['property', '_id']}
                            selections={propertiesSelection}
                            disabled={isEditMode}
                            onChange={handleOnPropertyChange}
                            placeholder="Select a Property"
                            requiredErrorMessage="Please select a property to book."
                          />
                        )}
                      </Col>
                    </Row>

                    <SectionCard title="Rooms">
                      {rooms.map((room, idx) => (
                        <RoomsContainer>
                          <Col span={19}>
                            <InfoText fontWeight="bold" fontSize={16} style={{ marginBottom: 10 }}>
                              {room.roomCount + ' x ' + room.name}
                            </InfoText>
                            <InfoText>{`${constructDisplayPrice(room.priceDetails.amount * room.roomCount)} for ${
                              room.roomCount
                            } rooms x ${bookedNights} nights `}</InfoText>
                          </Col>
                          <Col span={5}>
                            <Button
                              block
                              style={{ marginBottom: 5 }}
                              onClick={handleOnEditButtonClick(idx)}
                              disabled={isPastBooking || isBookingCancelled}
                            >
                              Edit
                            </Button>
                            <Button danger block onClick={handleOnDeleteButtonClick(room._id)} disabled={isEditMode}>
                              Delete
                            </Button>
                          </Col>
                        </RoomsContainer>
                      ))}
                      {!isEditMode && (
                        <Col span={24}>
                          <AddRoomButton
                            icon={<PlusOutlined />}
                            onClick={handleOnAddRoomButtonClick}
                            disabled={!selectedProperty}
                            loading={isRoomTypesLoading}
                          >
                            Add Room
                          </AddRoomButton>
                        </Col>
                      )}
                    </SectionCard>
                  </TabPane>
                </Tabs>
                <Row style={{ marginTop: 10 }}>
                  <Button type="primary" icon={<CheckOutlined />} style={{ marginRight: 5 }} htmlType="submit">
                    {isEditMode ? 'Save' : 'Create'}
                  </Button>
                </Row>
              </Form>
            </Col>
            <Col span={24} lg={16} xl={8}>
              <SectionCard title="Booking Summary" cardStyle={{ backgroundColor: 'white' }}>
                {isEditMode && (
                  <>
                    <ContentDisplay label="Guest Name:" details={booking.guestDetails.name} />
                    <ContentDisplay
                      label="Contact No:"
                      details={booking.guestDetails.contact.countryCode + booking.guestDetails.contact.contactNumber}
                    />
                  </>
                )}
                <ContentDisplay label="Property:" details={getPropertyName(selectedProperty)} />
                <ContentDisplay label="Check in:" details={moment(form.getFieldValue('startDate')).format('ddd, DD MMM YYYY')} isBold />
                <ContentDisplay label="Check out:" details={moment(form.getFieldValue('endDate')).format('ddd, DD MMM YYYY')} isBold />
                <ContentDisplay label="Length of stay:" details={bookedNights + ' night(s)'} />
                {isEditMode && (
                  <>
                    <Divider height="2px" />
                    <ContentDisplay label="Host Name:" details={booking.host.name} />
                    <ContentDisplay label="Host Contact No:" details={booking.host.contactNumber} />
                    <ContentDisplay label="Host Email:" details={booking.host.email} />
                  </>
                )}
                <Divider height="2px" />
                {rooms.map((room, idx) => (
                  <>
                    {idx > 0 && <Divider />}
                    <PriceDisplay
                      label={room.roomCount + ' x ' + room.name}
                      price={room.priceDetails.amount * room.roomCount}
                      remarks={room.remarks}
                      remarksLabel={'Special Request'}
                    />
                  </>
                ))}
                {hasPackageCode ? (
                  <>
                    <PriceDisplay label="Room Rate:" price={0} totalRoomRate={calculateTotalPrice(rooms)} />
                    {isHoliStayBooking ? (
                      <>
                        <PriceDisplay label="Surcharge:" price={surcharge} />
                        <PriceDisplay label="Total Payment:" price={surcharge} isBold />
                      </>
                    ) : (
                      <PriceDisplay label="Total Payment:" price={0} isBold />
                    )}
                  </>
                ) : hasPromotionCode ? (
                  <>
                    <PriceDisplay label="Room Rate:" price={calculateTotalPrice(rooms)} totalRoomRate={calculateTotalPriceBeforeDiscount(rooms)} />
                    <PriceDisplay label="Total Discount:" price={calculateTotalDiscount(rooms)} />
                    <PriceDisplay label="Total Payment:" price={calculateTotalPrice(rooms)} isBold />
                  </>
                ) : (
                  <>
                    <PriceDisplay label="Room Rate:" price={calculateTotalPrice(rooms)} />
                    <PriceDisplay label="Total Payment:" price={calculateTotalPrice(rooms)} isBold />
                  </>
                )}
                <Divider height="2px" />
                {booking.paymentDetails && (
                  <>
                    <ContentDisplay label="Paid By:" details={getLabelOfConstant(booking.paymentDetails.type, paymentTypes)} />
                    {!!paymentGatewayLabel && (
                      <ContentDisplay
                        label="Payment Gateway:"
                        details={
                          <span>
                            {paymentGatewayLabel}&nbsp;
                            <Tooltip title={booking.paymentDetails.sourceId}>
                              <ExclamationCircleOutlined />
                            </Tooltip>
                          </span>
                        }
                      />
                    )}
                    {booking.packageCode && <ContentDisplay label="Package Code:" details={booking.packageCode} />}
                    {hasPromotionCode && <ContentDisplay label="Promotion Code:" details={booking.paymentDetails.promotionCode} />}
                  </>
                )}
              </SectionCard>
              {isEditMode && (
                <SectionCard title="Financial Info" cardStyle={{ marginTop: '16px' }}>
                  <PriceDisplay
                    label="Total:"
                    price={getTotalOfFinancialAmount(booking.financialDetails.rooms)}
                    remarks={booking.financialDetails.remarks}
                    remarksLabel={'Remarks'}
                  />
                  <Button type="primary" onClick={() => setIsUpdateFinInfoButtonClicked(true)}>
                    Update financial info
                  </Button>
                  <FinancialInfoModal
                    visible={isUpdateFinInfoButtonClicked}
                    onCancel={() => setIsUpdateFinInfoButtonClicked(false)}
                    onSave={() => setIsUpdateFinInfoButtonClicked(false)}
                    bookingCode={booking.code}
                    financialDetails={booking.financialDetails}
                    bookingId={bookingId}
                  />
                </SectionCard>
              )}
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default BookingDetails;
