import styled from '@emotion/styled';

import { Row } from 'antd';

export const StyledDiv = styled.div`
  margin-right: 10px;
`;

export const StyledRow = styled(Row)`
  margin: 6px 0;
`;

export const StyledText = styled.p`
  margin-bottom: '8px';
  font-size: '16px';
`;
