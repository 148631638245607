import React from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown, Menu } from 'antd';
import { UserOutlined, KeyOutlined, LogoutOutlined } from '@ant-design/icons';

import { buildBaseUri, getMyProfileRoute, getResetPasswordRoute } from 'utils/routes';

import { AvatarButton, MenuItem, StyledFoldIcon, StyledHeaderContainer, StyledUnfoldIcon } from './Header.styles';
import { withAppContext } from 'contexts/AppContext/AppContext';

const Header = ({ isCollapsed, onCollapseClick, user, onLogout }) => {
  const history = useHistory();

  const getInitalsFromFullName = () => {
    const nameSeperations = user && user.name ? user.name.split(' ') : [];
    if (nameSeperations.length > 0) {
      const firstInitial = nameSeperations[0] ? nameSeperations[0].charAt(0).toUpperCase() : '';
      const secondInitial = nameSeperations[1] ? nameSeperations[1].charAt(0).toUpperCase() : '';
      return `${firstInitial}${secondInitial}`;
    }
    return 'AD';
  };

  const handleOnMenuItemClick = e => {
    const uri = e.key;
    if (uri === buildBaseUri()) {
      onLogout();
    }
    history.push(uri);
  };

  const menu = (
    <Menu onClick={handleOnMenuItemClick}>
      <MenuItem key={getMyProfileRoute().path}>
        <UserOutlined /> My Profile
      </MenuItem>
      <MenuItem key={getResetPasswordRoute().path}>
        <KeyOutlined /> Reset Password
      </MenuItem>
      <MenuItem key={buildBaseUri()}>
        <LogoutOutlined /> Logout
      </MenuItem>
    </Menu>
  );

  return (
    <StyledHeaderContainer>
      <div onClick={onCollapseClick}>{isCollapsed ? <StyledUnfoldIcon /> : <StyledFoldIcon />}</div>
      <Dropdown overlay={menu} placement="bottomRight" trigger="click">
        <AvatarButton>{getInitalsFromFullName()}</AvatarButton>
      </Dropdown>
    </StyledHeaderContainer>
  );
};

export default withAppContext(Header);
