import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row, Table } from 'antd';
import { Link, useHistory } from 'react-router-dom';

import Card from 'components/Card/Card';
import SectionContainer from 'components/SectionContainer/SectionContainer';
import Title from 'components/Title/Title';

import { useGetPaginatedProperties } from 'apis/property';
import { useFetchConstant } from 'hooks/constants';
import { constructConstantLabel } from 'utils/constants';
import { getLabelOfConstant } from 'utils/general';
import { getNewPropertyRoute, getPropertyDetailsRoute } from 'utils/routes';
import { constructColumn, constructColumnFilterSearch, constructColumnFilterRadio, handleOnAggregationTableChange } from 'utils/table/table';

const constructColumns = (states, propertyStatuses) => [
  {
    ...constructColumn('Property', 'name'),
    ...constructColumnFilterSearch('name', 'Search Property'),
    render: (text, record) => {
      return <Link to={getPropertyDetailsRoute(record._id).path}>{text}</Link>;
    }
  },
  {
    ...constructColumn('Host', 'hostName'),
    ...constructColumnFilterSearch('hostName', 'Search Host')
  },
  {
    ...constructColumn('City', 'city', { width: '15%' }),
    ...constructColumnFilterSearch('city', 'Search City')
  },
  {
    ...constructColumn('State', 'state', { width: '15%' }),
    ...constructColumnFilterRadio('state', states),
    render: text => constructConstantLabel(states, text, text)
  },
  {
    ...constructColumn('Status', 'status'),
    ...constructColumnFilterRadio('status', propertyStatuses),
    render: status => getLabelOfConstant(status, propertyStatuses) || '-'
  }
];

const Hosts = () => {
  const history = useHistory();
  const [query, setQuery] = useState({});
  const { isLoading: isPropertiesLoading, paginatedData: properties, total } = useGetPaginatedProperties(query);
  const { isLoading: isStateLoading, selection: states } = useFetchConstant('statesMY');
  const { isLoading: isPropertyStatusesLoading, selection: propertyStatuses } = useFetchConstant('propertyStatuses');

  const isLoading = isPropertiesLoading || isStateLoading || isPropertyStatusesLoading;

  return (
    <Card>
      <SectionContainer>
        <Row justify="space-between" align="middle">
          <Col>
            <Title>Properties</Title>
          </Col>
          <Col>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => history.push(getNewPropertyRoute().path)}>
              Add new property
            </Button>
          </Col>
        </Row>
      </SectionContainer>
      <Table
        loading={isLoading}
        rowKey={record => record._id}
        dataSource={properties}
        columns={constructColumns(states, propertyStatuses)}
        scroll={{ x: 1000 }}
        pagination={{ total }}
        onChange={(pagination, filters, sorter) => handleOnAggregationTableChange({ pagination, filters, sorter }, setQuery)}
      />
    </Card>
  );
};

export default Hosts;
