import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Table, Row, Col, Button } from 'antd';

import { useGetPaginatedUsers } from 'apis/user';

import Card from 'components/Card/Card';
import Title from 'components/Title/Title';
import SectionContainer from 'components/SectionContainer/SectionContainer';

import { getUserDetailsRoute, getNewUserRoute } from 'utils/routes';
import { constructColumn, constructColumnFilterSearch, handleOnAggregationTableChange } from 'utils/table/table';

const constructColumns = () => [
  {
    ...constructColumn('Username', 'username', { hasSorter: true }),
    ...constructColumnFilterSearch('username', 'Search Username'),
    render: (text, record) => {
      return <Link to={getUserDetailsRoute(record._id).path}>{text}</Link>;
    }
  },
  {
    ...constructColumn('Name', 'name', { hasSorter: true }),
    ...constructColumnFilterSearch('name', 'Search Name')
  },
  {
    ...constructColumn('Email Address', 'email'),
    ...constructColumnFilterSearch('email', 'Search Email Address')
  },
  {
    ...constructColumn('Contact Number', 'contactNo'),
    ...constructColumnFilterSearch('contactNo', 'Search Contact Number')
  }
];

const Users = () => {
  const history = useHistory();
  const [query, setQuery] = useState({});
  const { isLoading: isUsersLoading, paginatedData: users, total } = useGetPaginatedUsers(query);

  return (
    <Card>
      <SectionContainer>
        <Row justify="space-between" align="middle">
          <Col>
            <Title>Users</Title>
          </Col>
          <Col>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => history.push(getNewUserRoute().path)}>
              Add new user
            </Button>
          </Col>
        </Row>
      </SectionContainer>
      <Table
        loading={isUsersLoading}
        rowKey={record => record._id}
        dataSource={users}
        columns={constructColumns()}
        scroll={{ x: 1000 }}
        pagination={{ total }}
        onChange={(pagination, filters, sorter) => handleOnAggregationTableChange({ pagination, filters, sorter }, setQuery)}
      />
    </Card>
  );
};

export default Users;
