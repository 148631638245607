import React, { useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Form, Modal, message, Row, Skeleton, Tabs } from 'antd';

import ActivationButton from 'components/ActivationButton/ActivationButton';
import ClickableTextButton from 'components/ClickableTextButton/ClickableTextButton';
import Title from 'components/Title/Title';

import {
  useGetPromotionById,
  postCreatePromotion,
  patchUpdatePromotion,
  patchUpdatePromotionStatus,
  deletePromotion,
  refetchPromotionDetails,
  verifyPromotionCode
} from 'apis/promotion';
import { useGetProperties } from 'apis/property';
import { useFetchConstant } from 'hooks/constants';

import { constructDisplayPercentage, constructPercentageFromDisplay, getLabelOfConstant, guard } from 'utils/general';
import { getPromotionsRoute } from 'utils/routes';

import PromotionDetailsForm from './PromotionDetailsForm/PromotionDetailsForm';

import { StatusTag } from './PromotionDetails.styles';

const { useForm } = Form;
const { TabPane } = Tabs;

/* =============================================== Local Functions =============================================== */

const PromotionStatusTag = ({ promotionStatuses, promotionStatusesConst, status }) => {
  const label = getLabelOfConstant(status, promotionStatuses);

  const getTagColor = () => {
    switch (status) {
      case promotionStatusesConst.ACTIVE.code:
        return 'success';
      case promotionStatusesConst.INACTIVE.code:
        return 'error';
      default:
        return 'default';
    }
  };

  return <StatusTag color={getTagColor()}>{label} </StatusTag>;
};

const usePropertySelection = () => {
  const { isLoading: isPropertiesLoading, data: properties } = useGetProperties();

  const propertySelections = useMemo(() => guard(() => properties.map(property => ({ label: property.name, value: property._id })), []), [
    properties
  ]);

  return { isPropertiesLoading, propertySelections };
};

const usePromotion = (promotionTypes, promotionId) => {
  const { isLoading: isPromotionLoading, data: promotion } = useGetPromotionById(promotionId);

  const promotionFormInitialValue = useMemo(() => {
    const formattedPromotion = promotion || {};

    const defaultPromotionTypeObject = promotionTypes.find(type => !!type.isDefault);
    const isPercentage = promotionTypes.find(type => type.code === formattedPromotion.type && type.isPercentage);
    const formattedAmount = isPercentage ? constructDisplayPercentage(formattedPromotion.amount) : formattedPromotion.amount;

    return {
      code: formattedPromotion.code,
      name: formattedPromotion.name,
      type: formattedPromotion.type || guard(() => defaultPromotionTypeObject.value),
      properties: formattedPromotion.properties,
      amount: formattedAmount || 1,
      redemptionCount: formattedPromotion.redemptionCount,
      status: formattedPromotion.status
    };
  }, [promotionTypes, promotion]);

  return { isPromotionLoading, promotionFormInitialValue, refetchPromotionDetails };
};

/* =============================================== Main Component =============================================== */

const PromotionDetails = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const history = useHistory();
  const [form] = useForm();
  const { id: promotionId } = useParams();
  const isEdit = useMemo(() => !!promotionId, [promotionId]);

  const { isLoading: isPromotionStatusesLoading, selection: promotionStatuses, data: promotionStatusesConst } = useFetchConstant('promotionStatuses');
  const { isLoading: isPromotionTypesLoading, selection: promotionTypes } = useFetchConstant('promotionTypes');

  const { isPropertiesLoading, propertySelections } = usePropertySelection();
  const { isPromotionLoading, promotionFormInitialValue, refetchPromotionDetails } = usePromotion(promotionTypes, promotionId);

  const isLoading = useMemo(() => isPromotionStatusesLoading || isPromotionTypesLoading || isPropertiesLoading || isPromotionLoading, [
    isPromotionStatusesLoading,
    isPromotionTypesLoading,
    isPropertiesLoading,
    isPromotionLoading
  ]);
  const isActivePromotion = useMemo(() => guard(() => promotionFormInitialValue.status === promotionStatusesConst.ACTIVE.code), [
    promotionStatusesConst,
    promotionFormInitialValue
  ]);

  const handleOnActivatePromotion = () => {
    patchUpdatePromotionStatus(promotionId, promotionStatusesConst.ACTIVE.code).then(() => {
      message.success(`This promotion has been successfully activated.`);

      refetchPromotionDetails(promotionId);
    });
  };

  const handleOnDeactivatePromotion = () => {
    patchUpdatePromotionStatus(promotionId, promotionStatusesConst.INACTIVE.code).then(() => {
      message.success(`This promotion has been successfully deactivated.`);

      refetchPromotionDetails(promotionId);
    });
  };

  const handleOnSubmit = () => {
    setIsSubmitting(true);

    return form
      .validateFields()
      .then(async values => {
        const isPercentage = promotionTypes.find(type => type.code === values.type && type.isPercentage);
        const amount = isPercentage ? constructPercentageFromDisplay(values.amount) : values.amount;

        const payload = {
          ...values,
          amount
        };

        if (isEdit) {
          return patchUpdatePromotion(promotionId, payload)
            .then(() => {
              message.success('Promotion successfully updated.');

              refetchPromotionDetails(promotionId);
            })
            .catch(ex => message.error(ex.message));
        } else {
          await verifyPromotionCode(payload.code).catch(ex => {
            form.setFields([{ name: 'code', errors: [ex.message] }]);

            throw ex;
          });

          return postCreatePromotion(payload)
            .then(() => {
              message.success('Promotion successfully created.');

              history.push(getPromotionsRoute().path);
            })
            .catch(ex => message.error(ex.message));
        }
      })
      .catch(ex => {
        console.log('ex :>> ', ex);
        message.error(ex.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleOnDeletePromotion = () => {
    setIsSubmitting(true);

    Modal.confirm({
      title: 'Are you sure you want to delete this promotion?',
      content: 'You will not able to undo this action.',
      onOk() {
        return deletePromotion(promotionId)
          .then(() => {
            message.success('Promotion successfully deleted!');

            history.push(getPromotionsRoute().path);
          })
          .catch(ex => message.error(ex))
          .finally(() => setIsSubmitting(false));
      },
      oncancel() {
        setIsSubmitting(false);
      }
    });
  };

  return isLoading ? (
    <Skeleton active />
  ) : (
    <div style={{ width: '100%' }}>
      <ClickableTextButton text="Back to Promotions" url={getPromotionsRoute().path} />
      <Row type="flex" align="middle" style={{ marginBottom: '16px' }}>
        <Title> {isEdit ? promotionFormInitialValue.code : 'New Promotion'} </Title>
        {isEdit && (
          <PromotionStatusTag
            promotionStatuses={promotionStatuses}
            promotionStatusesConst={promotionStatusesConst}
            status={promotionFormInitialValue.status}
          />
        )}
      </Row>

      <Card>
        <Tabs
          defaultActiveKey="1"
          tabBarExtraContent={
            <ActivationButton
              id={promotionId}
              label="promotion"
              isActivated={isActivePromotion}
              onActivate={handleOnActivatePromotion}
              onDeactivate={handleOnDeactivatePromotion}
            />
          }
        >
          <TabPane tab="Promotion" key="1">
            <PromotionDetailsForm
              form={form}
              promotionTypes={promotionTypes}
              propertySelections={propertySelections}
              isEdit={isEdit}
              isSubmitting={isSubmitting}
              initialValues={promotionFormInitialValue}
              onFormSave={handleOnSubmit}
              onDeletePromotion={handleOnDeletePromotion}
            />
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
};

export default PromotionDetails;
