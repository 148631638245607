import React from 'react';
import { List, Button, Divider, Col } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import Counter from 'components/Counter/Counter';

import { StyledDiv, StyledRow, StyledText } from './Bedrooms.styles';

export const BedRoomListItem = ({ disabled, title, description, onEditClick, shouldDisableRemove, onRemoveClick }) => {
  return (
    <List.Item>
      <List.Item.Meta title={title} description={description} />
      <StyledDiv>
        <Button ghost disabled={disabled} type="primary" onClick={onEditClick}>
          Edit
        </Button>
      </StyledDiv>
      <StyledDiv>
        <Button icon={<DeleteOutlined />} disabled={disabled || shouldDisableRemove} ghost type="danger" onClick={onRemoveClick} />
      </StyledDiv>
    </List.Item>
  );
};

export const BedConfiguration = ({ roomName, beds, onBedDecrement, onBedIncrement }) => {
  return (
    <div>
      <h4> Editing: {roomName} </h4>
      <Divider />
      {beds &&
        beds.length > 0 &&
        beds.map(bed => (
          <StyledRow key={bed.label} type="flex" justify="left">
            <Col span={16} md={13}>
              <StyledText>{bed.label} </StyledText>
            </Col>
            <Col span={8} md={11}>
              <Counter count={bed.count} onDecrement={onBedDecrement(bed.key)} onIncrement={onBedIncrement(bed.key)} />
            </Col>
          </StyledRow>
        ))}
    </div>
  );
};
