import styled from '@emotion/styled';
import { Button } from 'antd';

export const CountContainer = styled.div`
  width: 100%;
  line-height: 40px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  font-size: 14px;
  width: 30%;
  min-width: 30px;
  max-width: 60px;

  background-color: ${props => (props.disabled ? 'rgba(130, 130, 130, 0.1) !important' : '')};
`;

export const DisplayText = styled.p`
  margin: 0;
  font-size: 16px;
`;
