import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

import FormItem from 'components/FormItem/FormItem';
import { guard } from 'utils/general';

const DEFAULT_MALAYSIA_LNG_LAT = { lng: 101.9758, lat: 4.2105 };

const MyGoogleMap = ({ disabled, onMarkerMove, value, onChange }) => {
  const position = guard(() => ({ lng: value[0], lat: value[1] }), DEFAULT_MALAYSIA_LNG_LAT);

  const handleOnMarkerDragEnd = e => {
    const lngLat = [e.latLng.lng(), e.latLng.lat()];
    onMarkerMove(lngLat);
    onChange(lngLat);
  };

  return (
    <GoogleMap defaultZoom={16} defaultCenter={DEFAULT_MALAYSIA_LNG_LAT} center={position}>
      <Marker draggable={!disabled} onDragEnd={handleOnMarkerDragEnd} position={position} />
    </GoogleMap>
  );
};

const FormGoogleMap = ({ disabled, name, onMarkerMove }) => {
  return (
    <FormItem name={name}>
      <MyGoogleMap disabled={disabled} onMarkerMove={onMarkerMove} />
    </FormItem>
  );
};

export default withScriptjs(withGoogleMap(FormGoogleMap));
