import React, { useState } from 'react';

import Header from './components/Header/Header';
import NavBar from './components/NavBar/NavBar';

import HASLogo from './images/HostAStay-logo.png';

import { ContentContainer, ContentLayout, Image, LogoContainer, RootLayout, StyledBody, StyledSider } from './StandardLayout.styles';

const StandardLayout = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleOnCollapseClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <RootLayout>
      <StyledSider breakpoint="lg" collapsible collapsed={isCollapsed} trigger={null}>
        <LogoContainer>
          <Image alt="logo" src={HASLogo} isCollapsed={isCollapsed} />
        </LogoContainer>
        <NavBar />
      </StyledSider>
      <ContentLayout>
        <Header isCollapsed={isCollapsed} onCollapseClick={handleOnCollapseClick} />
        <StyledBody>
          <ContentContainer>{children}</ContentContainer>
        </StyledBody>
      </ContentLayout>
    </RootLayout>
  );
};

export default StandardLayout;
