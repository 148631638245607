import React, { useMemo } from 'react';
import { Button, Row, Form, Skeleton, Modal, message } from 'antd';
import {
  CheckOutlined,
  ExclamationCircleOutlined,
  BellOutlined,
  SkinOutlined,
  CoffeeOutlined,
  HomeOutlined,
  CloudOutlined,
  HddOutlined,
  SmileOutlined,
  WarningTwoTone,
  DeleteOutlined
} from '@ant-design/icons';

import { useFetchConstant } from 'hooks/constants';

import { convertAmenitiesSelectionToObj } from 'utils/general';
import { postCreateRoomType, patchUpdateRoomType, deleteRoomType } from 'apis/roomType';

import Card from 'components/Card/Card';
import BasicDetailsCard from './components/BasicDetailsCard/BasicDetailsCard';
import SetupCard from './components/SetupCard/SetupCard';
import AmenitiesCard from '../components/AmenitiesCard/AmenitiesCard';
import PhotosCard from '../components/PhotosCard/PhotosCard';

import { StyledSubmitButton } from './RoomType.styles';

const { useForm } = Form;

const FIELD_NAME_ROOM_SIZE = 'roomSizeInSqFt';
const FIELD_NAME_CAPACITY_ADULT = 'adultCapacity';
const FIELD_NAME_CAPACITY_CHILDREN = 'childrenCapacity';
const FIELD_NAME_NO_OF_LIVING_ROOMS = 'livingrooms';
const FIELD_NAME_NO_OF_BATH_ROOMS = 'bathrooms';
const FIELD_NAME_BEDROOMS = 'bedrooms';
const FIELD_NAME_ROOM_AMENITIES = 'roomAmenities';
const FIELD_NAME_WEEKDAY_PRICE = 'weekdayPrice';
const FIELD_NAME_WEEKEND_PRICE = 'weekendPrice';

const formatBedroomsIntoSelections = (bedrooms, bedTypesSelection) => {
  return bedrooms
    ? bedrooms.map(bedroom => {
        let totalBedCount = 0;
        const beds = Object.keys(bedTypesSelection).reduce((newBeds, key) => {
          let newBed = {
            ...bedTypesSelection[key],
            count: 0
          };
          for (let i = 0; i < bedroom.beds.length; i++) {
            const bed = bedroom.beds[i];
            if (String(bed.type) === String(bedTypesSelection[key].code)) {
              newBed.count = bed.count;
              totalBedCount += bed.count;
              break;
            }
          }
          return {
            ...newBeds,
            [key]: newBed
          };
        }, {});

        return {
          ...bedroom,
          beds,
          totalBedCount
        };
      })
    : undefined;
};

const formatAmenitiesIntoSelection = roomAmenities => {
  let formattedRoomAmenities = {};
  Object.keys(roomAmenities).forEach(key => {
    const { code, label, type } = roomAmenities[key];

    if (type in formattedRoomAmenities) {
      formattedRoomAmenities[type]['data'].push({
        key: code,
        label,
        value: code
      });
    } else {
      formattedRoomAmenities[type] = {
        icon: getRoomTypeAmenitiesIcon(type),
        data: [
          {
            key: code,
            label,
            value: code
          }
        ]
      };
    }
  });

  return formattedRoomAmenities;
};

const formatBedroomsIntoPaylaod = bedrooms => {
  return bedrooms.map(bedroom => {
    const { beds } = bedroom;
    const newBeds = Object.keys(beds)
      .map(bedKey => {
        const bed = beds[bedKey];
        return bed.count
          ? {
              type: bed.code,
              count: bed.count
            }
          : undefined;
      })
      .filter(bed => !!bed); // filter bed that has count 0
    return { name: bedroom.name, beds: newBeds };
  });
};

const formatRoomTypePayload = values => {
  return {
    name: values.name,
    capacity: { adult: values.adultCapacity, children: values.childrenCapacity },
    amenities: Object.values(values.roomAmenities).reduce((amenities, currentAmmenity) => {
      return currentAmmenity === undefined ? amenities : amenities.concat(currentAmmenity);
    }, []),
    roomSizeInSqFt: values.roomSizeInSqFt,
    rate: {
      weekday: values.weekdayPrice,
      weekend: values.weekendPrice
    },
    inventory: values.inventory,
    livingrooms: values.livingrooms,
    bathrooms: values.bathrooms,
    bedrooms: formatBedroomsIntoPaylaod(values.bedrooms),
    ...(values.photos && {
      images: values.photos.map(photo => ({
        imageUrl: photo.imageUrl,
        caption: [{ text: photo.caption }]
      }))
    })
  };
};

const getRoomTypeAmenitiesIcon = type => {
  const icons = {
    Accessibility: <BellOutlined style={{ color: '#231F20', paddingRight: '10px' }} />,
    Bathroom: <SkinOutlined style={{ color: '#231F20', paddingRight: '10px' }} />,
    'Food and Drink': <CoffeeOutlined style={{ color: '#231F20', paddingRight: '10px' }} />,
    'Media Technology': <HomeOutlined style={{ color: '#231F20', paddingRight: '10px' }} />,
    'Outdoor and View': <CloudOutlined style={{ color: '#231F20', paddingRight: '10px' }} />,
    'Room Amenities': <HddOutlined style={{ color: '#231F20', paddingRight: '10px' }} />,
    'Service and Extras': <SmileOutlined style={{ color: '#231F20', paddingRight: '10px' }} />
  };
  return icons[type] || 'tool';
};

const RoomType = ({ canEdit, listingId, roomTypeDetails, handleOnShowRoomTypeDetails, bedTypesSelection }) => {
  const [form] = useForm();

  const { isLoading: isAmenitiesLoading, selection: amenitiesSelection } = useFetchConstant('roomAmenities');

  const formattedAmenities = useMemo(() => {
    if (isAmenitiesLoading) {
      return {};
    }

    return formatAmenitiesIntoSelection(amenitiesSelection);
  }, [isAmenitiesLoading, amenitiesSelection]);

  const formattedRoomTypeDetails = useMemo(() => {
    if (Object.entries(roomTypeDetails).length === 0) {
      return {};
    }

    return {
      _id: roomTypeDetails.roomType._id,
      name: roomTypeDetails.name,
      [FIELD_NAME_ROOM_SIZE]: roomTypeDetails.roomType.roomSizeInSqFt,
      [FIELD_NAME_CAPACITY_ADULT]: roomTypeDetails.roomType.capacity.adult,
      [FIELD_NAME_CAPACITY_CHILDREN]: roomTypeDetails.roomType.capacity.children,
      inventory: roomTypeDetails.inventory,
      [FIELD_NAME_NO_OF_LIVING_ROOMS]: roomTypeDetails.roomType.livingrooms,
      [FIELD_NAME_NO_OF_BATH_ROOMS]: roomTypeDetails.roomType.bathrooms,
      [FIELD_NAME_BEDROOMS]: formatBedroomsIntoSelections(roomTypeDetails.roomType.bedrooms, bedTypesSelection),
      [FIELD_NAME_ROOM_AMENITIES]: convertAmenitiesSelectionToObj(roomTypeDetails.roomType.amenities, formattedAmenities),
      photos: roomTypeDetails.images.map(image => ({
        _id: image._id,
        imageUrl: image.imageUrl,
        caption: image.caption[0].text
      })),
      [FIELD_NAME_WEEKDAY_PRICE]: roomTypeDetails.rates[0].weekday,
      [FIELD_NAME_WEEKEND_PRICE]: roomTypeDetails.rates[0].weekend
    };
  }, [roomTypeDetails, bedTypesSelection, formattedAmenities]);

  const roomTypeId = formattedRoomTypeDetails._id;
  const isEditMode = !!roomTypeId;

  const isLoading = isAmenitiesLoading;

  const handleOnSubmitForm = async () => {
    try {
      const values = await form.validateFields();
      const payload = formatRoomTypePayload(values);

      if (isEditMode) {
        Modal.confirm({
          title: 'Are you sure you want to overwrite existing data?',
          content: 'You will not be able to undo this action, but you may update it again.',
          icon: <ExclamationCircleOutlined />,
          onOk() {
            patchUpdateRoomType(listingId, roomTypeId, payload).then(() => {
              message.success('You have successfully updated this room type!');
            });
          },
          onCancel() {}
        });
      } else {
        postCreateRoomType(listingId, payload).then(() => {
          message.success('You have successfully created new room type!');
          handleOnShowRoomTypeDetails(false);
        });
      }
    } catch (error) {
      message.error('Error while creating new room type, please contact support team!');
      console.error(error.message);
    }
  };

  const handleOnDeleteButtonClick = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this room type?',
      content: 'This room type will be permanently deleted.',
      icon: <WarningTwoTone twoToneColor="red" />,
      okText: 'Confirm',
      onOk() {
        deleteRoomType(listingId, roomTypeId).then(() => {
          message.success('You have successfully deleted this room type!');
          handleOnShowRoomTypeDetails(false);
        });
      },
      onCancel() {}
    });
  };

  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Card onClose={() => handleOnShowRoomTypeDetails(false)}>
          <Row gutter={[0, 16]}>
            <Form form={form} initialValues={formattedRoomTypeDetails} styled={{ width: '100%' }} onFinish={handleOnSubmitForm}>
              <BasicDetailsCard canEdit={canEdit} form={form} isEditMode={!!formattedRoomTypeDetails._id} />
              <SetupCard canEdit={canEdit} form={form} bedTypes={bedTypesSelection} />
              <AmenitiesCard
                canEdit={canEdit}
                fieldName={FIELD_NAME_ROOM_AMENITIES}
                amenities={formattedAmenities}
                defaultValues={formattedRoomTypeDetails[FIELD_NAME_ROOM_AMENITIES]}
              />
              <PhotosCard form={form} />
              <StyledSubmitButton type="primary" htmlType="submit" size="large" icon={<CheckOutlined />}>
                {isEditMode ? 'Save' : 'Create'}
              </StyledSubmitButton>
              {isEditMode && (
                <Button type="danger" onClick={handleOnDeleteButtonClick} size="large" icon={<DeleteOutlined />}>
                  Delete
                </Button>
              )}
            </Form>
          </Row>
        </Card>
      )}
    </>
  );
};

export default RoomType;
