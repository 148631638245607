const FORM_KEY_HOST = 'host';
const FORM_KEY_NAME = 'name';
const FORM_KEY_PROPERTY_TYPES = 'propertyTypes';
const FORM_KEY_PROPERTY_CLASS_TYPE = 'classType';
const FORM_KEY_IS_ALLOW_INSTANT_BOOKING = 'isAllowInstantBooking';
const FORM_KEY_IS_HOLISTAY_PROMO = 'isHoliStayPromo';
const FORM_KEY_CHECK_IN_TIME = 'checkInTime';
const FORM_KEY_CHECK_OUT_TIME = 'checkOutTime';
const FORM_KEY_MIN_NIGHT = 'minNight';
const FORM_KEY_MAX_NIGHT = 'maxNight';
const FORM_KEY_ZIP_CODE = 'zipCode';
const FORM_KEY_COUNTRY_CODE = 'countryCode';
const FORM_KEY_CITY = 'city';
const FORM_KEY_STREET = 'street';
const FORM_KEY_STATE = 'state';
const GOOGLE_MAP_API =
  'https://maps.googleapis.com/maps/api/js?key=AIzaSyAHBWhCJOD2ow59B91tQWXL_lunDkacQXE&v=3.exp&libraries=geometry,drawing,places';
const FORM_KEY_PROPERTY_AMENITIES = 'propertyAmenities';
const FORM_KEY_LOCATION = 'location';
const FORM_KEY_LONGITUDE = 'longitude';
const FORM_KEY_LATITUDE = 'latitude';
const FORM_KEY_IMAGES = 'images';

export {
  FORM_KEY_HOST,
  FORM_KEY_NAME,
  FORM_KEY_PROPERTY_TYPES,
  FORM_KEY_PROPERTY_CLASS_TYPE,
  FORM_KEY_IS_ALLOW_INSTANT_BOOKING,
  FORM_KEY_IS_HOLISTAY_PROMO,
  FORM_KEY_CHECK_IN_TIME,
  FORM_KEY_CHECK_OUT_TIME,
  FORM_KEY_MIN_NIGHT,
  FORM_KEY_MAX_NIGHT,
  FORM_KEY_ZIP_CODE,
  FORM_KEY_COUNTRY_CODE,
  FORM_KEY_CITY,
  FORM_KEY_STREET,
  FORM_KEY_STATE,
  GOOGLE_MAP_API,
  FORM_KEY_PROPERTY_AMENITIES,
  FORM_KEY_LOCATION,
  FORM_KEY_LONGITUDE,
  FORM_KEY_LATITUDE,
  FORM_KEY_IMAGES
};
