import { useMemo } from 'react';
import api from 'apis/apiHelper';
import { useCustomQuery } from 'hooks/reactQuery';

export const useFetchConstant = (name, { valueKey = 'code', labelKey = 'label', extraCustomKey = '', query = {} } = {}) => {
  const getConstant = (key, name, query) => {
    return api.get(`/constants/${name}`, { params: query });
  };

  const { isLoading, data, ...returnedParams } = useCustomQuery(`constant${name}${extraCustomKey}`, [name, query], getConstant, {
    shouldDefaultEmptyObject: false,
    prefixErrorMessage: `Error while getting ${name}.\n`
  });

  const isReadyToConstructSelection = !isLoading || !!data;
  const isConstructingPhoneCodeSelection = name === 'countries' && query.isFetchingAllCountries && valueKey === 'phoneCode';

  const selection = useMemo(() => constructSelections(data, valueKey, labelKey, isReadyToConstructSelection, isConstructingPhoneCodeSelection), [
    data,
    valueKey,
    labelKey,
    isReadyToConstructSelection,
    isConstructingPhoneCodeSelection
  ]);

  return { isLoading, selection, data, ...returnedParams };
};

const constructSelections = (data, valueKey, labelKey, isReady, isCountryPhoneCode) => {
  if (isReady && data) {
    const dataValues = Object.values(data);
    if (isCountryPhoneCode) {
      return dataValues.reduce((uniqueCountryCodes, country) => {
        if (!uniqueCountryCodes.find(countryCode => countryCode.phoneCode === country.phoneCode)) {
          uniqueCountryCodes.push({
            ...country,
            value: country[valueKey],
            label: country[labelKey]
          });
        }
        return uniqueCountryCodes;
      }, []);
    } else {
      return dataValues.map(selection => ({
        ...selection,
        value: selection[valueKey],
        label: selection[labelKey]
      }));
    }
  }
  return [];
};
