import React, { useState, useEffect } from 'react';
import { Row, Col, Form, message } from 'antd';
import { UserOutlined, KeyOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';

import { withAppContext } from 'contexts/AppContext/AppContext';
import FormInput from 'components/FormInput/FormInput';

import { getOverviewRoute } from 'utils/routes';

import { Root, TitleRow, TitleLabel, TitleNameLabel, LoginRow, LoginContainer, LoginButton } from './Login.styles';

const { useForm } = Form;

const overviewRoute = getOverviewRoute();

const Login = ({ isLoggedIn, isUserLoading, onLogin }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = useForm();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const { state } = location;
    if (!isLoading && !isUserLoading && (!state || !state.isChangePassword)) {
      if (isLoggedIn) {
        message.info('You already logged in.');
        history.push(overviewRoute.path);
      }
    }
  }, [isLoggedIn, isUserLoading, isLoading, history, location]);

  const handleOnFormSubmitAndValidated = values => {
    setIsLoading(true);
    onLogin(values.username.toLowerCase(), values.password)
      .then(() => {
        history.push(overviewRoute.path);
      })
      .catch(() => {
        form.setFields([
          { name: 'username', errors: ['Invalid username or password'] },
          { name: 'password', errors: ['Invalid username or password'] }
        ]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Root loading={isLoading}>
      <TitleRow type="flex" justify="center">
        <Col>
          <TitleLabel>
            Welcome to <TitleNameLabel>HostAStay Admin Panel</TitleNameLabel>
          </TitleLabel>
        </Col>
      </TitleRow>
      <LoginRow type="flex" justify="center" align="center">
        <Col lg={10} span={20}>
          <LoginContainer>
            <Form form={form} onFinish={handleOnFormSubmitAndValidated}>
              <FormInput
                label="Username"
                prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Your username"
                name="username"
                requiredErrorMessage="Please enter your username"
              />
              <FormInput
                label="Password"
                prefix={<KeyOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Your password"
                type="password"
                name="password"
                requiredErrorMessage="Please enter your password"
              />
              <Row>
                <LoginButton htmlType="submit" type="primary" disabled={isLoading} loading={isLoading}>
                  Login
                </LoginButton>
              </Row>
            </Form>
          </LoginContainer>
        </Col>
      </LoginRow>
    </Root>
  );
};

export default withAppContext(Login);
