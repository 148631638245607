import React, { useState, useEffect, useRef } from 'react';
import propsTypes from 'prop-types';
import { CSVLink } from 'react-csv';

const CsvDownload = ({ asyncExportMethod, filename, headers, children, disable }) => {
  const [csvData, setCsvData] = useState(false);
  const csvInstance = useRef();
  useEffect(() => {
    if (csvData && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData(false);
      });
    }
  }, [csvData]);
  return (
    <>
      <div
        onClick={async () => {
          if (disable) {
            return;
          }
          const newCsvData = await asyncExportMethod();
          setCsvData(newCsvData);
        }}
      >
        {children}
      </div>
      {csvData && headers ? <CSVLink data={csvData} headers={headers} filename={filename} ref={csvInstance} /> : undefined}
    </>
  );
};

export default CsvDownload;

CsvDownload.defaultProps = {
  children: undefined,
  asyncExportMethod: () => null,
  headers: [],
  disable: false
};

CsvDownload.propTypes = {
  children: propsTypes.node,
  asyncExportMethod: propsTypes.func.isRequired,
  headers: propsTypes.array.isRequired,
  disable: propsTypes.bool
};
