import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, message, Modal, Table, Row } from 'antd';

import Card from 'components/Card/Card';
import Title from 'components/Title/Title';

import { useGetPaginatedBookingsForOverview, patchUpdateBookingStatus } from 'apis/booking';
import { getBookingDetailRoute } from 'utils/routes';
import { constructColumn, constructColumnFilterSearch, handleOnAggregationTableChange } from 'utils/table/table';

import { StatusUpdateButton } from './Overview.styles';

const { confirm } = Modal;

const constructColumns = (onAcceptBookingButtonClick, onRejectBookingButtonClick) => [
  {
    ...constructColumn('Confirmation Code', 'code', { width: 180 }),
    ...constructColumnFilterSearch('code', 'Search Confirmation Code'),
    render: (text, record) => {
      return (
        <Link to={getBookingDetailRoute(record._id).path} target="_blank">
          {text}
        </Link>
      );
    }
  },
  {
    ...constructColumn('Check In Date', 'startDate', { isDate: true, hasSorter: true, width: 150 })
  },
  {
    ...constructColumn('Check Out Date', 'endDate', { isDate: true, hasSorter: true, width: 150 })
  },
  {
    ...constructColumn('Property Name', 'propertyName'),
    ...constructColumnFilterSearch('propertyName', 'Search Property')
  },
  {
    ...constructColumn('Guest Name', 'guestName'),
    ...constructColumnFilterSearch('guestName', 'Search Guest Name')
  },
  {
    ...constructColumn('Host Name', 'hostName'),
    ...constructColumnFilterSearch('hostName', 'Search Host')
  },
  {
    ...constructColumn('Update Status', 'action', { width: 200 }),
    render: (text, record) => (
      <Row gutter={4}>
        <Col>
          <StatusUpdateButton type="accept" onClick={() => onAcceptBookingButtonClick(record._id)}>
            Accept
          </StatusUpdateButton>
        </Col>
        <Col>
          <StatusUpdateButton type="reject" onClick={() => onRejectBookingButtonClick(record._id)}>
            Reject
          </StatusUpdateButton>
        </Col>
      </Row>
    )
  }
];

const Overview = () => {
  const [query, setQuery] = useState({});
  const { isLoading: isBookingsLoading, paginatedData: bookings, total, refetch: refetchBookings } = useGetPaginatedBookingsForOverview(query);

  const onAcceptBookingButtonClick = useCallback(
    bookingId => {
      confirm({
        title: 'Are you sure to accept this booking?',
        okText: 'Yes, I want to accept this booking.',
        onOk() {
          patchUpdateBookingStatus(bookingId, 'accepted').then(() => {
            refetchBookings();
            message.success('The booking has been successfully accepted.');
          });
        }
      });
    },
    [refetchBookings]
  );

  const onRejectBookingButtonClick = useCallback(
    bookingId => {
      confirm({
        title: 'Are you sure to reject this booking?',
        okText: 'Yes, I want to reject this booking.',
        onOk() {
          patchUpdateBookingStatus(bookingId, 'rejected').then(() => {
            refetchBookings();
            message.success('The booking has been successfully rejected.');
          });
        }
      });
    },
    [refetchBookings]
  );

  return (
    <Card>
      <Title marginBottom="32px">Overview of All Pending Bookings</Title>
      <Table
        loading={isBookingsLoading}
        rowKey={record => record._id}
        dataSource={bookings}
        columns={constructColumns(onAcceptBookingButtonClick, onRejectBookingButtonClick)}
        scroll={{ x: 1000 }}
        pagination={{ total }}
        onChange={(pagination, filters, sorter) => handleOnAggregationTableChange({ pagination, filters, sorter }, setQuery)}
      />
    </Card>
  );
};

export default Overview;
