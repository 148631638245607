import React, { useEffect } from 'react';

import Counter from 'components/Counter/Counter';
import FormItem from 'components/FormItem/FormItem';

const FormCounter = ({ value, form, fieldName, disable, minValue = 0 }) => {
  useEffect(() => {
    if (!value || value < minValue) {
      form.setFieldsValue({
        [fieldName]: minValue
      });
    }
  }, [fieldName, form, minValue, value]);

  const handleOnIncrement = () => {
    form.setFieldsValue({
      [fieldName]: value + 1
    });
  };

  const handleOnDecrement = () => {
    if (value > minValue) {
      form.setFieldsValue({
        [fieldName]: value - 1
      });
    }
  };

  return <Counter count={value} onDecrement={handleOnDecrement} onIncrement={handleOnIncrement} disable={disable} />;
};

const WrappedFormCounter = ({ name, form, disable, minValue }) => {
  return (
    <FormItem name={name}>
      <FormCounter form={form} fieldName={name} disable={disable} minValue={minValue} />
    </FormItem>
  );
};

export default WrappedFormCounter;
