import React from 'react';
import {
  TeamOutlined,
  LayoutOutlined,
  BankOutlined,
  FileDoneOutlined,
  ShoppingOutlined,
  ContactsOutlined,
  UserOutlined,
  QrcodeOutlined,
  EnvironmentOutlined,
  TagsOutlined
} from '@ant-design/icons';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import { withAppContext } from 'contexts/AppContext/AppContext';
import {
  getOverviewRoute,
  getHostsRoute,
  getPropertiesRoute,
  getBookingsRoute,
  getPromotionsRoute,
  getPackageOrdersRoute,
  getMembersRoute,
  getUsersRoute,
  getQRCodeCheckInRoute,
  getNomadLocationRoute,
  getPartnersRoute,
  getVouchersRoute
} from 'utils/routes';

import { StyledMenu } from './NavBar.styles';

const overviewRoute = { ...getOverviewRoute(), key: 'overview' };
const hostsRoute = { ...getHostsRoute(), key: 'hosts' };
const propertiesRoute = { ...getPropertiesRoute(), key: 'properties' };
const bookingsRoute = { ...getBookingsRoute(), key: 'bookings' };
const promotionsRoute = { ...getPromotionsRoute(), key: 'promotions' };
const packageOrdersRoute = { ...getPackageOrdersRoute(), key: 'package-orders' };
const membersRoute = { ...getMembersRoute(), key: 'members' };
const usersRoute = { ...getUsersRoute(), key: 'users' };
const qrCodeCheckinRoute = { ...getQRCodeCheckInRoute(), key: 'qr-code-check-in' };
const nomadLocationRoute = { ...getNomadLocationRoute(), key: 'nomad-location' };
const partnersRoute = { ...getPartnersRoute(), key: 'partners' };
const vouchersRoute = { ...getVouchersRoute(), key: 'vouchers' };

const MenuItemText = ({ path, label, onClick }) => {
  return (
    <Link to={path} onClick={onClick}>
      <span>{label}</span>
    </Link>
  );
};

const getCurrentPathMenuKey = pathname => {
  switch (true) {
    case pathname.startsWith(overviewRoute.path):
      return overviewRoute.key;
    case pathname.startsWith(hostsRoute.path):
      return hostsRoute.key;
    case pathname.startsWith(propertiesRoute.path):
      return propertiesRoute.key;
    case pathname.startsWith(bookingsRoute.path):
      return bookingsRoute.key;
    case pathname.startsWith(promotionsRoute.path):
      return promotionsRoute.key;
    case pathname.startsWith(packageOrdersRoute.path):
      return packageOrdersRoute.key;
    case pathname.startsWith(membersRoute.path):
      return membersRoute.key;
    case pathname.startsWith(usersRoute.path):
      return usersRoute.key;
    case pathname.startsWith(qrCodeCheckinRoute.path):
      return qrCodeCheckinRoute.key;
    case pathname.startsWith(nomadLocationRoute.path):
      return nomadLocationRoute.key;
    case pathname.startsWith(partnersRoute.path):
      return partnersRoute.key;
    case pathname.startsWith(vouchersRoute.path):
      return vouchersRoute.key;

    default:
      return '';
  }
};

const NavBar = ({ checkCanUserAccess }) => {
  const location = useLocation();

  const currentKey = getCurrentPathMenuKey(location.pathname);

  return (
    <StyledMenu mode="inline" defaultSelectedKeys={[currentKey]} selectedKeys={[currentKey]} onClick={() => {}}>
      {checkCanUserAccess(overviewRoute.role) && (
        <Menu.Item key={overviewRoute.key} icon={<LayoutOutlined />}>
          <MenuItemText path={overviewRoute.path} label="Overview" />
        </Menu.Item>
      )}
      {checkCanUserAccess(hostsRoute.role) && (
        <Menu.Item key={hostsRoute.key} icon={<TeamOutlined />}>
          <MenuItemText path={hostsRoute.path} label="Hosts" />
        </Menu.Item>
      )}
      {checkCanUserAccess(propertiesRoute.role) && (
        <Menu.Item key={propertiesRoute.key} icon={<BankOutlined />}>
          <MenuItemText path={propertiesRoute.path} label="Properties" />
        </Menu.Item>
      )}
      {checkCanUserAccess(bookingsRoute.role) && (
        <Menu.Item key={bookingsRoute.key} icon={<FileDoneOutlined />}>
          <MenuItemText path={bookingsRoute.path} label="Bookings" />
        </Menu.Item>
      )}
      {checkCanUserAccess(promotionsRoute.role) && (
        <Menu.Item key={promotionsRoute.key} icon={<ShoppingOutlined />}>
          <MenuItemText path={promotionsRoute.path} label="Promotions" />
        </Menu.Item>
      )}
      {/* {checkCanUserAccess(packageOrdersRoute.role) && (
        <Menu.Item key={packageOrdersRoute.key} icon={<ShoppingOutlined />}>
          <MenuItemText path={packageOrdersRoute.path} label="Package Orders" />
        </Menu.Item>
      )} */}
      {checkCanUserAccess(membersRoute.role) && (
        <Menu.Item key={membersRoute.key} icon={<ContactsOutlined />}>
          <MenuItemText path={membersRoute.path} label="Members" />
        </Menu.Item>
      )}
      {checkCanUserAccess(usersRoute.role) && (
        <Menu.Item key={usersRoute.key} icon={<UserOutlined />}>
          <MenuItemText path={usersRoute.path} label="Users" />
        </Menu.Item>
      )}
      {checkCanUserAccess(qrCodeCheckinRoute.role) && (
        <Menu.Item key={qrCodeCheckinRoute.key} icon={<QrcodeOutlined />}>
          <MenuItemText path={qrCodeCheckinRoute.path} label="QR Code Check-in" />
        </Menu.Item>
      )}
      {checkCanUserAccess(nomadLocationRoute.role) && (
        <Menu.Item key={nomadLocationRoute.key} icon={<EnvironmentOutlined />}>
          <MenuItemText path={nomadLocationRoute.path} label="Nomad GPS Record" />
        </Menu.Item>
      )}
      {checkCanUserAccess(partnersRoute.role) && (
        <Menu.Item key={partnersRoute.key} icon={<TeamOutlined />}>
          <MenuItemText path={partnersRoute.path} label="Partners" />
        </Menu.Item>
      )}
      {checkCanUserAccess(vouchersRoute.role) && (
        <Menu.Item key={vouchersRoute.key} icon={<TagsOutlined />}>
          <MenuItemText path={vouchersRoute.path} label="Vouchers" />
        </Menu.Item>
      )}
    </StyledMenu>
  );
};

export default withAppContext(NavBar);
