import React from 'react';
import { Input, Form } from 'antd';
import PropTypes from 'prop-types';

import { PASSWORD_REGEX } from 'utils/constants';

import { FormLabel } from './FormPasswordInput.styles';

const FormItem = Form.Item;
const PasswordInput = Input.Password;

const FormPasswordInput = ({ disabled, extraProps, extraRules, label, name, onFocus, placeholder, checkPattern, requiredErrorMessage }) => {
  const generatePropsForRules = () => {
    const rules = [...extraRules];
    if (requiredErrorMessage && typeof requiredErrorMessage === 'string') {
      rules.push({
        required: true,
        message: requiredErrorMessage
      });
    }
    if (checkPattern) {
      rules.push({
        pattern: PASSWORD_REGEX,
        message: 'Password must be alphanumeric with a length of 8 - 20 characters.'
      });
    }
    return rules;
  };

  return (
    <>
      {label && <FormLabel>{label}</FormLabel>}
      <FormItem rules={generatePropsForRules()} name={name} onFocus={() => onFocus(name)}>
        <PasswordInput disabled={disabled} placeholder={placeholder} {...extraProps} autoComplete="new-password" />
      </FormItem>
    </>
  );
};

FormPasswordInput.propTypes = {
  extraProps: PropTypes.object,
  onFocus: PropTypes.func,
  extraRules: PropTypes.array
};

FormPasswordInput.defaultProps = {
  extraProps: {},
  onFocus: () => {},
  extraRules: []
};

export default FormPasswordInput;
