function initConfig(hostNotisEmailTag) {
  return {
    hostNotisEmailTag
  };
}

let appConfig = {};

const appId = process.env.REACT_APP_HOSTPLATFORM_API_PREFIX;
if ('derantau' === appId || 'hasbooking' === appId) {
  appConfig = initConfig('hasBookingNotificationEmails');
} else {
  appConfig = initConfig('staySuitesNotificationEmails');
}

const env = {
  REACT_APP_API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT || 'http://localhost:4000/v1',
  REACT_APP_S3_BUCKET_PRESIGN_URL: process.env.REACT_APP_S3_BUCKET_PRESIGN_URL,
  REACT_APP_UPLOAD_ENDPOINT: process.env.REACT_APP_UPLOAD_ENDPOINT || 'http://localhost:3000/v1/staysuites',
  REACT_APP_S3_IMAGES_BUCKET: process.env.REACT_APP_S3_IMAGES_BUCKET || 'image.booking.hostastay.com',
  APP_CONFIG: appConfig,
  HOSTPLATFORM_API_PREFIX: appId,
  PORT: process.env.PORT || '3002'
};

// console.log(`server port:${env.PORT}, Backend API: ${env.REACT_APP_API_ENDPOINT}`);
// console.log(`HOSTPLATFORM_API_PREFIX: ${env.HOSTPLATFORM_API_PREFIX}`);

const compulsoryEnv = ['REACT_APP_API_ENDPOINT', 'APP_CONFIG', 'HOSTPLATFORM_API_PREFIX'];
compulsoryEnv.forEach(key => {
  if (!env[key]) {
    throw new Error(`env variable ${key} is not defined`);
  }
});

module.exports = env;
