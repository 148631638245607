import styled from '@emotion/styled';

const fontSizeForTitle = {
  xs: '16px',
  s: '24px',
  default: '32px',
  md: '40px'
};

export const Text = styled.p`
  color: ${props => props.theme.color.headingColor};
  font-size: ${props => fontSizeForTitle[`${props.type}`]};
  font-weight: ${props => props.theme.fontWeight.bolder};
  margin-bottom: ${props => props.marginBottom || props.theme.spacing.regular} !important;
`;
