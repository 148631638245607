import React from 'react';
import PropTypes from 'prop-types';
import { Col, Card } from 'antd';

import FormItem from 'components/FormItem/FormItem';
import UploadPhoto from 'components/UploadPhoto/UploadPhoto';

const FIELD_NAME_PHOTOS = 'photos';

const Photos = ({ disabled, value: photos, form, captionPrefix }) => {
  const handleOnUploadFinish = imageUrl => {
    const currentPhotos = photos || [];
    const uploadedPhoto = {
      imageUrl: imageUrl,
      caption: `${captionPrefix} ${currentPhotos.length + 1}`
    };

    form.setFieldsValue({
      photos: [...currentPhotos, uploadedPhoto]
    });
  };

  const handleOnCaptionChange = index => caption => {
    photos[index] = {
      ...photos[index],
      caption
    };
    form.setFieldsValue({
      photos: [...photos]
    });
  };

  const handleOnImageDelete = index => e => {
    const remainingPhotos = [...photos];
    remainingPhotos.splice(index, 1);

    form.setFieldsValue({
      photos: remainingPhotos
    });
  };

  const handleOnImageMakePrimary = index => e => {
    photos.unshift(photos[index]); // Move the photo to first element
    photos.splice(index + 1, 1); // Remove the photo

    form.setFieldsValue({
      photos: [...photos]
    });
  };

  return (
    <Card title="Photos">
      <UploadPhoto
        disabled={disabled}
        photos={photos}
        onCaptionChange={handleOnCaptionChange}
        onImageDelete={handleOnImageDelete}
        onImageMakePrimary={handleOnImageMakePrimary}
        onUploadFinish={handleOnUploadFinish}
        folderName={'property'}
      />
    </Card>
  );
};

const FormPhotos = ({ disabled, form }) => {
  return (
    <Col>
      <FormItem name={FIELD_NAME_PHOTOS}>
        <Photos disabled={disabled} form={form} />
      </FormItem>
    </Col>
  );
};

Photos.propTypes = {
  captionPrefix: PropTypes.string
};

Photos.defaultProps = {
  captionPrefix: 'Image'
};

export default FormPhotos;
