import React from 'react';
import PropTypes from 'prop-types';

import Card from 'components/Card/Card';
import FormSelection from 'components/FormSelection/FormSelection';

import { StyledCol, StyledTitle } from './AmenitiesSelection.styles';

const AmenitiesSelection = ({ disabled, icon, fieldName, title, items }) => {
  return (
    <StyledCol span={24} md={11}>
      <Card
        headStyle={{ backgroundColor: '#098ca3' }}
        title={
          <StyledTitle>
            {icon}
            {title}
          </StyledTitle>
        }
        bordered={true}
      >
        <FormSelection
          name={fieldName}
          size="large"
          isMultiple
          disabled={disabled}
          selections={items}
          placeholder={`please select ${String(title).toLowerCase()} amenities`}
        />
      </Card>
    </StyledCol>
  );
};

AmenitiesSelection.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.object,
  disabled: PropTypes.bool
};

AmenitiesSelection.defaultProps = {
  icon: undefined,
  disabled: false
};

export default AmenitiesSelection;
