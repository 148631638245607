import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode.react';
import { Button, Checkbox } from 'antd';
import env from 'config/env';
import { patchQREnabledStatus, useGetPropertyQRIsEnabled } from 'apis/property';

const QRCodeCheckin = ({ listingId }) => {
  const qrCodeText = `${env.REACT_APP_API_ENDPOINT}/check-in/${listingId}`;
  const [showQR, setShowQR] = useState(false);
  const [checked, setChecked] = useState(false);

  const { data: apiRes } = useGetPropertyQRIsEnabled(listingId);

  useEffect(() => {
    if (apiRes) {
      if (apiRes.propertyQR?.enabledQR) {
        setChecked(true);
        setShowQR(true);
      }
    }
  }, [apiRes]);

  const downloadQRCode = () => {
    const qrCodeURL = document.getElementById('qrCodeEl').toDataURL('image/png').replace('image/png', 'image/octet-stream');

    let aEl = document.createElement('a');
    aEl.href = qrCodeURL;
    aEl.download = 'QR_Code.png';
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  const onCheckboxClick = e => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      setShowQR(true);
    } else {
      setShowQR(false);
    }

    // call be to update show qr status to true
    patchQREnabledStatus(listingId, e.target.checked);
  };

  return (
    <>
      <Checkbox checked={checked} onChange={onCheckboxClick}>
        Show QR Code
      </Checkbox>
      {showQR && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            marginTop: '1rem'
          }}
        >
          <QRCode id="qrCodeEl" size={300} value={qrCodeText} />
          <Button type="primary" onClick={downloadQRCode} style={{ width: '300px', marginTop: '1rem' }}>
            Download
          </Button>
        </div>
      )}
    </>
  );
};

export default QRCodeCheckin;
