import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';

import FormItem from 'components/FormItem/FormItem';

const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

const addRules = requiredErrorMessage => {
  const rules = [];
  if (requiredErrorMessage && typeof requiredErrorMessage === 'string') {
    rules.push({
      required: true,
      message: requiredErrorMessage
    });
  }
  return rules;
};

const FormRadioButton = ({
  buttonStyle,
  defaultValue,
  extraRules,
  label,
  onChange,
  name,
  requiredErrorMessage,
  size,
  selections,
  shouldDisable,
  disableValues
}) => {
  const extraGeneralRules = addRules(requiredErrorMessage);
  return (
    <>
      <FormItem
        name={name}
        requiredErrorMessage={requiredErrorMessage}
        extraRules={[...extraRules, ...extraGeneralRules]}
        defaultValue={defaultValue}
        label={label}
      >
        <RadioGroup disabled={shouldDisable} buttonStyle={buttonStyle} size={size} onChange={onChange}>
          {selections.map(selection => (
            <RadioButton key={selection.key || selection.value} value={selection.value} disabled={disableValues.includes(selection.value)}>
              {selection.displayValue || selection.label || selection.value}
            </RadioButton>
          ))}
        </RadioGroup>
      </FormItem>
    </>
  );
};

FormRadioButton.propTypes = {
  extraRules: PropTypes.array,
  buttonStyle: PropTypes.oneOf(['outline', 'solid']),
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  selections: PropTypes.array.isRequired,
  defaultValue: PropTypes.any,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  requiredErrorMessage: PropTypes.string,
  shouldDisable: PropTypes.bool,
  size: PropTypes.oneOf(['default', 'large']),
  disableValues: PropTypes.array
};

FormRadioButton.defaultProps = {
  extraRules: [],
  buttonStyle: 'outline',
  defaultValue: undefined,
  form: {},
  label: '',
  onChange: undefined,
  onSearch: undefined,
  placeholder: '',
  shouldDisable: false,
  requiredErrorMessage: '',
  size: 'default',
  disableValues: []
};

export default FormRadioButton;

/* --------------------------------------Bare Radio Button------------------------------------------- */
export const BareRadioButton = ({ options, defaultValue, buttonStyle, isDisabled, onChange }) => {
  defaultValue = defaultValue || options[0].value;

  return (
    <RadioGroup defaultValue={defaultValue} buttonStyle={buttonStyle} disabled={isDisabled} onChange={onChange}>
      {options.map(option => (
        <RadioButton id={option.id} key={option.value} value={option.value}>
          {option.label || option.value}
        </RadioButton>
      ))}
    </RadioGroup>
  );
};

BareRadioButton.propTypes = {
  options: PropTypes.array.isRequired,
  defaultValue: PropTypes.string,
  buttonStyle: PropTypes.oneOf(['outline', 'solid']),
  isDiabled: PropTypes.bool,
  onChange: PropTypes.func
};

BareRadioButton.defaultProps = {
  buttonStyle: 'solid',
  isDiabled: false,
  onChange: undefined
};
/* --------------------------------------Bare Radio Button------------------------------------------- */
