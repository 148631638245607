import React, { useState, useMemo } from 'react';
import { PlusOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Col, Row, Table, Modal } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';

import { useGetPaginatedBookings, getBookingCsvData } from 'apis/booking';

import Card from 'components/Card/Card';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import SectionContainer from 'components/SectionContainer/SectionContainer';
import Title from 'components/Title/Title';
import CsvDownload from 'components/CsvDownload/CsvDownload';

import { useFetchConstant } from 'hooks/constants';
import { getBookingDetailRoute, getNewBookingRoute } from 'utils/routes';
import { constructColumn, constructColumnFilterSearch, handleOnAggregationTableChange, constructColumnFilterRadio } from 'utils/table/table';
import { getLabelOfConstant, guard } from 'utils/general';
import { formatToDateString, getCurrentMonth, getCurrentMonthEnd, formatToMomentObject } from 'utils/date';

const CSV_HEADERS = [
  { label: 'Booking Code', key: 'code' },
  { label: 'Status', key: 'status' },
  { label: 'Booking Date', key: 'bookingDate' },
  { label: 'Member ID', key: 'guestDetails.membershipId' },
  { label: 'Guest Name', key: 'guestDetails.name' },
  { label: 'Email', key: 'guestDetails.email' },
  { label: 'Contact Number', key: 'guestDetails.contactNo' },
  { label: 'Check In Date', key: 'startDate' },
  { label: 'Check Out Date', key: 'endDate' },
  { label: 'Property', key: 'property.name' },
  { label: 'Room', key: 'room.name' },
  { label: 'No. of Room Booked', key: 'room.count' },
  { label: 'City', key: 'property.city' },
  { label: 'State', key: 'property.state' },
  { label: 'Payment Method', key: 'paymentDetails.type' },
  { label: 'Room Nights', key: 'paymentDetails.nightRedeemed' },
  { label: 'Room Rate Per Room (MYR)', key: 'room.ratePerRoom' },
  { label: 'Total Room Rate (MYR)', key: 'room.totalRate' },
  { label: 'Payment To Host Per Room (MYR)', key: 'room.hostNettPaymentPerRoom' },
  { label: 'Total Payment To Host (MYR)', key: 'room.hostTotalNettPayment' },
  { label: 'Host Name', key: 'host.name' }
];

const constructColumns = (bookingStatuses, bookingStatusesConst) => [
  {
    ...constructColumn('Confirmation Code', 'code', { width: 180 }),
    ...constructColumnFilterSearch('code', 'Search Confirmation Code'),
    render: (text, record) => {
      return <Link to={getBookingDetailRoute(record._id).path}>{text}</Link>;
    }
  },
  {
    ...constructColumn('Check In Date', 'startDate', { isDate: true, hasSorter: true, width: 150 })
  },
  {
    ...constructColumn('Check Out Date', 'endDate', { isDate: true, hasSorter: true, width: 150 })
  },
  {
    ...constructColumn('Property Name', 'propertyName'),
    ...constructColumnFilterSearch('propertyName', 'Search Property')
  },
  {
    ...constructColumn('Guest Name', 'guestName'),
    ...constructColumnFilterSearch('guestName', 'Search Guest Name')
  },
  {
    ...constructColumn('Host Name', 'hostName'),
    ...constructColumnFilterSearch('hostName', 'Search Host')
  },
  {
    ...constructColumn('Package/Promotion Code', 'guestAppliedCode'),
    ...constructColumnFilterSearch('guestAppliedCode', 'Search Package/Promotion Code')
  },
  {
    ...constructColumn('Status', 'status'),
    ...constructColumnFilterRadio('status', bookingStatuses),
    render: (status, record) => {
      const isPastBooking = moment(record.startDate).isBefore(moment(), 'day');
      const isPending = bookingStatusesConst && [bookingStatusesConst.PENDING.code, bookingStatusesConst.STRIPE_UNPAID.code].includes(status);

      return isPastBooking && isPending ? 'Expired' : getLabelOfConstant(status, bookingStatuses);
    }
  }
];

const useBookings = query => {
  const { isLoading: isBookingsLoading, paginatedData: bookings, total } = useGetPaginatedBookings(query);

  const formattedBookings = useMemo(() => {
    return !isBookingsLoading
      ? bookings.map(booking => ({
          _id: booking._id,
          code: booking.code,
          startDate: booking.startDate,
          endDate: booking.endDate,
          propertyName: booking.propertyName,
          guestName: booking.guestName,
          hostName: booking.hostName,
          guestAppliedCode: guard(() => booking.paymentDetails.packageCode || booking.paymentDetails.promotionCode),
          status: booking.status
        }))
      : [];
  }, [isBookingsLoading, bookings]);

  return { isBookingsLoading, bookings: formattedBookings, total };
};

const Bookings = () => {
  const [query, setQuery] = useState({});
  const [isDownloadCSVModalVisible, setIsDownloadCSVModalVisible] = useState(false);
  const [csvQuery, setCsvQuery] = useState({ startDate: formatToDateString(getCurrentMonth()), endDate: formatToDateString(getCurrentMonthEnd()) });
  const history = useHistory();

  const { isBookingsLoading, bookings, total } = useBookings(query);
  const { selection: bookingStatuses, data: bookingStatusesConst, isLoading: isBookingStatusesLoading } = useFetchConstant('bookingStatuses');

  const dateRangeValues = useMemo(() => [formatToMomentObject(csvQuery.startDate), formatToMomentObject(csvQuery.endDate)], [csvQuery]);
  const isLoading = isBookingsLoading || isBookingStatusesLoading;

  return (
    <Card>
      <SectionContainer>
        <Row justify="space-between" align="middle">
          <Col>
            <Title>Bookings</Title>
          </Col>
          <Col>
            <Row gutter={8}>
              <Col>
                <Button icon={<DownloadOutlined />} onClick={() => setIsDownloadCSVModalVisible(true)}>
                  Generate CSV
                </Button>
              </Col>
              <Col>
                <Button type="primary" icon={<PlusOutlined />} onClick={() => history.push(getNewBookingRoute().path)}>
                  Add new booking
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </SectionContainer>
      <Table
        loading={isLoading}
        rowKey={record => record._id}
        dataSource={bookings}
        columns={constructColumns(bookingStatuses, bookingStatusesConst)}
        scroll={{ x: 1000 }}
        pagination={{ total }}
        onChange={(pagination, filters, sorter) => handleOnAggregationTableChange({ pagination, filters, sorter }, setQuery)}
      />

      <Modal
        visible={isDownloadCSVModalVisible}
        onCancel={() => setIsDownloadCSVModalVisible(false)}
        footer={
          <CsvDownload
            asyncExportMethod={() => getBookingCsvData(csvQuery)}
            headers={CSV_HEADERS}
            filename={`Bookings_${csvQuery.startDate}_${csvQuery.endDate}.csv`}
          >
            <Button type="primary" icon={<DownloadOutlined />} onClick={() => setIsDownloadCSVModalVisible(false)}>
              Download CSV
            </Button>
          </CsvDownload>
        }
      >
        <SectionContainer>
          <p style={{ fontSize: 16 }}>Select date range to download:</p>
          <DateRangePicker
            value={dateRangeValues}
            onChange={dates => dates && setCsvQuery({ startDate: formatToDateString(dates[0]), endDate: formatToDateString(dates[1]) })}
            allowClear={false}
          />
        </SectionContainer>
      </Modal>
    </Card>
  );
};

export default Bookings;
