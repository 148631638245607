import api from './apiHelper';
import { queryCache } from 'react-query';
import { useCustomPaginatedQuery, useCustomQuery } from 'hooks/reactQuery';

const getBookingPath = params => `booking${params ? `/${params}` : ``}`;

/* ------------------------------- POST CREATE -------------------------------------- */

export const postCreateBooking = payload => {
  const path = getBookingPath('admin');
  return api.post(path, payload);
};

/* ---------------------------------- GET ---------------------------------------- */

export const useGetPaginatedBookingsForOverview = query => {
  const getBookings = async (key, query) => {
    const path = getBookingPath('paginated');
    return api.get(path, { params: { ...query, isForOverview: true } });
  };
  return useCustomPaginatedQuery('bookings', getBookings, query);
};

export const useGetPaginatedBookings = (query, { enabled = true } = {}) => {
  const getBookings = async (key, query) => {
    const path = getBookingPath('paginated');
    return api.get(path, { params: query });
  };
  return useCustomPaginatedQuery('bookings', getBookings, query, { enabled });
};

export const useGetBookingById = (bookingId, onError) => {
  const getBookingById = (key, bookingId) => {
    const path = getBookingPath(bookingId);
    return api.get(path);
  };
  return useCustomQuery('getBookingById', [bookingId], getBookingById, { onError, enabled: !!bookingId });
};

export const useGetBookingSurcharge = (query, enabled, onError) => {
  const getBookingSurcharge = (key, query) => {
    const path = getBookingPath('surcharge');
    return api.get(path, { params: query });
  };
  return useCustomQuery('getBookingSurcharge', [query], getBookingSurcharge, { shouldDefaultEmptyObject: false, onError, enabled });
};

export const getBookingCsvData = query => {
  const path = getBookingPath('csv');
  return api.get(path, { params: query });
};

export const refetchBooking = bookingId => {
  queryCache.invalidateQueries(['getBookingById', bookingId]);
};

/* ---------------------------------- PATCH UPDATE ----------------------------------- */

export const patchUpdateBookingFinancialInfo = (bookingId, payload) => {
  const path = getBookingPath(`${bookingId}/financial`);
  return api.patch(path, payload);
};

export const patchUpdateBookingStatus = (bookingId, status) => {
  const path = getBookingPath(`${bookingId}/status`);
  return api.patch(path, { status });
};

export const patchUpdateBooking = (bookingId, payload) => {
  const path = getBookingPath(bookingId);
  return api.patch(path, payload);
};

/* ---------------------------------- PUT UPDATE ----------------------------------- */
