import React, { useMemo, useState } from 'react';
import { Button, Col, List, Row, Skeleton } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { useGetRoomTypes } from 'apis/roomType';
import { useFetchConstant } from 'hooks/constants';
import { guard } from 'utils/general';
import { getCurrentIndexForPaginatedList } from 'utils/table/table';

import Card from 'components/Card/Card';

import RoomType from './RoomType';

import { StyledRoomTypeCardTitle } from './RoomTypeList.styles';

const RoomTypeCard = ({ ssRoomType, onClickRoomTypeList }) => {
  return (
    <List.Item>
      <List.Item.Meta
        title={
          <StyledRoomTypeCardTitle sm={20} xs={24}>
            {ssRoomType.name}
          </StyledRoomTypeCardTitle>
        }
        description={
          <Row span={24}>
            <Col sm={20} xs={24}>
              <Row>
                <Col span={24}>Inventory: {ssRoomType.inventory || '-'}</Col>
                <Col span={24}>Rooms Count: {guard(() => ssRoomType.roomType.bedrooms.length, '-')}</Col>
              </Row>
            </Col>
            <Col sm={4} xs={24}>
              <Button type="primary" onClick={onClickRoomTypeList} block>
                Edit
              </Button>
            </Col>
          </Row>
        }
      />
    </List.Item>
  );
};

const RoomTypeList = ({ listingId }) => {
  const { isLoading: isBedTypesLoading, selection: bedTypesSelection } = useFetchConstant('bedTypes');
  const {
    isLoading: isRoomTypesLoading,
    data: { roomTypes: ssRoomTypes, canEdit }
  } = useGetRoomTypes(listingId);

  const [currentPage, setCurrentPage] = useState(1);
  const [showRoomTypeDetails, setShowRoomTypeDetails] = useState(false);
  const [selectedRoomTypeIndex, setSelectedRoomTypeIndex] = useState(-1);
  const selectedRoomType = useMemo(() => (selectedRoomTypeIndex > -1 ? ssRoomTypes[selectedRoomTypeIndex] : {}), [
    ssRoomTypes,
    selectedRoomTypeIndex
  ]);

  const handleOnClickRoomTypeList = roomTypeIndex => () => {
    setSelectedRoomTypeIndex(roomTypeIndex);
    handleOnShowRoomTypeDetails(true);
  };

  const handleOnClickCreateNew = () => () => {
    setSelectedRoomTypeIndex(-1);
    handleOnShowRoomTypeDetails(true);
  };

  const handleOnShowRoomTypeDetails = boolean => {
    setShowRoomTypeDetails(boolean);
  };

  const isLoading = isBedTypesLoading || isRoomTypesLoading;

  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          {showRoomTypeDetails ? (
            <RoomType
              canEdit={canEdit}
              listingId={listingId}
              roomTypeDetails={selectedRoomType}
              handleOnShowRoomTypeDetails={handleOnShowRoomTypeDetails}
              bedTypesSelection={bedTypesSelection}
            />
          ) : (
            <Card>
              {canEdit && (
                <Button type="primary" icon={<PlusOutlined />} style={{ marginBottom: '20px' }} onClick={handleOnClickCreateNew()}>
                  Create Room Type
                </Button>
              )}
              <List
                size="large"
                bordered
                dataSource={ssRoomTypes}
                pagination={{ onChange: setCurrentPage }}
                renderItem={(ssRoomType, index) => {
                  const currentItemIndex = getCurrentIndexForPaginatedList(index, currentPage);
                  return (
                    <RoomTypeCard key={ssRoomType._id} ssRoomType={ssRoomType} onClickRoomTypeList={handleOnClickRoomTypeList(currentItemIndex)} />
                  );
                }}
              />
            </Card>
          )}
        </>
      )}
    </>
  );
};

export default RoomTypeList;
