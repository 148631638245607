import { useCustomPaginatedQuery } from 'hooks/reactQuery';
import api from './apiHelper';

const getNomadLocationPath = params => `location${!!params ? `/${params}` : ''}`;

export const useGetNomadLocations = (query, onError) => {
  const getLocations = async (key, query) => {
    const path = getNomadLocationPath('paginated');
    return api.get(path, { params: query });
  };

  return useCustomPaginatedQuery('locations', getLocations, query, { onError });
};
