import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';

import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import FormSelection from 'components/FormSelection/FormSelection';
import FormLabel from 'components/FormLabel/FormLabel';

import { REGEX_NUMBER_N_DOT } from 'utils/constants';
import { guard } from 'utils/general';

const PromotionSettingFormItems = ({
  promotionTypeConstants,

  promotionTypeName,
  promotionAmountName,
  minValue,
  initialSelectedType
}) => {
  const [selectedType, setSelectedType] = useState('');

  const promotionTypeObject = useMemo(() => promotionTypeConstants.find(type => type.code === (selectedType || initialSelectedType)), [
    promotionTypeConstants,
    selectedType,
    initialSelectedType
  ]);
  const isPercentage = useMemo(() => guard(() => !!promotionTypeObject.isPercentage), [promotionTypeObject]);
  const maxValue = isPercentage ? 100 : undefined;
  const precision = isPercentage ? 0 : 2;
  const formatter = isPercentage ? value => `${value}%` : value => `RM ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const parser = isPercentage ? value => value.replace(/[^0-9]/g, '') : value => value.replace(REGEX_NUMBER_N_DOT, '');

  const handleOnTypeChange = selectedType => {
    setSelectedType(selectedType);
  };

  return (
    <>
      <Row>
        <FormLabel hasRequiredErrorMessage={true}>Promotion Setting</FormLabel>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <FormSelection
            name={promotionTypeName}
            requiredErrorMessage="Please select a promotion type"
            selections={promotionTypeConstants}
            onChange={handleOnTypeChange}
          />
        </Col>

        <Col span={12}>
          <FormInputNumber
            name={promotionAmountName}
            requiredErrorMessage="Please enter an amount"
            minValue={minValue}
            maxValue={maxValue}
            precision={precision}
            formatter={formatter}
            parser={parser}
          />
        </Col>
      </Row>
    </>
  );
};

PromotionSettingFormItems.propTypes = {
  promotionTypeConstants: PropTypes.array.isRequired,
  promotionTypeName: PropTypes.string.isRequired,
  promotionAmountName: PropTypes.string.isRequired,
  minValue: PropTypes.number,
  initialSelectedType: PropTypes.string
};

PromotionSettingFormItems.defaultProps = {
  minValue: 1
};

export default PromotionSettingFormItems;
