import { Col, Row, Table } from 'antd';
import Card from 'components/Card/Card';
import SectionContainer from 'components/SectionContainer/SectionContainer';
import Title from 'components/Title/Title';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { constructColumn, constructColumnFilterSearch, handleOnAggregationTableChange } from 'utils/table/table';
import { getMemberDetailsRoute } from 'utils/routes';
import { useGetNomadLocations } from 'apis/nomadLocation';

const constructColumns = () => [
  {
    ...constructColumn('Nomad', 'memberName', { hasSorter: true }),
    ...constructColumnFilterSearch('memberName', 'Search Nomad'),
    render: (text, record) => <Link to={getMemberDetailsRoute(record.memberId).path}>{text}</Link>
  },
  {
    ...constructColumn('Longitude', 'long', { hasSorter: true })
  },
  {
    ...constructColumn('Lattitude', 'lat', { hasSorter: true })
  },
  {
    ...constructColumn('Checked-in Date', 'createdAt', { isDate: true, hasSorter: true })
  }
];

const NomadLocation = () => {
  const [query, setQuery] = useState({});
  const { isLoading: isNomadLoading, paginatedData: nomadLocations, total } = useGetNomadLocations(query);

  const checkinDatas = useRef([]);
  useEffect(() => {
    if (nomadLocations) {
      checkinDatas.current = nomadLocations.map(val => {
        return {
          _id: val._id,
          lat: val.lat,
          long: val.long,
          memberName: `${val.memberDetails.name} (${val.memberDetails.email})`,
          memberId: val.memberId,
          createdAt: val.timeStamp
        };
      });
    }
  }, [nomadLocations]);

  return (
    <Card>
      <SectionContainer>
        <Row justify="space-between" align="middle">
          <Col>
            <Title>Nomad Location Record</Title>
          </Col>
        </Row>
      </SectionContainer>

      <Table
        loading={isNomadLoading}
        rowKey={record => record._id}
        dataSource={checkinDatas.current}
        columns={constructColumns()}
        scroll={{ x: 1000 }}
        pagination={{ total }}
        onChange={(pagination, filters, sorter) => handleOnAggregationTableChange({ pagination, filters, sorter }, setQuery)}
      />
    </Card>
  );
};

export default NomadLocation;
