import React from 'react';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';

import { NavButton, IconContainer, Text } from './ClickableTextButton.styles';

const ClickableTextButton = ({ text, url = '', showIcon = true, icon = <LeftOutlined />, onClick = () => {} }) => {
  const history = useHistory();
  return (
    <NavButton align="middle" onClick={url ? () => history.push(url) : onClick}>
      {showIcon && <IconContainer>{icon}</IconContainer>}
      <Text>{text}</Text>
    </NavButton>
  );
};

export default ClickableTextButton;
