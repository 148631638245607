import React, { useMemo } from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';

import FormItem from 'components/FormItem/FormItem';

import { checkIsEmail } from 'utils/general';

const { Option } = Select;

const generateRules = (oriRules, isEmail) => {
  let newRules = [...oriRules];
  if (isEmail) {
    newRules.push({
      validator: async (rule, values) => {
        if (!values) {
          return;
        }

        for (let i = 0; i < values.length; i++) {
          const value = values[i];
          if (!checkIsEmail(value)) {
            throw new Error(`One of your email(s) is invalid: ${value}`);
          }
        }
      }
    });
  }
  return newRules;
};

const FormMultiInputTag = ({
  disabled,
  label,
  name,
  placeholder,
  requiredErrorMessage,
  selections,
  onChange,
  defaultValue,
  isEmail,
  extraProps,
  extraRules
}) => {
  const rules = useMemo(() => generateRules(extraRules, isEmail), [extraRules, isEmail]);

  return (
    <>
      <FormItem requiredErrorMessage={requiredErrorMessage} name={name} label={label} initialValue={defaultValue} extraRules={rules}>
        <Select disabled={disabled} mode="tags" placeholder={placeholder} {...extraProps} onChange={onChange} dropdownStyle={{ display: 'none' }}>
          {selections.map(selection => (
            <Option key={selection.label} value={selection.value}>
              {selection.label}
            </Option>
          ))}
        </Select>
      </FormItem>
    </>
  );
};

FormMultiInputTag.propTypes = {
  isSearchable: PropTypes.bool,
  selections: PropTypes.array
};

FormMultiInputTag.defaultProps = {
  isSearchable: true,
  selections: [],
  extraRules: [],
  isEmail: false
};

export default FormMultiInputTag;
