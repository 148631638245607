import api from './apiHelper';
import { queryCache } from 'react-query';
import { useCustomQuery, useCustomPaginatedQuery } from 'hooks/reactQuery';

const getUserPath = params => `user${!!params ? `/${params}` : ''}`;

/* ---------------------------------- GET ---------------------------------------- */

export const useGetPaginatedUsers = (query, onError) => {
  const getUsers = async (key, query) => {
    const path = getUserPath('paginated');
    return api.get(path, { params: query });
  };

  return useCustomPaginatedQuery('users', getUsers, query, {
    onError
  });
};

export const useGetUserById = (userId, onError) => {
  const getUserById = (key, userId) => {
    const path = getUserPath(userId);
    return api.get(path);
  };
  return useCustomQuery('user', [userId], getUserById, { onError, enabled: !!userId });
};

export const useGetSelf = onError => {
  const getUserSelf = key => {
    const path = getUserPath('self');
    return api.get(path);
  };
  return useCustomQuery('user self', [], getUserSelf, { onError });
};

/* ------------------------------- POST CREATE -------------------------------------- */

export const postCreateUser = payload => {
  const path = getUserPath();
  return api.post(path, payload).then(newUser => {
    refetchUsers();
    return newUser;
  });
};

/* ---------------------------------- PATCH UPDATE ----------------------------------- */

export const patchUpdateUser = (userId, payload) => {
  const path = getUserPath(userId);
  return api.patch(path, payload).then(updatedUser => {
    refetchUserDetails(userId);
    refetchUsers();
    return updatedUser;
  });
};

export const patchUpdateSelf = payload => {
  const path = getUserPath('self');
  return api.patch(path, payload).then(updatedSelf => {
    refetchSelfDetails();
    refetchUsers();
    return updatedSelf;
  });
};

/* ---------------------------------- DELETE ----------------------------------- */

export const deleteUser = userId => {
  const path = getUserPath(userId);
  return api.delete(path).then(deletedUser => {
    refetchUsers();
    return deletedUser;
  });
};

/* ---------------------------------- REFETCH FUNCTION ---------------------------------------- */

const refetchUsers = () => queryCache.invalidateQueries(['users']);
const refetchUserDetails = userId => queryCache.invalidateQueries(['user', userId]);
const refetchSelfDetails = () => queryCache.invalidateQueries(['user self']);
