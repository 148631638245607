import styled from '@emotion/styled';

import { List } from 'antd';

export const RoomListContainer = styled(List)`
  width: 100%;
`;

export const Description = styled.span`
  color: #f5222d;
`;

export const BedsConfigurationContainer = styled.div`
  width: 100%;
  padding: 20px;
  margin-top: 30px;
  box-sizing: border-box;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);

  @media (min-width: 768px) {
    margin: 0 0 0 30px;
  }
`;
