import React from 'react';
import { Col, message, Row } from 'antd';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import Card from 'components/Card/Card';
import FormInput from 'components/FormInput/FormInput';
import FormSelection from 'components/FormSelection/FormSelection';
import FormGoogleMap from 'components/FormGoogleMap/FormGoogleMap';

import {
  FORM_KEY_ZIP_CODE,
  FORM_KEY_COUNTRY_CODE,
  FORM_KEY_CITY,
  FORM_KEY_STREET,
  FORM_KEY_STATE,
  FORM_KEY_LOCATION,
  GOOGLE_MAP_API
} from '../../utils/formKey';

const LocationCard = ({ canEdit, form, states, countries }) => {
  const handleOnMapMarkerMove = newLocation => {
    form.setFieldsValue({
      [FORM_KEY_LOCATION]: newLocation
    });
  };

  const getCountryValue = countryCode => {
    return countries.find(country => country.iso2 === countryCode).value;
  };

  const getStateValue = stateCode => {
    return states.find(state => state.code === stateCode).value;
  };

  const handleOnMapSearch = addressInformation => {
    geocodeByAddress(
      `${addressInformation[FORM_KEY_STREET]}, ${addressInformation[FORM_KEY_CITY]}, ${addressInformation[FORM_KEY_ZIP_CODE]} ${getStateValue(
        addressInformation[FORM_KEY_STATE]
      )}, ${getCountryValue(addressInformation[FORM_KEY_COUNTRY_CODE])}`
    )
      .then(results => {
        return getLatLng(results[0]);
      })
      .then(latLng => {
        return form.setFieldsValue({
          [FORM_KEY_LOCATION]: [latLng.lng, latLng.lat]
        });
      })
      .catch(error => {
        form.setFieldsValue({
          [FORM_KEY_LOCATION]: undefined
        });
        message.error('This address is not found in Google Map. Please use a different one');
        console.error(error);
      });
  };

  const debounceHandleOnMapSearch = debounce(handleOnMapSearch, 500);

  const handleOnFormChange = (fieldName, extraAction = () => {}) => event => {
    extraAction(event);

    const fieldValue = extractFieldValue(event);
    const allFieldsValue = form.getFieldsValue([FORM_KEY_STREET, FORM_KEY_CITY, FORM_KEY_ZIP_CODE, FORM_KEY_STATE, FORM_KEY_COUNTRY_CODE]);

    allFieldsValue[fieldName] = fieldValue;
    const hasCompletedForm = checkHasFormCompleted(allFieldsValue);

    if (hasCompletedForm) {
      debounceHandleOnMapSearch(allFieldsValue);
    }
  };

  const extractFieldValue = event => {
    let fieldValue = event;
    if (typeof fieldValue === 'object' && fieldValue.target) {
      fieldValue = fieldValue.target.value;
    }
    return fieldValue;
  };

  const checkHasFormCompleted = allFieldsValue => {
    // Check all fields value and make sure they are set
    let hasCompletedForm = true;
    for (let i = 0; i < Object.keys(allFieldsValue).length; i++) {
      const key = Object.keys(allFieldsValue)[i];
      if (allFieldsValue[key]) {
        continue;
      } else {
        hasCompletedForm = false;
        break;
      }
    }
    return hasCompletedForm;
  };

  return (
    <Col>
      <Card title="Location">
        <Row gutter={16}>
          <Col span={24}>
            <FormInput
              disabled={!canEdit}
              name={FORM_KEY_STREET}
              label="Street Address"
              placeholder="Street Address"
              requiredErrorMessage="Please provide street address that your property locate"
              extraProps={{ size: 'large', onChange: handleOnFormChange(FORM_KEY_STREET) }}
            />
          </Col>
          <Col span={24} md={12}>
            <FormInput
              disabled={!canEdit}
              name={FORM_KEY_CITY}
              label="City"
              placeholder="City"
              requiredErrorMessage="Please provide city that your property locate"
              extraProps={{ size: 'large', onChange: handleOnFormChange(FORM_KEY_CITY) }}
            />
          </Col>
          <Col span={24} md={12}>
            <FormInput
              disabled={!canEdit}
              name={FORM_KEY_ZIP_CODE}
              label="ZIP Code"
              placeholder="ZIP Code"
              requiredErrorMessage="Please provide the ZIP Code of your property locate"
              extraProps={{ size: 'large', onChange: handleOnFormChange(FORM_KEY_ZIP_CODE) }}
              extraRules={[
                {
                  max: 6,
                  message: 'ZIP Code has maximum 6 numbers'
                },
                {
                  min: 5,
                  message: 'ZIP Code has minimum 5 numbers'
                },
                {
                  pattern: /^[0-9]*$/,
                  message: 'ZIP Code must be number'
                }
              ]}
            />
          </Col>
          <Col span={24} md={12}>
            <FormSelection
              disabled={!canEdit}
              name={FORM_KEY_STATE}
              label="State"
              placeholder="State"
              requiredErrorMessage="Please select state that your property locate"
              selections={states}
              onChange={handleOnFormChange(FORM_KEY_STATE)}
            />
          </Col>
          <Col span={24} md={12}>
            <FormSelection
              disabled={!canEdit}
              name={FORM_KEY_COUNTRY_CODE}
              label="Country"
              placeholder="Country"
              requiredErrorMessage="Please select country that your property locate"
              selections={countries}
              onChange={handleOnFormChange(FORM_KEY_COUNTRY_CODE)}
            />
          </Col>
          <Col span={24}>
            <div>
              <h3>Move the pin to update your property location</h3>
              <FormGoogleMap
                disabled={!canEdit}
                name={FORM_KEY_LOCATION}
                googleMapURL={GOOGLE_MAP_API}
                onMarkerMove={handleOnMapMarkerMove}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `400px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />
              {form.getFieldError(FORM_KEY_LOCATION).length > 0 && <span>Your address is invalid, please check your address again.</span>}
            </div>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

LocationCard.propTypes = {
  states: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired
};

LocationCard.defaultProps = {
  states: [],
  countries: []
};

export default LocationCard;
