import React from 'react';
import { useParams } from 'react-router-dom';
import { message, Row, Tabs } from 'antd';

import { useGetPropertyDetailsById, patchUpdatePropertyStatus } from 'apis/property';
import { useFetchConstant } from 'hooks/constants';
import { guard, getLabelOfConstant } from 'utils/general';

import ActivationButton from 'components/ActivationButton/ActivationButton';
import Card from 'components/Card/Card';
import ClickableTextButton from 'components/ClickableTextButton/ClickableTextButton';
import Title from 'components/Title/Title';

import { getPropertiesRoute } from 'utils/routes';

import Property from './Property/Property';
import RoomTypeList from './RoomType/RoomTypeList';

import { StatusTag } from './PropertyDetails.styles';
import QRCodeCheckin from './QRCode/QRCode';

const TabPane = Tabs.TabPane;

const PropertyStatusTag = ({ status, label, propertyStatusesConst }) => {
  const getTagColor = () => {
    switch (status) {
      case propertyStatusesConst.ACTIVE.code:
        return 'success';
      case propertyStatusesConst.INACTIVE.code:
        return 'error';
      default:
        return 'default';
    }
  };
  return <StatusTag color={getTagColor()}>{label}</StatusTag>;
};

const PropertyDetails = () => {
  const { listingId } = useParams();

  const { data: listingDetails, isLoading: isLoadingPropertyDetails } = useGetPropertyDetailsById(listingId);
  const { selection: propertyStatuses, data: propertyStatusesConst, isLoading: isPropertyStatusesLoading } = useFetchConstant('propertyStatuses');

  const isEditMode = !!listingId;
  const isLoading = isLoadingPropertyDetails || isPropertyStatusesLoading;

  const status = guard(() => listingDetails.propertyDetails.status, undefined);
  const isActivated = !isPropertyStatusesLoading && status === propertyStatusesConst.ACTIVE.code;

  const handleOnActivate = () =>
    patchUpdatePropertyStatus(listingId, propertyStatusesConst.ACTIVE.code).then(() => {
      message.success(`The property has been successfully activated.`);
    });

  const handleOnDeactivate = () =>
    patchUpdatePropertyStatus(listingId, propertyStatusesConst.INACTIVE.code).then(() => {
      message.success(`The property has been successfully deactivated.`);
    });

  return (
    !isLoading && (
      <div style={{ width: '100%' }}>
        <ClickableTextButton text="Back to Properties" url={getPropertiesRoute().path} />
        <Row type="flex" align="middle" style={{ marginBottom: '16px' }}>
          <Title>{isEditMode ? listingDetails && listingDetails.propertyDetails && listingDetails.propertyDetails.name : 'New Property'}</Title>
          {isEditMode && (
            <PropertyStatusTag status={status} label={getLabelOfConstant(status, propertyStatuses)} propertyStatusesConst={propertyStatusesConst} />
          )}
        </Row>
        <Card>
          <Tabs
            defaultActiveKey="1"
            tabBarExtraContent={
              <ActivationButton
                id={listingId}
                status={status}
                isActivated={isActivated}
                label="property"
                onActivate={handleOnActivate}
                onDeactivate={handleOnDeactivate}
              />
            }
          >
            <TabPane tab="Property" key="1">
              <Property listingId={listingId} isEditMode={isEditMode} />
            </TabPane>
            {isEditMode && (
              <TabPane tab="Room Type" key="2">
                <RoomTypeList listingId={listingId} isEditMode={isEditMode} />
              </TabPane>
            )}
            <TabPane tab="QR Code" key="3">
              <QRCodeCheckin listingId={listingId} />
            </TabPane>
          </Tabs>
        </Card>
      </div>
    )
  );
};

export default PropertyDetails;
