import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row, Form, Button, Skeleton, Modal, message } from 'antd';
import { CheckOutlined, ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';

import ClickableTextButton from 'components/ClickableTextButton/ClickableTextButton';
import FormInput from 'components/FormInput/FormInput';
import FormSelection from 'components/FormSelection/FormSelection';
import FormMultiInputTag from 'components/FormMultiInputTag/FormMultiInputTag';
import SectionCard from 'components/SectionCard/SectionCard';
import SectionContainer from 'components/SectionContainer/SectionContainer';
import Title from 'components/Title/Title';
import FormSinglePhoto from 'components/FormSinglePhoto/FormSinglePhoto';

import { useGetHostById, patchUpdateHost, postCreateHost, deleteHost } from 'apis/host';
import { useFetchConstant } from 'hooks/constants';
import { getHostsRoute, getHostDetailRoute } from 'utils/routes';
import env from 'config/env';

const { confirm, error } = Modal;
const { useForm } = Form;

const Host = () => {
  const history = useHistory();
  const { id: hostId } = useParams();
  const [form] = useForm();
  const isEditMode = useMemo(() => !!hostId, [hostId]);

  const { isLoading: isHostLoading, data: host } = useGetHostById(hostId);

  const { isLoading: isStateLoading, selection: states } = useFetchConstant('statesMY');
  const { isLoading: isCountriesLoading, selection: countries } = useFetchConstant('countries', {
    labelKey: 'name',
    valueKey: 'iso2'
  });

  const isLoading = useMemo(() => isStateLoading || isCountriesLoading || isHostLoading, [isStateLoading, isCountriesLoading, isHostLoading]);
  const canEdit = useMemo(() => !isEditMode || host.isOwnedByHSuites, [isEditMode, host]);
  const canShowFormImage = useMemo(() => !isEditMode || host.isOwnedByHSuites || (!host.isOwnedByHSuites && host.images && host.images.length), [
    isEditMode,
    host
  ]);

  const handleOnSave = values => {
    const payload = {
      ...values
    };

    if (isEditMode) {
      confirm({
        title: 'Are you sure you want to overwrite existing data?',
        content: 'You will not be able to undo this action, but you may update it again.',
        icon: <ExclamationCircleOutlined />,
        onOk() {
          patchUpdateHost(hostId, payload)
            .then(() => {
              message.success('Update success!');
            })
            .catch(ex => {
              error({
                title: ex.message
              });
            });
        },
        onCancel() {}
      });
    } else {
      postCreateHost(payload)
        .then(newHost => {
          Modal.success({
            title: 'Host has been created successfully!',
            okText: 'View created host',
            onOk() {
              history.push(getHostDetailRoute(newHost._id).path);
            }
          });
        })
        .catch(ex => {
          error({
            title: ex.message
          });
        });
    }
  };

  const handleOnDeleteClick = () => {
    confirm({
      title: (
        <span>
          Are you sure that you want to delete this host? <br />
          <br />
          This will remove <b>ALL</b> of their listings.
        </span>
      ),
      content: 'You will not be able to undo this action.',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes, I want to delete this host.',
      onOk() {
        deleteHost(hostId)
          .then(() => {
            message.success('Delete success!');
            history.push(getHostsRoute().path);
          })
          .catch(ex => {
            error({
              title: ex.message
            });
          });
      },
      onCancel() {}
    });
  };

  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Form initialValues={host} scrollToFirstError={true} onFinish={handleOnSave} style={{ width: '100%' }} form={form}>
          <ClickableTextButton text="Back to Hosts" url={getHostsRoute().path} />
          <Title marginBottom="16px">{isEditMode ? host.name : 'New Host'}</Title>
          <SectionContainer>
            <SectionCard title="Basic Information">
              <Row gutter={16}>
                <Col span={24}>
                  <FormInput
                    label="Host Name"
                    name="name"
                    placeholder="HostAStay Sdn. Bhd."
                    requiredErrorMessage="Please enter host name."
                    disabled={!canEdit || host.isRoot}
                  />
                </Col>
                <Col span={12}>
                  <FormInput
                    label="Email Address"
                    name="email"
                    type="email"
                    placeholder="example@mail.com"
                    requiredErrorMessage="Please enter email address."
                    disabled={!canEdit}
                  />
                </Col>
                <Col span={12}>
                  <FormInput
                    label="Contact Number"
                    name="contactNo"
                    placeholder="60123456789"
                    requiredErrorMessage="Please enter contact number."
                    disabled={!canEdit}
                  />
                </Col>
                <Col span={24}>
                  <FormMultiInputTag name={env.APP_CONFIG.hostNotisEmailTag} label="Emails for Booking Notification" isEmail />
                </Col>
                {canShowFormImage && (
                  <Col span={24}>
                    <FormSinglePhoto label="Upload Host Logo" name="hosts" form={form} disabled={!canEdit} />
                  </Col>
                )}
              </Row>
            </SectionCard>
          </SectionContainer>
          <SectionContainer>
            <SectionCard title="Address Details">
              <Row gutter={16}>
                <Col span={24}>
                  <FormInput label="Street Address" name="street" requiredErrorMessage="Please enter street address." disabled={!canEdit} />
                </Col>
                <Col span={12}>
                  <FormInput label="City" name="city" requiredErrorMessage="Please enter city." disabled={!canEdit} />
                </Col>
                <Col span={12}>
                  <FormInput label="Zip Code" name="zipCode" requiredErrorMessage="Please enter zip code." disabled={!canEdit} />
                </Col>
                <Col span={12}>
                  <FormSelection
                    label="State"
                    name="state"
                    placeholder="Select a state"
                    requiredErrorMessage="Please select a state."
                    selections={states}
                    disabled={!canEdit}
                  />
                </Col>
                <Col span={12}>
                  <FormSelection
                    label="Country"
                    name="countryCode"
                    placeholder="Select a country"
                    requiredErrorMessage="Please select a country."
                    selections={countries}
                    disabled={!canEdit}
                  />
                </Col>
              </Row>
            </SectionCard>
          </SectionContainer>
          <Row gutter={8} style={{ marginBottom: '32px' }}>
            <Col>
              <Button htmlType="submit" type="primary" icon={<CheckOutlined />}>
                {isEditMode ? 'Save' : 'Create'}
              </Button>
            </Col>
            {isEditMode && (
              <Col>
                <Button type="ghost" danger icon={<DeleteOutlined />} onClick={handleOnDeleteClick}>
                  Delete
                </Button>
              </Col>
            )}
          </Row>
        </Form>
      )}
    </>
  );
};

export default Host;
