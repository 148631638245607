import React from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';

import Card from 'components/Card/Card';
import AmenitiesSelection from 'components/AmenitiesSelection/AmenitiesSelection';

const AmenitiesCard = ({ canEdit, amenities, fieldName }) => {
  return (
    <Col>
      <Card title="Amenities">
        <Row type="flex" justify="space-between">
          {amenities &&
            Object.keys(amenities).map(key => {
              const subFieldName = String(key);

              return (
                <AmenitiesSelection
                  key={key}
                  disabled={!canEdit}
                  title={key}
                  fieldName={[fieldName, subFieldName]}
                  items={amenities[key].data}
                  icon={amenities[key].icon}
                />
              );
            })}
        </Row>
      </Card>
    </Col>
  );
};

export default AmenitiesCard;

AmenitiesCard.propTypes = {
  amenities: PropTypes.object.isRequired,
  defaultValues: PropTypes.object,
  fieldName: PropTypes.string.isRequired
};

AmenitiesCard.defaultProps = {
  defaultValues: {}
};
