import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import FormItem from 'components/FormItem/FormItem';

import { FullWidthDatePicker } from './FormDatePicker.styles';

const dateFormat = 'YYYY-MM-DD';

const FormDatePicker = ({ disabled, extraProps, label, name, placeholder, onFocus, requiredErrorMessage, isDob, isReservation }) => {
  const getDisabledDate = current => {
    if (isDob) {
      return current && current > moment().endOf('day');
    } else if (isReservation) {
      return current && current <= moment().startOf('day');
    }
    return undefined;
  };

  return (
    <>
      <FormItem requiredErrorMessage={requiredErrorMessage} name={name} label={label}>
        <FullWidthDatePicker disabled={disabled} format={dateFormat} placeholder={placeholder} {...extraProps} disabledDate={getDisabledDate} />
      </FormItem>
    </>
  );
};

FormDatePicker.propTypes = {
  extraProps: PropTypes.object,
  isDob: PropTypes.bool,
  isReservation: PropTypes.bool
};

FormDatePicker.defaultProps = {
  extraProps: {},
  isDob: false,
  isReservation: false
};

export default FormDatePicker;
