import api from './apiHelper';
import { queryCache } from 'react-query';
import { useCustomQuery, useCustomPaginatedQuery } from 'hooks/reactQuery';

const getPackageOrderPath = params => `packageOrder${params ? `/${params}` : ``}`;

/* ------------------------------- POST CREATE -------------------------------------- */

export const postCreatePackageOrder = payload => {
  const path = getPackageOrderPath();
  return api.post(path, payload);
};

/* ---------------------------------- GET ---------------------------------------- */

export const useGetPaginatedPackageOrders = (query, onError) => {
  const getPackageOrders = async (key, query) => {
    const path = getPackageOrderPath('paginated');
    return api.get(path, { params: query });
  };

  return useCustomPaginatedQuery('packageOrders', getPackageOrders, query, {
    onError
  });
};

export const useGetPackageOrderById = (packageOrderId, onError) => {
  const getPackageOrderById = (key, packageOrderId) => {
    const path = getPackageOrderPath(packageOrderId);
    return api.get(path);
  };
  return useCustomQuery('getPackageOrderById', [packageOrderId], getPackageOrderById, { onError, enabled: !!packageOrderId });
};

export const refetchPackageOrder = packageOrderId => {
  queryCache.invalidateQueries(['getPackageOrderById', packageOrderId]);
};

export const useGetPackageOrders = (query, bookingId) => {
  const getPackageOrders = () => {
    const path = getPackageOrderPath('admin');
    return api.get(path, { params: query });
  };
  return useCustomQuery('getPackageOrders', [query, bookingId], getPackageOrders);
};

/* ---------------------------------- PATCH UPDATE ----------------------------------- */

export const patchUpdatePackageOrder = (packageOrderId, payload) => {
  const path = getPackageOrderPath(packageOrderId);
  return api.patch(path, payload);
};

export const patchUpdatePackageOrderTrxn = (packageOrderId, payload) => {
  const path = `${getPackageOrderPath(packageOrderId)}/transaction`;
  return api.patch(path, payload);
};

/* ---------------------------------- PUT UPDATE ----------------------------------- */

/* ----------------------------------- OTHERS -------------------------------------- */

export const getValidateHasExistPackageCode = packageCode => {
  const path = `${getPackageOrderPath(packageCode)}/packageCode`;
  return api.get(path);
};
