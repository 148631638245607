import React, { useMemo } from 'react';
import { Col, Row, Form, Button, Skeleton, Modal, message } from 'antd';
import { CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import FormInput from 'components/FormInput/FormInput';
import FormSelection from 'components/FormSelection/FormSelection';
import FormGroupSelectionInput from 'components/FormGroupSelectionInput/FormGroupSelectionInput';
import SectionCard from 'components/SectionCard/SectionCard';
import Title from 'components/Title/Title';

import { withAppContext } from 'contexts/AppContext/AppContext';

import { useGetSelf, patchUpdateSelf } from 'apis/user';
import { useFetchConstant } from 'hooks/constants';

import { SectionContainer } from './MyProfile.styles';

const { confirm, error } = Modal;

const MyProfile = ({ user: loggedInUser }) => {
  const { isLoading: isUserLoading, data: self } = useGetSelf();
  const { isLoading: isCountryCodesLoading, selection: countryCodes } = useFetchConstant('countries', {
    labelKey: 'phoneCode',
    valueKey: 'phoneCode'
  });
  const { isLoading: isRolesLoading, selection: roles } = useFetchConstant('roles');
  const isLoading = useMemo(() => isRolesLoading || isUserLoading || isCountryCodesLoading, [isRolesLoading, isUserLoading, isCountryCodesLoading]);

  const handleOnSave = values => {
    const payload = {
      ...values
    };
    confirm({
      title: 'Are you sure you want to overwrite existing data?',
      content: 'You will not be able to undo this action, but you may update it again.',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        patchUpdateSelf(payload)
          .then(() => {
            message.success('Update success!');
          })
          .catch(ex => {
            error({
              title: ex.message
            });
          });
      },
      onCancel() {}
    });
  };

  const handleOnSaveFailed = ({ errorFields }) => {
    errorFields.forEach(field => message.error(field.errors[0]));
  };

  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Form initialValues={self} scrollToFirstError={true} style={{ width: '100%' }} onFinish={handleOnSave} onFinishFailed={handleOnSaveFailed}>
          <Title marginBottom="16px">Profile Details</Title>
          <SectionContainer>
            <Row gutter={8}>
              <Col span={24} md={12}>
                <SectionCard title="Basic Information">
                  <Row gutter={16}>
                    <Col span={24}>
                      <FormInput label="Name" name="name" requiredErrorMessage="Please enter user's name." />
                    </Col>
                    <Col span={24}>
                      <FormInput
                        label="Email Address"
                        name="email"
                        type="email"
                        placeholder="example@mail.com"
                        requiredErrorMessage="Please enter email address."
                      />
                    </Col>
                    <Col span={24}>
                      <FormGroupSelectionInput
                        label="Contact Number"
                        inputName={['contact', 'contactNumber']}
                        inputType="number"
                        selectionName={['contact', 'countryCode']}
                        selections={countryCodes}
                        requiredErrorMessage="Please enter contact number"
                      />
                    </Col>
                  </Row>
                </SectionCard>
              </Col>
              <Col span={24} md={12}>
                <SectionCard title="Account Details">
                  <Row gutter={16}>
                    <Col span={24}>
                      <FormInput label="Username" name="username" requiredErrorMessage="Please enter username." disabled />
                      <FormSelection
                        label="Role"
                        name="role"
                        placeholder="Select role type"
                        requiredErrorMessage="Please select a role."
                        selections={roles}
                        disabled
                      />
                    </Col>
                  </Row>
                </SectionCard>
              </Col>
            </Row>
          </SectionContainer>
          <Row gutter={8} style={{ marginBottom: '32px' }}>
            <Col>
              <Button htmlType="submit" type="primary" icon={<CheckOutlined />}>
                Update my details
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default withAppContext(MyProfile);
