import React from 'react';
import { Col } from 'antd';
import FormCounter from 'components/FormCounter/FormCounter';

import { StyledRow } from './RowFormCounter.styles';

const RowFormCounter = ({ form, label, name, disable, minValue }) => {
  return (
    <StyledRow type="flex" gutter={{ span: 32, md: 0 }}>
      <Col span={12} md={4}>
        <p style={{ marginBottom: '8px' }}>{label}</p>
      </Col>
      <Col span={12} md={8}>
        <FormCounter form={form} name={name} disable={disable} minValue={minValue} />
      </Col>
    </StyledRow>
  );
};

export default RowFormCounter;
